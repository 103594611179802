.ConfirmOrderDelivery-modalBackground {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba($color: black, $alpha: 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  /* border: 1px solid black; */
}



.ConfirmOrderDelivery-modalContainer {
  width: 60%;
  height: 60%;

  border-radius: calc(12px * var(--heightmultiplier));

  background-color: var(--primaryColor);
  // box-shadow: #FD6025 0px 0px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: calc(20px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));
  // border: 5px solid #FC6025;
  /* padding-top: calc(50px);
    padding-bottom: calc(50px); */
  &__title {
    width: 90%;
    height: 8%;
    // border: 1px solid red;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    font-size: calc(28px * var(--heightmultiplier));
    font-weight: 600;
  }

  &__dataContainer {
    width: 90%;
    height: 78%;
    // border: 1px solid blue;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    &__header {
      width: 100%;
      height: 10%;
      // border: 1px solid green;
      font-size: calc(20px * var(--heightmultiplier));
      font-weight: 500;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      &__title {
      width: fit-content;
      height: 100%;
      font-size: calc(24px * var(--heightmultiplier));
      // border: 1px solid green;
      color:#3FDA76;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      }
    }
    &__detailsContainer {
      width: 90%;
      height: 89%;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: center;
      justify-content: flex-start;
      // border: 2px solid rgb(59, 11, 190);
      &__TotalItem {
        width: 100%;
        height: 10%;
        box-sizing: border-box;
        font-family: 'General Sans';
        font-style: normal;
        font-weight: 400;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        font-size: calc(20px * var(--heightmultiplier));
        // line-height: calc(27px * var(--heightmultiplier));
        color: var(--primaryTextColor);
        // margin-left: calc(3px * var(--heightmultiplier));
        // border: 2px solid rgb(243, 195, 38);
      }
  
      &__sub-Container {
        width: 100%;
        height: 100%;
        //padding: calc(5px * var(--heightmultiplier));
        color: var(--primaryTextColor);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        // border: 2px solid rgb(3, 2, 9);
  
        &__title {
          width: 100%;
          height: 15%;
          // border: 1px solid yellow;
          display: flex;
          // flex-direction: column;
          text-align: center;
          align-items: center;
          justify-content: space-between;
          background-color: rgb(206, 206, 207);
          color:var(--primaryTextColor);
          border-radius: calc(10px * var(--heightmultiplier));
  
          &__Sr-No {
            height: 100%;
            width: 10%;
            font-family: 'General Sans';
            display: flex;
            align-items: center;
            justify-content: center;
            font-style: normal;
            font-weight: 500;
            font-size: calc(18px * var(--heightmultiplier));
            //color: var(--primaryTextColor);
            //border: 1px solid magenta
          }
  
          &__name {
            height: 100%;
            width: 40%;
            font-family: 'General Sans';
            font-style: normal;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            font-size: calc(18px * var(--heightmultiplier));
            // color: var(--primaryTextColor);
            // border: 1px solid magenta
          }
  
          &__weight {
            height: 100%;
            width: 10%;
            font-family: 'General Sans';
            font-style: normal;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            font-size: calc(18px * var(--heightmultiplier));
            // color: var(--primaryTextColor);
            // border: 1px solid magenta
          }
  
          &__Volumetricweight {
            height: 100%;
            width: 14%;
            font-family: 'General Sans';
            font-style: normal;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            font-size: calc(18px * var(--heightmultiplier));
            //color: var(--primaryTextColor);
            // border: 1px solid magenta
          }
  
          &__quantity {
            height: 100%;
            width: 10%;
            font-family: 'General Sans';
            font-style: normal;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            font-size: calc(18px * var(--heightmultiplier));
            //color: var(--primaryTextColor);
            //border: 1px solid magenta
          }
  
          &__totalWeight {
            height: 100%;
            width: 15%;
            font-family: 'General Sans';
            font-style: normal;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            font-size: calc(18px * var(--heightmultiplier));
            //color: var(--primaryTextColor);
            //border: 1px solid magenta
          }
        }
  
        &__dataContainer {
          height: 80%;
          width: 100%;
          // border: 2px solid red;
          padding: calc(10px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));
          overflow-y: auto;
        }
        &__cancleReason {
          height: 70%;
          width: 100%;
          //border: 2px solid red;
          padding: calc(10px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));
          overflow-y: scroll;
        }
  
        &__listDown {
          width: 100%;
          height: 20%;
          // border: 1px solid yellow;
          display: flex;
          // flex-direction: column;
          text-align: center;
          align-items: center;
          justify-content: space-between;
          border-radius: calc(10px * var(--heightmultiplier));
          background-color: var(--secondaryColor);
  
          &:nth-of-type(even) {
            background-color: var(--primaryColor);
          }
  
          &__Sr-No {
            height: 100%;
            width: 10%;
            font-family: 'General Sans';
            display: flex;
            align-items: center;
            justify-content: center;
            font-style: normal;
            font-weight: 500;
            font-size: calc(16px * var(--heightmultiplier));
            color: var(--primaryTextColor);
            //border: 1px solid magenta
          }
  
          &__name {
            height: 100%;
            width: 40%;
            font-family: 'General Sans';
            font-style: normal;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            font-size: calc(16px * var(--heightmultiplier));
            color: var(--primaryTextColor);
            //border: 1px solid magenta
          }
  
          // &__unit-price{
          //   height: 100%;
          //   width: 12%;
          //   font-family: 'General Sans';
          //   font-style: normal;
          //   display: flex;
          //     align-items: center;
          //     justify-content: center;
          //   font-weight: 500;
          //   font-size: calc(20px * var(--heightmultiplier));
          //   color: var(--primaryTextColor);
          //     border: 1px solid magenta
  
          // }
          &__weight {
            height: 100%;
            width: 10%;
            font-family: 'General Sans';
            font-style: normal;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            font-size: calc(16px * var(--heightmultiplier));
            color: var(--primaryTextColor);
            //border: 1px solid magenta
          }
  
          &__Volumetricweight {
            height: 100%;
            width: 14%;
            font-family: 'General Sans';
            font-style: normal;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            font-size: calc(16px * var(--heightmultiplier));
            color: var(--primaryTextColor);
            //border: 1px solid magenta
          }
  
          &__quantity {
            height: 100%;
            width: 10%;
            font-family: 'General Sans';
            font-style: normal;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            font-size: calc(16px * var(--heightmultiplier));
            color: var(--primaryTextColor);
            //border: 1px solid magenta
          }
  
          &__totalWeight {
            height: 100%;
            width: 15%;
            font-family: 'General Sans';
            font-style: normal;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            font-size: calc(16px * var(--heightmultiplier));
            color: var(--primaryTextColor);
            // border: 1px solid magenta
          }
  
          // &__total-price{
          //   height: 100%;
          //   width: 12%;
          //   font-family: 'General Sans';
          //   font-style: normal;
          //   display: flex;
          //     align-items: center;
          //     justify-content: center;
          //   font-weight: 500;
          //   font-size: calc(20px * var(--heightmultiplier));
          //   color: var(--primaryTextColor);
          //     border: 1px solid magenta
          // }
        }
  
        &__totalWeight {
          width: 100%;
          height: 10%;
          // border: 1px solid yellow;
          display: flex;
          align-items: flex-end;
          justify-content: flex-start;
          background-color: var(--primaryColor);
  
          &__title {
            height: 100%;
            width: 20%;
            font-family: 'General Sans';
            display: flex;
            align-items: center;
            justify-content: center;
            font-style: normal;
            font-weight: 500;
            font-size: calc(18px * var(--heightmultiplier));
            color: var(--primaryTextColor);
            //border: 1px solid magenta
          }
  
          &__value {
            height: 100%;
            width: 10%;
            font-family: 'General Sans';
            font-style: normal;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            font-size: calc(18px * var(--heightmultiplier));
            color: var(--primaryTextColor);
            //border: 1px solid magenta
          }
        }
      }
    }
  }
  &__confirmDelivery{
    height: 12%;
    width: 100%;
    // border: 2px solid blue;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &__note{
     height: fit-content;
    width: 100%;
    // border: 2px solid rgb(255, 0, 0);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #878B8F;
    }
    &__confirmBtn{
      height: 90%;
      width: 10%;
      border: 2px outset rgba($color: #3FDA76, $alpha: 1.0);
      background-color: rgba($color: #3FDA76, $alpha: 0.2);
      font-size: calc(18px * var(--heightmultiplier));
      font-weight: 500;
      border-radius: calc(10px * var(--heightmultiplier));
      color:rgba($color: var(--primaryTextColor), $alpha: 1.0);
      &:hover{
        background-color: rgba($color: #3FDA76, $alpha: 0.8);
        color: white;
        border: 2px inset rgba($color: #3FDA76, $alpha: 1.0);
      }
    }
  }

  &__box-msg {
    font-style: normal;
    font-weight: 90%;
    width: 90%;
    height: 90%;
    font-size: calc(16px* var(--heightmultiplier));
    text-align: center;
    color: var(--primaryTextColor);
    opacity: 0.8;
    //border:1px solid red;
    // margin-left:calc(10px*var(--heightmultiplier));
    // margin: calc(5px* var(--heightmultiplier));
  }


  // &__box-img {
  //   /* width: calc(45px * var(--widthmultiplier)); */
  //   height: 29.17%;
  //   /* width: ; */
  // // }
  // &__box-button-container {
  //   display: flex;
  //   height: fit-content;
  //   width: 100%;
  //   justify-content: space-evenly;
  //   align-items: center;
  // }

}





.custom-alert-box-name {
  height: fit-content;
  width: fit-content;

  font-style: normal;
  font-weight: 600;
  font-size: 2.5em;

  color: #1F2633;

}

#cancelBtn {
  width: 25.43%;
  height: fit-content;

  font-style: normal;
  font-weight: 400;
  font-size: 2em;
  color: #FC6025;
  background-color: rgba(253, 97, 37, 0.1);
  border-radius: 5px;
  z-index: 12;
  display: flex;
  padding: 0px 10px;
  align-items: center;
  justify-content: center;
  /* border: 2px solid #FC6025; */
}

#cancelBtn:hover {
  cursor: pointer;
  /* color: white; */
  font-weight: 600;
  background-color: #FC6025;
  color: white;
}

#OkayBtn {

  box-sizing: border-box;
  width: 25.43%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 600;
  font-size: 2em;
  background-color: rgba(253, 97, 37, 1);
  border-radius: 5px;
  color: white;

}

#OkayBtn:hover {
  cursor: pointer;
  background-color: #FC6025;
}