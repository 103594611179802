.HelpFormContainer{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    // border: 1px solid red;
    // overflow-y: auto;
    // iframe {
    //     // border: 1px solid #ccc;
    //     border-radius: 8px;
    //   }
    &__google-form{
        width: 100%;
        height: 800px;
        border: none;
    
        /* Remove Google Form footer */
        &::-webkit-scrollbar {
          display: none; /* Hide scrollbar */
        }
    
        /* Hiding footer using absolute positioning */
        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 50px; /* Adjust height based on the footer */
          background: white; /* Matches form background */
        }
      }
  }