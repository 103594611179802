

.createOrderSelect-container {
    width: 100%;
    height: 100%;
    background-color: var(--secondaryColor);
    overflow: hidden;
    // padding: 0px;
    // margin: 0px;
    // background-color: var(--primaryColor);
    padding: calc(15px* var(--heightmultiplier)) calc(15px* var(--widthmultiplier));
    // border-radius: calc(16px * var(--heightmultiplier));
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
    // border: 2px solid red;
    top: 0px;
    // left: 0px;
    // &__Header{
    //     width: 100%;
    //     height: 8%;
    //     // border: 1px solid green;
    //     display: flex;
    //     flex-direction: row;
    //     text-align: left;
    //     align-items: center;
    //     justify-content: flex-start;
    //     &__title{
    //         width: 100%;
    //         height: 100%;
    //         border-bottom: 1px solid var(--primaryTextColor);
    //         // text-align: center;
    //         color: var(--primaryTextColor);
    //         font-size: calc(28px * var(--heightmultiplier));
    //         font-weight: 600;
    //         display: flex;
    //         flex-direction: row;
    //         align-items: center;
    //         justify-content: center;
    //     }

    // }
    &__Header2{
        width: 100%;
        height: fit-content;
        // border: 1px solid green;
        display: flex;
        flex-direction: row;
        text-align: left;
        align-items: flex-start;
        justify-content: flex-start;
        // margin-top: calc(10px * var(--heightmultiplier));
        &__title{
            width: 100%;
            height: 100%;
            // border-bottom: 1px solid var(--primaryTextColor);
            // text-align: center;
            color: var(--primaryTextColor);
            font-size: calc(28px * var(--heightmultiplier));
            font-weight: 600;
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: flex-start;
        }
    }
    &__cardComponent{
        width: 100%;
        height: 92%;
        padding: calc(20px * var(--heightmultiplier)) calc(100px * var(--widthmultiplier));
        // border: 1px solid blue;
        // display: grid;
        // grid-template-columns: 1fr 1fr; 
        // grid-template-rows: auto auto;
        display: flex;
        flex-wrap: wrap;
        gap: calc(100px * var(--heightmultiplier));
        justify-content: center;
        align-items: center
    }

    &__orderModel_container{
        width: 100%;
        height: 100%;
        background-color: rgb(169, 166, 166, 0.6);
        padding: calc(15px* var(--heightmultiplier)) calc(15px* var(--widthmultiplier));
        // border: 1px solid green;
        display: flex;
        align-items: center;
        justify-content: center;
        // position: absolute;
        // left: 0px;
        // top:0px;
        z-index: 2;
        // background-color: red;
    }

}
