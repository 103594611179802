@import "../variables";

.CategoryAddForm {
  position: relative;
  width: 100%;
  height: 94%;
  // height: calc(775px * var(--heightmultiplier));
  box-sizing: border-box;
  background-color: var(--primaryColor);
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  // border: 1px solid purple;
  border-radius: calc(16px * var(--heightmultiplier))
    calc(0px * var(--heightmultiplier)) calc(16px * var(--heightmultiplier))
    calc(16px * var(--heightmultiplier));
  &__container {
    width: 98%;
    height: 97%;
    //width: 70%;
    //border: 1px solid red;
    text-align: center;
    margin: calc(8px * var(--heightmultiplier))
      calc(5px * var(--widthmultiplier));
    &__header {
      width: 100%;
      height: 7%;
      box-sizing: border-box;
      font-family: "General Sans";
      font-style: normal;
      font-weight: 500;
      align-self: center;
      //border: 2px solid rgb(61, 14, 190);
      //border-right: 1px solid black;
      // margin: calc(8px * var(--heightmultiplier))
      // calc(5px * var(--widthmultiplier));
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: calc(10px * var(--heightmultiplier));
      font-size: calc(24px * var(--heightmultiplier));
      line-height: calc(54px * var(--heightmultiplier));
      color: white;
      background-color: rgb(124, 124, 124);
      padding: calc(0px * var(--widthmultiplier))
        calc(10px * var(--heightmultiplier));
      &__title {
        width: 30%;
        //border-right: 2px solid black;
        //border: 1px solid green;
      }
    }
    &__categoryTable {
      overflow-y: scroll;
      width: 100%;
      height: 85%;
      // padding: calc(2px * var(--heightmultiplier))
      //   calc(10px * var(--widthmultiplier));
      font-size: calc(20px * var(--heightmultiplier));
      margin: calc(8px * var(--heightmultiplier))
       calc(5px * var(--widthmultiplier));
      //border: 2px solid green;
      &__data {
        width: 100%;
        //width:fit-content;
        height: calc(45px * var(--heightmultiplier));
        //height: fit-content;
        //border: 1px solid black;
        padding: calc(5px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
        margin-top: calc(5px * var(--heightmultiplier));
        background-color: var(--secondaryColor);
        font-size: calc(20px * var(--heightmultiplier));
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-radius: calc(8px * var(--heightmultiplier));
        &:nth-of-type(even) {
          background-color: var(--primaryColor);
        }
        &__item {
          width: 30%;
          height: 100%;
           //border: 1px solid blue;
          &__editButton {
            border: none;
            width: 50%;
            height: fit-content;
            // padding: calc(5px * var(--widthmultiplier))
            //   calc(10px * var(--heightmultiplier));
            padding: calc(2px * var(--heightmultiplier))
              calc(5px * var(--widthmultiplier));
            border-radius: calc(10px * var(--heightmultiplier));
            background-color: rgba($color: #03bd1c, $alpha: 0.1);
            color: var(--primaryTextColor);
            &:hover {
              cursor: pointer;
              color: white;
              background-color: rgba($color: #03bd1c, $alpha: 0.5);
            }
          }
        }
        &__itemStart {
          width: 30%;
          height: fit-content;
          //border: 1px solid blue;
          padding: calc(2px * var(--heightmultiplier))
              calc(5px * var(--widthmultiplier));
        }
        &__itemEnd {
          width: 30%;
          height: fit-content;
          //border: 1px solid blue;
          &__deleteButton {
            font-size: calc(10px * var(--heightmultiplier));
            padding: calc(2px * var(--heightmultiplier))
              calc(5px * var(--widthmultiplier));
            color: red;
            // font-size: calc(24px * var(--heightmultiplier));
            &:hover {
              // font-size: calc(40px * var(--heightmultiplier));
              cursor: pointer;
            }
          }
        }
      }
    }
    &__addButton {
      width: fit-content;
      height: fit-content;
      //border: 1px solid pink;
      float: right;
      padding: calc(5px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
      &__categoryAddbtn {
        border: none;
        padding: calc(2px * var(--heightmultiplier))
          calc(20px * var(--widthmultiplier));
        border-radius: calc(10px * var(--heightmultiplier));
        font-weight: 500;
        font-size: calc(24px * var(--heightmultiplier));
        background-color: rgba($color: #fd6125, $alpha: 0.2);
        color: var(--primaryTextColor);
        &:hover {
          cursor: pointer;
          color: white;
          background-color: rgba($color: #fd6125, $alpha: 0.8);
        }
      }
    }
  }
}
