.ordersListItem-container{
    height: calc(65px * var(--heightmultiplier));
    width: calc(1550px * var(--widthmultiplier));
    background-color: var(--primaryColor);
    border-radius: calc(10px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
    padding: calc(0px * var(--heightmultiplier)) calc(50px * var(--widthmultiplier));
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: calc(16px * var(--heightmultiplier));
    color: var(--primaryTextColor);
    // border: 1px solid black;

    // &:hover{
    //     //background-color: rgba($color: #FD6125, $alpha: 1);
    //     // box-shadow: #FD6125 0px 0px 15px;
    //     border: 2px solid #FD6125;
    //     cursor: pointer;
    // }
    &__Sr-No {
        height: 100%;
        width: calc(60px * var(--widthmultiplier));
        font-family: 'General Sans';
        font-style: normal;
        font-weight: 500;
        font-size: calc(20px * var(--heightmultiplier));
        line-height: calc(27px * var(--heightmultiplier));
        padding: 0px;
        color: rgba($color: var(--primaryColor), $alpha: 0.4);
        // border: 1px solid black;
        display: flex;
        align-items: center;
        justify-content: center;
        // opacity: 0.4;
    }

    &__order-no {
        // height: calc(80px * var(--heightmultiplier));
        width: calc(300px * var(--widthmultiplier));
        font-family: 'General Sans';
        font-style: normal;
        font-weight: 500;
        font-size: calc(20px * var(--heightmultiplier));
        line-height: calc(27px * var(--heightmultiplier));
        // border: 1px solid black;

        color:inherit;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__quantity {
        // height: calc(80px * var(--heightmultiplier));
        width: calc(200px * var(--widthmultiplier));
        font-family: 'General Sans';
        font-style: normal;
        font-weight: 500;
        font-size: calc(20px * var(--heightmultiplier));
        line-height: calc(27px * var(--heightmultiplier));
        display: flex;
        align-items: center;
        justify-content: center;

        color:inherit; // opacity: 0.4;
    }

    &__totalWeight {
        // height: calc(80px * var(--heightmultiplier));
        width: calc(150px * var(--widthmultiplier));
        font-family: 'General Sans';
        font-style: normal;
        font-weight: 500;
        font-size: calc(20px * var(--heightmultiplier));
        line-height: calc(27px * var(--heightmultiplier));
        text-align: center;

        color:inherit;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__total-quantity {
        width: calc(150px * var(--widthmultiplier));
        font-family: 'General Sans';
        font-style: normal;
        font-weight: 500;
        font-size: calc(20px * var(--heightmultiplier));
        line-height: calc(27px * var(--heightmultiplier));
        text-align: center;

        color:inherit;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__total-price {
        width: calc(150px * var(--widthmultiplier));
        font-family: 'General Sans';
        font-style: normal;
        font-weight: 500;
        font-size: calc(20px * var(--heightmultiplier));
        line-height: calc(27px * var(--heightmultiplier));
        text-align: center;

        color:inherit;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__status {
        width: calc(200px * var(--widthmultiplier));
        font-family: 'General Sans';
        font-style: normal;
        font-weight: 500;
        font-size: calc(20px * var(--heightmultiplier));
        line-height: calc(27px * var(--heightmultiplier));
        text-align: center;
        color:inherit;
        // border: 1px solid black;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__actions{
        width: calc(100px * var(--widthmultiplier));
        height: 50%;
        font-family: 'General Sans';
        font-style: normal;
        font-weight: 500;
        font-size: calc(14px * var(--heightmultiplier));
        // line-height: calc(27px * var(--heightmultiplier));
        border-radius: calc(16px * var(--heightmultiplier)* var(--widthmultiplier)/2);
        // text-align: center;
        border: 2px solid rgba($color: #FD6125, $alpha: 1);
        background-color: rgba($color: #FD6125, $alpha: 0.2);
        color:var(--primaryTextColor);
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover{
            background-color: rgba($color: #FD6125, $alpha: 0.8);
            color: white;
        }
    }
}