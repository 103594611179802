.modalBackground {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(6, 6, 6, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
  /* border: 1px solid black; */
}



.LoadingScreen__modalContainer {
  // width: calc(600px * var(--widthmultiplier));
  // height: calc(600px * var(--widthmultiplier));

  // border-radius: 0.75em;

  // background-color: var(--primaryColor);;
  // box-shadow: 0px 0px 15px #FD6125;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: space-evenly;
  // /* border: 5px solid #FC6025; */
  // /* padding-top: calc(50px);
  // padding-bottom: calc(50px); */
  width: calc(200px * var(--widthmultiplier));
  height: calc(200px * var(--widthmultiplier));
  box-sizing: border-box;
  border: calc(20px * var(--widthmultiplier)) solid #FD6125;
  border-right-color: rgba(6, 6, 6, 0.0);
  border-radius: 50%;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

.LoadingScreen__loader{
  width: calc(500px * var(--widthmultiplier));
  height: calc(500px * var(--widthmultiplier));
  border-radius: 50%;
  border: calc(20px * var(--widthmultiplier)) solid #FD6125;
  border-right: none;

  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;

}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}