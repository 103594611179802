@import "../variables";



.InventoryManagementContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  // border: 1px solid red;
}

.ManageInventory {
  // border: 1px solid black;
  width: 98%;
  height: 8%;
  box-sizing: border-box;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top-right-radius: calc(16px * var(--heightmultiplier));

  &__title {
    height: 100%;
    width: 70%;
    box-sizing: border-box;
    font-family: "General Sans";
    font-style: normal;
    font-weight: 500;
    font-size: calc(40px * var(--heightmultiplier));
    // line-height: calc(54px * var(--heightmultiplier));
    color: var(--primaryTextColor);
    background-color: var(--secondaryColor);
    padding: calc(0px * var(--heightmultiplier)) calc(20px * var(--widthmultiplier));
    // border: 1px solid black;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;

    // margin-bottom: calc(48px * var(--heightmultiplier));
    &__bulkUpload {
      // width: 35%;
      height: 100%;
      // border: 1px solid blue;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
    }
  }

  &__block {
    height: 100%;
    width: 98%;
    // border: 1px solid blue;
  }

  &__header {
    height: 100%;
    background-color: var(--secondaryColor);
    align-items: center;
    display: flex;
    align-items: flex-start;
    //margin: calc(0px * var(--heightmultiplier)) calc(4px * var(--widthmultiplier));
    //margin: 0px;
    background-color: var(--primaryColor);
    border-top-left-radius: calc(16px * var(--heightmultiplier));
    border-top-right-radius: calc(16px * var(--widthmultiplier));

    // border: 1px solid black;
    &__links {
      //width: 45%;
      height: 100%;
      border: none;
      font-size: calc(20px * var(--heightmultiplier));
      color: #5f5e5e;
      font-weight: calc(50px * var(--heightmultiplier));
      //border: 1px solid white;
      //border-radius: calc(10px * var(--heightmultiplier));
      border-top-left-radius: calc(16px * var(--heightmultiplier));
      background-color: transparent;
      border-right: 2px solid rgb(117, 115, 115);
      padding: calc(12px * var(--heightmultiplier)) calc(15px * var(--widthmultiplier));

      &:hover {
        //border-top-left-radius: calc(16px * var(--heightmultiplier));
        background-color: #cecdcd;
        color: black;

      }

      &__active {
        color: white;
        background-color: #fd6125;
      }
    }

    &__link {
      //width: 30%;
      height: 100%;
      border: none;
      font-size: calc(20px * var(--heightmultiplier));
      color: #5f5e5e;
      font-weight: calc(50px * var(--heightmultiplier));
      //border: 1px solid black;
      background-color: transparent;
      border-top-right-radius: calc(16px * var(--heightmultiplier));
      //border-right: 2px solid rgb(117, 115, 115);
      padding: calc(12px * var(--heightmultiplier)) calc(15px * var(--widthmultiplier));
      margin-right: calc(-5px * (--widthmultiplier));

      &:hover {
        //border-top-left-radius: calc(16px * var(--heightmultiplier));
        background-color: #cecdcd;
        color: black;

      }
      &__active {
        color: white;
        background-color: #fd6125;
      }
    }


    &__link1 {
      height: 100%;
      //width: 30%;
      border: none;
      font-size: calc(20px * var(--heightmultiplier));
      color: #5f5e5e;
      font-weight: calc(50px * var(--heightmultiplier));
      //border: 1px solid black;
      background-color: transparent;
      //
      //border-top-right-radius: calc(16px * var(--heightmultiplier));
      border-right: 2px solid rgb(117, 115, 115);
      padding: calc(12px * var(--heightmultiplier)) calc(15px * var(--widthmultiplier));

      &:hover {
        //border-top-left-radius: calc(16px * var(--heightmultiplier));
        background-color: #cecdcd;
        color: black;

      }
      &__active {
        color: white;
        background-color: #fd6125;
      }
    }
  }
}

.allInventoryButtons {
  display: flex;
  align-items: center;
  gap: 1rem;
  height: 54px;
}