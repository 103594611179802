

.MyProfileAddress-SubConatiner {
    width: 98%;
    height: 90.5%;
    //border: 1px solid red;
    border-radius: calc(10px * var(--heightmultiplier));
    background-color: var(--primaryColor);
    padding: calc(15px* var(--heightmultiplier)) calc(10px* var(--widthmultiplier));
  
    &__DataConatiner {
      width: 25%;
      height: fit-content;
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: flex-start;
      border: 4px solid var(--secondaryTextColor);
      margin: 1.5rem;
      border-radius: calc(10px * var(--heightmultiplier));
  
      &__subContainer {
        width: 100%;
        height: fit-content;
        //border: 1px solid #FD6125;
        font-weight: 500;
        font-size: calc(24px * var(--heightmultiplier));
        //margin: calc(10px * var(--heightmultiplier));
      }
  
      &__title {
        width: 97%;
        height: fit-content;
        //border: 1px solid #FD6125;
        border-bottom: 2px solid var(--secondaryTextColor);
        font-weight: 500;
        font-size: calc(24px * var(--heightmultiplier));
        margin: calc(0px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
      }
  
      &__label {
        display: flex;
        align-items: flex-start;
        margin-left: 0.2rem;
        padding-left: 1%;
        //border: 1px solid red;
        width: 98%;
        font-weight: 400;
        font-size: calc(20px * var(--heightmultiplier));
        padding: calc(5px * var(--heightmultiplier)) calc(2px * var(--widthmultiplier));
        height: fit-content;
        background-color: var(--primaryColor);
      }
  
      &__value {
        width: 97%;
        height: fit-content;
        box-sizing: border-box;
        margin: 0.2rem;
        padding-left: 1%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        //border: 1px solid black;
        border-bottom: 1px solid var(--primaryTextColor);
        font-weight: 500;
        font-size: calc(16px * var(--heightmultiplier));
        color: rgb(104, 104, 104);
  
        &__icon {
          width: 10%;
          height: 90%;
          float: right;
          color: var(--primaryTextColor);
  
          &:hover {
            cursor: pointer;
            color: #FD6125;
          }
  
          &:focus {
            cursor: pointer;
            color: #6CCC54;
          }
        }
      }
      &__chngPassBtn{
        width: 45%;
        //height: 70%;
        margin: calc(10px * var(--heightmultiplier));
        font-size: calc(16px * var(--heightmultiplier));
        font-weight: 450;
        border: none;
        background-color: rgba($color: #FD6125, $alpha: 0.2);
        color: var(--primaryTextColor);
        border-radius: calc(10px * var(--heightmultiplier));
        padding: calc(5px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
        &:hover{
          cursor: pointer;
          background-color: rgba($color: #FD6125, $alpha: 1);
          color: white;
          box-shadow: var(--primaryTextColor) 0px 0px 5px;;
        }
      }
    }
  
  }