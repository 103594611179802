.InputForm-container{
  position:relative;
  z-index: 100;
  min-height: calc(32px * var(--heightmultiplier));
  width: 40%;
  padding: calc(0.2% * var(--heightmultiplier));
  // border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--primaryColor);
  overflow: visible;
    &__inputField{
      height: 98%;
      box-sizing: border-box;
      width: 98%;
      //font-size: 16%;
      font-size: calc(16px * var(--heightmultiplier));
      border: none;
      outline: none;
      position: absolute;
      background-color: transparent;
      left: 0;
      z-index:100;
      padding: 1%;
    }
    &__label{
      height:max-content;
      position: absolute;
      display: flex;
      //font-size: 16px;
      font-size: calc(16px * var(--heightmultiplier));
      background-color: var(--primaryColor);
      color: var(--primaryTextColor);
      padding: 0.1% 0.5%;
      // padding: calc(0.5%* var(--heightmultiplier)) calc(0.5% * var(--widthmultiplier));
      transform: scale(1);
      transition: all 0.3s linear;
      z-index: 101;
      // &::before{
      //   content:"";
      //   border: 1px solid
      // }
    }
    &__label2{
      height:max-content;
      position: absolute;
      display: flex;
      //font-size: 16px;
      font-size: calc(16px * var(--heightmultiplier));
      background-color: var(--primaryColor);
      color: rgb(250, 125, 9);
      //transform: translate(0, calc(-75% * var(--heightmultiplier))) scale(0.8);
      transform: translate(0, calc(-20px * var(--heightmultiplier))) scale(0.8);
      // padding: calc(5px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
      //padding: calc(0.5%* var(--heightmultiplier)) calc(0.8% * var(--widthmultiplier));
      padding: 0.2% 0.5%;
      // transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      transition: all 0.2s linear;
      z-index: 101;
    }

    &__box1{
      position: absolute;
      left: 0px;
      z-index: 50;
      height: 100%;
      border: 1px solid black;
      width: calc(10px * var(--heightmultiplier));
      border-right: none;
      border-top-left-radius: calc(10px * var(--heightmultiplier));
      border-bottom-left-radius: calc(10px * var(--heightmultiplier));
    }
    &__box2{
      position: absolute;
      color: transparent;
      left: calc(10px * var(--heightmultiplier));
      z-index: 50;
      height: 100%;
      background-color: transparent;
      border: 1px solid black;
      border-right: none;
      border-left: none;

    }
    &__box3{
      position: absolute;
      left: 0px;
      z-index: 50;
      height: 100%;
      border: 1px solid black;
      border-left: none;
      border-top-right-radius: calc(10px * var(--heightmultiplier));
      border-bottom-right-radius: calc(10px * var(--heightmultiplier));
    }
}