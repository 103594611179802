@import "../variables";


.CreateOrderInventoryShowSubContent-container {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    // border: 1px solid black;

    &__titleContainer {
        width: 100%;
        height: fit-content;
        // border: 1px solid red;
        background-color: var(--primaryColor);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        // border-radius: calc(16px * var(--heightmultiplier));

        // border-bottom-left-radius: calc(10px * var(--heightmultiplier));
        &__container {
            width: 65%;
            height: 40%;
            // border: 1px solid red;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            &__title {
                font-family: 'General Sans';
                font-style: normal;
                font-weight: 500;
                font-size: calc(30px * var(--heightmultiplier));
                line-height: calc(30px * var(--heightmultiplier));
                margin-top: calc(9px * var(--heightmultiplier));
                margin-bottom: calc(9px * var(--heightmultiplier));
                margin-left: calc(10px * var(--heightmultiplier));
                // border: 1px solid yellow;
            }

            &__category-dropdown {
                border: 1px solid #ccc;
                width: 16%;
                height: 100%;
                border-radius: calc(10px * var(--heightmultiplier));
                background-color: var(--primaryColor);
                border-color: rgba($color: #4285F4, $alpha: 0.5);
                outline: none;
                // z-index: 1;
                // font-family: 'General Sans';
                font-style: normal;
                font-weight: 500;
                // border: 1px solid yellow;

                &:focus {
                    //   border-color: #89c2ff;
                    box-shadow: 0 0 px rgba(0, 123, 255, 0.5);
                }
            }
        }

        &__customerDeatis-container {
            width: 100%;
            height: fit-content;
            // border: 1px solid green;
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-start;
            padding: calc(8px * var(--heightmultiplier)) calc(8px * var(--widthmultiplier));

            &__locationCont1 {
                width: 20%;
                height: fit-content;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-evenly;
                width: fit-content;
                height: fit-content;
                border-radius: calc(10px * var(--heightmultiplier));
                padding: calc(5px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));
                color: var(--primaryTextColor);
                border: 2px solid var(--primaryTextColor);

                &__label {
                    width: calc(130px * var(--heightmultiplier));
                    height: fit-content;
                    // margin-top: calc(-20px * var(--heightmultiplier));
                    margin-left: calc(10px * var(--heightmultiplier));
                    padding: calc(0px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
                    // border: 1px solid blue;
                    font-size: calc(14px * var(--heightmultiplier));
                    font-weight: 500;
                    display: flex;
                    text-align: flex-start;
                    align-items: center;
                    margin-top: calc(-48px * var(--heightmultiplier));
                    background-color: var(--primaryColor);
                    color: var(--primaryTextColor)
                }
            }

            &__customerInput {
                width: fit-content;
                height: fit-content;
                // position: absolute;
                // top: 20px;
                // left: 2px;
                // border: 2px solid var(--primaryTextColor);
                display: flex;
                text-align: center;
                align-items: center;
                justify-content: flex-start;
                // margin-left: calc(0px* var(--widthmultiplier));
                // padding: calc(0px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
                // overflow-y: hidden;
                // position: relative;

                &__label {
                    width: fit-content;
                    // margin-left: calc(10px * var(--heightmultiplier));
                    padding: calc(0px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
                    height: fit-content;
                    display: flex;
                    text-align: center;
                    align-items: center;
                    justify-content: space-evenly;
                    // border: 1px solid blue;
                    font-size: calc(16px * var(--heightmultiplier));
                    font-weight: 500;
                    // padding: calc(5px * var(--heightmultiplier))

                }

                &__value {
                    width: 60%;
                    height: fit-content;
                    // border: 1px solid blue;
                    font-size: calc(16px * var(--heightmultiplier));
                    border: none;
                    outline: none;
                    display: flex;
                    text-align: flex-start;
                    align-items: center;
                    background-color: var(--primaryColor);
                    border-radius: calc(10px * var(--heightmultiplier));
                    padding: calc(5px * var(--heightmultiplier));

                    &__input {
                        width: fit-content;
                        height: fit-content;
                        outline: none;
                        border-radius: calc(5px * var(--heightmultiplier));
                        padding: calc(0px * var(--heightmultiplier)) calc(2px * var(--widthmultiplier));
                        //border: none;
                        border: 1px solid var(--primaryTextColor);
                        color: var(--primaryTextColor);
                        background-color: var(--primaryColor);
                    }

                    &__input1 {
                        width: fit-content;
                        height: fit-content;
                        //outline: none;
                        border-radius: calc(5px * var(--heightmultiplier));
                        padding: calc(0px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));
                        //border: none;
                        border-top-right-radius: 0px;
                        border-bottom-right-radius: 0px;
                        border-right: none;
                        border: 1px solid var(--primaryTextColor);
                        color: var(--primaryTextColor);
                        background-color: var(--primaryColor);
                    }

                    &__input2 {
                        width: fit-content;
                        height: fit-content;
                        //outline: none;
                        border-radius: calc(5px * var(--heightmultiplier));
                        padding: calc(0px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));
                        border-top-left-radius: 0px;
                        border-bottom-left-radius: 0px;
                        //border: none;
                        border-left: none;
                        border: 1px solid var(--primaryTextColor);
                        color: var(--primaryTextColor);
                        background-color: var(--primaryColor);
                    }
                }
            }
        }
    }

    &__editCustomer {
        width: 5%;
        height: fit-content;
        position: absolute;
        right: 6px;
        border: 1px solid #FD6125;
        border-radius: calc(10px * var(--heightmultiplier));
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        background-color: rgba($color: #FD6125, $alpha: 0.2);

        &:hover {
            background-color: rgba($color: #FD6125, $alpha: 1);
            color: white;
        }
    }



    // &__title {
    //     box-sizing: border-box;
    //     font-family: 'General Sans';
    //     font-style: normal;
    //     font-weight: 500;
    //     font-size: calc(40px * var(--heightmultiplier));
    //     line-height: calc(54px * var(--heightmultiplier));
    //     color: var(--primaryTextColor);
    //     margin-bottom: calc(48px * var(--heightmultiplier));

    // }

    &__subContainer {
        box-sizing: border-box;
        width: 100%;
        height: 92%;
        margin-top: calc(5px* var(--heightmultiplier));
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        // border: 1px solid black;

        &__products-view {
            // box-sizing: border-box;
            height: 100%;
            // width: 100%;
            // border: 1px solid red;
            // overflow-y: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            // flex-wrap: wrap;

            // padding-top: calc(15px * var(--heightmultiplier));
            // padding-bottom: calc(15px * var(--heightmultiplier));
            // padding-right: calc(10px * var(--widthmultiplier));
            // padding-left: calc(10px * var(--widthmultiplier));
            &__title {
                width: 100%;
                height: 5%;
                // border: 2px solid purple;
                box-sizing: border-box;
                font-family: 'General Sans';
                font-style: normal;
                font-weight: 500;
                font-size: calc(40px * var(--heightmultiplier));
                line-height: calc(54px * var(--heightmultiplier));
                color: var(--primaryTextColor);
                margin-bottom: calc(2px * var(--heightmultiplier));
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
            }

            &__product-container {
                width: 100%;
                height: 94%;
                // border: 2px solid yellow;
                direction: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                &__noProductFound-text {
                    width: 100%;
                    height: 100%;
                    // border: 2px solid green;
                    direction: flex;
                    flex-direction: row;
                    text-align: center;
                    align-content: center;
                    justify-items: center;
                    font-size: calc(28px * var(--heightmultiplier));
                    font-weight: 500;
                }
            }
        }

        &__product-description {
            position: relative;
            box-sizing: border-box;
            height: 100%;
            background-color: var(--primaryColor);
            ;
            width: 34%;
            //   ?  border: 1px solid black;
            padding: calc(41px * var(--heightmultiplier)) calc(41px * var(--widthmultiplier));
            border-radius: calc(10px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;


            &__title {
                font-family: 'General Sans';
                font-style: normal;
                font-weight: 500;
                font-size: calc(30px * var(--heightmultiplier));
                line-height: calc(30px * var(--heightmultiplier));
                margin-top: calc(9px * var(--heightmultiplier));
                margin-bottom: calc(25px * var(--heightmultiplier));
            }

            &__descrip {
                font-family: 'General Sans';
                font-style: normal;
                font-weight: 400;
                font-size: calc(18px * var(--heightmultiplier));
                line-height: calc(25px * var(--heightmultiplier));
                // color: var(--secondaryTextColor);
                color: rgba($color: var(--primaryTextColor), $alpha: 0.4);
                text-align: justify;
            }

            &__addToCart {
                // position: absolute;
                // bottom: calc(18px * var(--heightmultiplier));
                // left: 0;
                height: fit-content;
                width: 100%;
                font-family: 'General Sans';
                font-style: normal;
                font-weight: 400;
                font-size: calc(18px * var(--heightmultiplier));
                line-height: calc(25px * var(--heightmultiplier));
                // color: var(--secondaryTextColor);
                color: rgba($color: var(--primaryTextColor), $alpha: 0.4);
                display: flex;
                align-items: center;
                justify-content: space-around;
                // padding: calc(0px * var(--heightmultiplier)) calc(41px * var(--widthmultiplier));
                margin: calc(16px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));

                // border: 1px solid black;

                &__input {

                    padding: calc(5px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));
                    border: 2px solid rgba($color: #FD6125, $alpha: 0.4);
                    outline: none;
                    border-radius: calc(8px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
                    width: calc(150px * var(--widthmultiplier));
                }
            }

        }
    }
}