.OrderDetailsPopUp-modalBackground {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba($color: black, $alpha: 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    // border: 5px solid black; 
  }
  
  
  
  .OrderDetailsPopUp-modalContainer {
    width: 90%;
    height: 80%;
  
    border-radius: 0.75em;
  
    background-color: var(--primaryColor);;
    //box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    box-shadow: #FD6025 0px 0px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    // border: 5px solid #FC6025; 
     padding: calc(20px * var(--heightmultiplier));
  
    &__box-msg {
      width:100%;
      height: 100%;
      font-style: normal;
      font-weight: 70%;
      font-size: calc(16px* var(--heightmultiplier));
      text-align: center;
      color: var(--primaryTextColor);
      opacity: 0.8;
      // margin-left:calc(10px*var(--heightmultiplier));
      //margin: calc(5px* var(--heightmultiplier));
    }
  
  
    // &__box-img {
    //   /* width: calc(45px * var(--widthmultiplier)); */
    //   height: 29.17%;
    //   /* width: ; */
    // }
    &__box-button-container {
      display: flex;
      height: fit-content;
      width: 100%;
      justify-content: space-evenly;
      align-items: center;
    }
    
  }
  
  
  
  
  
  // .custom-alert-box-name {
  //   height: 100%;
  //   width: 100%;
  
  //   font-style: normal;
  //   font-weight: 600;
  //   //font-size: 2.5em;
  
  //   color: #1F2633;
  
  // }
  // #cancelBtn {
  //   width: 15%;
  //   height: fit-content;
  
  //   font-style: normal;
  //   font-weight: 400;
  //   font-size: 2em;
  //   color: #FC6025;
  //   background-color: rgba(253,97,37, 0.1);
  //   border-radius: 5px;
  //   z-index: 12;
  //   display: flex;
  //   padding: 0px 10px;
  //   align-items: center;
  //   justify-content: center;
  //   /* border: 2px solid #FC6025; */
  // }
  
  // #cancelBtn:hover {
  //   cursor: pointer;
  //   /* color: white; */
  //   font-weight: 600;
  //   background-color: #FC6025;
  //   color: white;
  // }
  
  // #OkayBtn {
    
  //   box-sizing: border-box;
  //   width:15%;
  //   height: fit-content;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   font-style: normal;
  //   font-weight: 600;
  //   font-size: 2em;
  //   background-color: rgba(253,97,37, 1);
  //   border-radius: 5px;
  //   color: white;
  
  // }
  
  // #OkayBtn:hover {
  //   cursor: pointer;
  //   background-color: #FC6025;
  // }
  .OrderCancelPopUp-modalBackground {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba($color: black, $alpha: 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    // border: 5px solid black; 
  }
  
  
  
  .OrderCancelPopUp-modalContainer {
    width: 60%;
    height: 50%;
  
    border-radius: 0.75em;
  
    background-color: var(--primaryColor);;
    box-shadow: #FD6025 0px 0px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    // border: 5px solid #FC6025; 
     padding: calc(20px * var(--heightmultiplier));
     box-shadow: #FD6025 0px 0px 15px;
  
    &__box-msg {
      width:100%;
      height: 100%;
      font-style: normal;
      font-weight: 70%;
      font-size: calc(16px* var(--heightmultiplier));
      text-align: center;
      color: var(--primaryTextColor);
      opacity: 0.8;
      // margin-left:calc(10px*var(--heightmultiplier));
      //margin: calc(5px* var(--heightmultiplier));
    }
  
  
    // &__box-img {
    //   /* width: calc(45px * var(--widthmultiplier)); */
    //   height: 29.17%;
    //   /* width: ; */
    // }
    &__box-button-container {
      display: flex;
      height: fit-content;
      width: 100%;
      justify-content: space-evenly;
      align-items: center;
    }
    
  }
  
  
  
  
  
  // .custom-alert-box-name {
  //   height: 100%;
  //   width: 100%;
  
  //   font-style: normal;
  //   font-weight: 600;
  //   font-size: 2.5em;
  
  //   color: #1F2633;
  
  // }
  // #cancelBtn {
  //   width: 15%;
  //   height: fit-content;
  
  //   font-style: normal;
  //   font-weight: 400;
  //   font-size: 2em;
  //   color: #FC6025;
  //   background-color: rgba(253,97,37, 0.1);
  //   border-radius: 5px;
  //   z-index: 12;
  //   display: flex;
  //   padding: 0px 10px;
  //   align-items: center;
  //   justify-content: center;
  //   /* border: 2px solid #FC6025; */
  // }
  
  // #cancelBtn:hover {
  //   cursor: pointer;
  //   /* color: white; */
  //   font-weight: 600;
  //   background-color: #FC6025;
  //   color: white;
  // }
  
  #OkayBtn {
    
    box-sizing: border-box;
    width:15%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 600;
    font-size: 2em;
    background-color: rgba(253,97,37, 1);
    border-radius: 5px;
    color: white;
  
  }
  
  #OkayBtn:hover {
    cursor: pointer;
    background-color: #FC6025;
  }