.UpdateFormContainer {
  width: 100%;
  height: 100%;
  //border: 1px solid black;
  display: block;

  // align-items: center;
  // justify-content: space-between;
  //background-color: white;
  //padding: calc(20px * var(--heightmultiplier));
  //font-size: calc(14px * var(--heightmultiplier));
  &__heading {
    width: 98%;
    height: 20%;
    //width: 70%;
    box-sizing: border-box;
    font-family: "General Sans";
    font-style: normal;
    font-weight: 500;
    align-self: center;
    //border: 2px solid orangered;
    //justify-content: space-between;
    font-size: calc(40px * var(--heightmultiplier));
    line-height: calc(54px * var(--heightmultiplier));
    color: var(--primaryTextColor);
    margin: calc(2px * var(--widthmultiplier)) calc(5px * var(--heightmultiplier));
  }

  &__updateContainer {
    width: 98%;
    height: fit-content;
    display: flex;
    align-items: center;
    // font-weight: 500;
    font-size: calc(18px * var(--heightmultiplier));

    //justify-content: space-between;
    //border: 2px solid rgb(59, 11, 190);
    &__img-container {
      width: 50%;
      height: 100%;
      padding: calc(5px * var(--heightmultiplier));
      color: var(--primaryTextColor);
      display: flex;
      margin: calc(10px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));
      // flex-direction: column;
      align-items: center;
      justify-content: space-between;

      //border: 1px solid maroon;
      &__image {
        height: calc(200px * var(--heightmultiplier));
        width: calc(200px * var(--heightmultiplier));
        //margin: calc(20px * var(--heightmultiplier));
      }

      &__hidden {
        display: none;
      }

      &__buttonImage {
        border: none;
        width: calc(30px * var(--heightmultiplier));
        //margin: calc(20px * var(--heightmultiplier));
        height: calc(30px * var(--heightmultiplier));
        //margin-top:calc(50px*var(--heightmultiplier)) ;
        //color: rgb(252, 35, 35);
        //margin-bottom: calc(20px * var(--heightmultiplier));
        background-color: transparent;
        color: var(--primaryTextColor);
        align-self: flex-end;

        &:hover {
          cursor: pointer;
          color: #fd6125;
        }

        &:focus {
          cursor: pointer;
          color: rgb(26, 119, 7);
        }
      }
    }

    &__subContainer {
      width: 100%;
      height: 100%;
      //padding: calc(5px * var(--heightmultiplier));
      color: var(--primaryTextColor);
      display: flex;
      align-items: center;
      // justify-content: space-between;
      //border: 1px solid pink;
    }

    &__lable {
      min-width: calc(140px * var(--heightmultiplier));
      height: calc(40px * var(--heightmultiplier));
      //height: fit-content;
      //width: 70%;
      display: flex;
      text-align: left;
      box-sizing: border-box;
      font-family: "General Sans";
      font-style: normal;
      font-weight: 500;
      //font-size: calc(18px * var(--heightmultiplier));
      line-height: calc(32px * var(--heightmultiplier));
      //border: 1px solid blue;
      //justify-items: flex-start;
      color: var(--primaryTextColor);
      margin: calc(2px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
    }

    &__colon {
      width: fit-content;
      height: calc(40px * var(--heightmultiplier));
      //height: fit-content;
      //width: 70%;
      box-sizing: border-box;
      font-family: "General Sans";
      font-style: normal;
      font-weight: 500;
      // font-size: calc(20px * var(--heightmultiplier));
      line-height: calc(32px * var(--heightmultiplier));
      //border: 1px solid blue;
      //justify-items: flex-start;
      color: var(--primaryTextColor);
      margin: calc(2px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
    }

    &__value {
      width: fit-content;
      height: calc(30px * var(--heightmultiplier));
      //height:fit-content;
      //width: 70%;
      outline: none;
      background-color: var(--primaryColor);
      border: none;
      box-sizing: border-box;
      font-family: "General Sans";
      font-style: normal;
      font-weight: 500;
      // font-size: calc(20px * var(--heightmultiplier));
      line-height: calc(32px * var(--heightmultiplier));
      color: var(--primaryTextColor);
      border-radius: calc(10px * var(--heightmultiplier));
      padding: calc(5px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
      margin: calc(2px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
    }

    &__itemData {
      width: fit-content;
      height: calc(30px * var(--heightmultiplier));
      //height:fit-content;
      //width: 70%;
      background-color: var(--primaryColor);
      //border: none;
      box-sizing: border-box;
      font-family: "General Sans";
      font-style: normal;
      font-weight: 500;
      // font-size: calc(20px * var(--heightmultiplier));
      line-height: calc(32px * var(--heightmultiplier));
      color: var(--primaryTextColor);
      border-radius: calc(10px * var(--heightmultiplier));
      padding: calc(0px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
      //border: 1px solid green;
      //justify-items: flex-start;
      margin: calc(0px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));

      &:hover {
        outline: none;
      }
    }
    &__itemData2 {
      width: fit-content;
      height: calc(30px * var(--heightmultiplier));
      //height:fit-content;
      //width: 70%;
      background-color: var(--primaryColor);
      //border: none;
      box-sizing: border-box;
      font-family: "General Sans";
      font-style: normal;
      font-weight: 500;
      // font-size: calc(20px * var(--heightmultiplier));
      line-height: calc(32px * var(--heightmultiplier));
      color: var(--primaryTextColor);
      border-radius: calc(8px * var(--heightmultiplier));
      padding: calc(0px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
      //border: 1px solid green;
      //justify-items: flex-start;
      margin: calc(0px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));

      &:hover {
        outline: none;
      }
    }
    &__btn{
      border: none;
      width: calc(26px * var(--heightmultiplier));
      height: fit-content;
      font-size: calc(22px * var(--heightmultiplier));
      font-weight: 600;
     // padding: calc(10px * var(--heightmultiplier));
      // height: calc(75px * var(--heightmultiplier));
      margin-left: calc(3px * var(--heightmultiplier));
      // border-top-right-radius: calc(10px * var(--heightmultiplier));
      // border-bottom-right-radius: calc(10px * var(--heightmultiplier));
      border-radius: calc(5px * var(--heightmultiplier));
      background-color: rgba($color: #fd6125, $alpha: 0.1);
      color: #fd6125;
      //margin: 0px;
      //border: 1px solid red;
      &:hover {
        cursor: pointer;
        color: white;
        background-color: rgba($color: #fd6125, $alpha: 1);
      }
    }
  }

  &__Options {
    //border: 1px solid pink;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: calc(10px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));
  }
}