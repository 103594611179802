.HelpManualContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.HelpManual {
  //border: 1px solid black;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-top-right-radius: calc(16px * var(--heightmultiplier));

  &__title {
    width: 100%;
    height: fit-content;
    //width: 70%;
    box-sizing: border-box;
    font-family: "General Sans";
    font-style: normal;
    font-weight: 500;
    font-size: calc(40px * var(--heightmultiplier));
    color: var(--primaryTextColor);
    background-color: var(--secondaryColor);
    padding: calc(0px * var(--heightmultiplier)) calc(20px * var(--widthmultiplier));
  }

  
  &__doc-container{
    width: 100%;
    height: 95%;

    // border: 1px solid red;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__links-container{
      width: 30%;
      height: 100%;
     // border: 1px solid blue;
     background-color: var(--primaryColor);
     padding: calc(10px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
     display: flex;
     flex-direction: column;
     align-items: flex-start;
     justify-content: flex-start;
     overflow: auto;

     &__borderdiv{
      align-self: center;
      width:95%;
      margin-top: calc(3px * var(--heightmultiplier));
      border: 1.5px solid var(--secondaryTextColor);
     }


      &__link{
        width: 100%;
        height: fit-content;
        //border: 1px solid violet;
        border: none;
        outline: none;
        text-align: left;
        border-radius: calc(10px * var(--heightmultiplier));
        background-color: var(--primaryColor);
        padding-left: calc(5px * var(--widthmultiplier));
        // margin: calc(10px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));
        padding-top: calc(10px * var(--heightmultiplier));
        font-size: calc(18px * var(--heightmultiplier));
        font-weight: 500;
        &:hover{
          text-decoration: underline;
          cursor: pointer;
        }
      }
      &__focusedLink{
        width: 100%;
        height: fit-content;
        //border: 1px solid violet;
        border: none;
        outline: none;
        text-align: left;
        border-radius: calc(10px * var(--heightmultiplier));
        background-color: rgba($color: #4285F4, $alpha: 0.7);
        
        // margin: calc(10px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));
        padding: calc(2px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
        font-size: calc(18px * var(--heightmultiplier));
        font-weight: 600;
      }
    }
    &__rightPanel{
      width: 80%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }

    &__pdf-container{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      height: fit-content;
      overflow: auto;
      width: 100%;
      height: 99%;
      // &__dummy{
      //   width: 100%;
      //   height: 10000px;
      //   background-color: #6fe8de;
      // }
    }
  }

}