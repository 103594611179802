.headerBar-container {
    width: 100%;
    height: 100%;
    // border: 1px solid red;
    // border-radius: calc(16px* var(--heightmultiplier));
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: var(--secondaryColor);
    box-sizing: border-box;
    // margin-bottom: calc(45px * var(--heightmultiplier));

    &__navsection {
        width: 80%;
        height: 100%;
        // border: 1px solid green;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        &__logoContainer {
            width: fit-content;
            height: 100%;
            // border: 1px solid blue;
            // margin-left: calc(10px * var(--widthmultiplier));
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            // display: none;
            &__phc-logo {
                width: calc(100px * var(--widthmultiplier));
                height: calc(80px * var(--widthmultiplier));

            }

            &__selectedTab {
                width: fit-content;
                height: 100%;
                // border: 1px solid red;
                font-size: calc(42px * var(--heightmultiplier));
                padding-left: calc(10px * var(--widthmultiplier));
                font-weight: 600;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
            }

            &__backButton {
                width: fit-content;
                height: 100%;
                // border: 1px solid #FD6125;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                margin-left: calc(10px * var(--widthmultiplier));
              //  margin-bottom: calc(15px * var(--widthmultiplier));

                &__container {
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                    // margin-right: calc(30px * var(--widthmultiplier));
                    // border: 1px solid red;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    border-radius: calc(16px * var(--heightmultiplier));

                    // &:hover {
                    //     color: #FD6125;
                    //     stroke: #FD6125;
                    //     cursor: pointer;
                    // }

                    &__svgImag {
                        width: 80%;
                        height: 60%;
                        // border: 1px solid green;
                        // transform: rotate(180deg);
                        //   stroke:#878B8F ;
                        // fill: #878B8F
                        //   &:hover{
                        //       stroke: #FD6125;
                        //   }
                    }

                    &__textBack {
                        font-size: calc(16px * var(--heightmultiplier));
                        font-weight: 400;
                    }
                }
            }
        }
    }

    &__userSection {
        width: 24%;
        height: 100%;
        // border: 1px solid brown;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        position: relative;

        &__bagIconContainer {
            width: 40%;
            height: 100%;
            // border: 1px solid red;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
          

            &__user {
                // height: 100%;
                width: calc(100px * var(--widthmultiplier));
                height: fit-content;
                // position: absolute;
                // top: calc(45px * var(--heightmultiplier));
                // right: calc(0px * var(--widthmultiplier));
                // margin-right: 0px;
                padding: calc(10px * var(--heightmultiplier));
                border-radius: calc(16px * var(--heightmultiplier));
                display: flex;
                align-items: center;
                justify-self: space-between;
                border: 1px solid var(--secondaryTextColor);
                box-sizing: border-box;

                // border: 1px solid red;
                &:hover {
                    cursor: pointer;
                    background-color: rgba($color: #FD6125, $alpha: 1);
                    color: white;
                }


                &__bagStr {
                    width: 60%;
                    // border: 1px solid red;
                    margin-left: 8%;
                    box-sizing: border-box;
                    font-family: 'General Sans';
                    font-style: normal;
                    font-weight: 500;
                    font-size: calc(16px * var(--heightmultiplier));
                    // line-height: calc(20px * var(--heightmultiplier));

                    // color: var(--primaryTextColor);
                }

                &__bagImg {
                    width: 40%;
                    //border: 1px solid black;
                    //height: calc(20px * var(--heightmultiplier));
                    position: relative;

                    &__productCount {
                        box-sizing: content-box;
                        position: absolute;
                        right: calc(-5px * var(--heightmultiplier));
                        top: calc(-10px * var(--heightmultiplier));
                        font-size: calc(14px * var(--heightmultiplier));
                        font-weight: 600;
                        z-index: 10;
                        background-color: #FD6125;
                        color: var(--primaryColor);
                        display: flex;
                        align-items: center;
                        text-align: center;
                        justify-content: center;
                        width: calc(20px * var(--heightmultiplier));
                        height: calc(20px * var(--heightmultiplier));
                        border-radius: 50%;
                        border: calc(2px * var(--heightmultiplier)) solid var(--primaryColor);
                    }

                    &__productCountWhite {
                        box-sizing: content-box;
                        position: absolute;
                        right: calc(-5px * var(--heightmultiplier));
                        top: calc(-10px * var(--heightmultiplier));
                        font-size: calc(14px * var(--heightmultiplier));
                        font-weight: 600;
                        z-index: 10;
                        background-color: white;
                        color: #FD6125;
                        display: flex;
                        align-items: center;
                        text-align: center;
                        justify-content: center;
                        width: calc(20px * var(--heightmultiplier));
                        height: calc(20px * var(--heightmultiplier));
                        border-radius: 50%;
                        border: calc(2px * var(--heightmultiplier)) solid #FD6125;
                    }

                    &__img {
                        box-sizing: border-box;
                        height: calc(24px * var(--heightmultiplier));
                        width: calc(24px * var(--widthmultiplier));

                        // padding-left: calc(6px * var(--widthmultiplier));
                        &:hover {
                            cursor: pointer;
                        }
                    }
                }


                &__name {
                    box-sizing: border-box;
                    font-size: 1.5em;
                }
            }

            &__userIcon {
                width: 60%;
                height: 100%;
                // border: 1px solid blueviolet;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-evenly;
                position: absolute;
                right: 0;

                &__img {
                    box-sizing: border-box;
                    height: calc(6px * var(--heightmultiplier));
                    width: calc(12px * var(--widthmultiplier));
                    padding-left: calc(6px * var(--widthmultiplier));
                    // border: 1px solid black;

                    &:hover {
                        cursor: pointer;
                    }
                }
                &__userName{
                    box-sizing: border-box;
                    height: 100%;
                    width:fit-content;
                    // padding-left: calc(6px * var(--widthmultiplier));
                    // border: 1px solid red;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    font-size: calc(18px * var(--heightmultiplier));
                }

                &__proImg {
                    box-sizing: border-box;
                    height: 3em;
                    width: 3em;
                    // min-width: 50px;
                    margin-left: 2em;
                    box-sizing: border-box;
                    border-radius: 50%;
                    background-color: #FD6125;
                    color: var(--primaryTextColor);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 500;
                    // border: 1px solid black;

                    &:hover {
                        // background-color: rgba(253,97,37,0.5);
                        cursor: pointer;
                    }
                }
            }
        }
    }
}