@import "../variables";

.BulkUpload-container {
    position: relative;
    width: fit-content;
    height: fit-content;
    box-sizing: border-box;
    background-color: var(--primaryColor);
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: calc(16px * var(--heightmultiplier)) ;
    border: 1px solid var(--primaryTextColor);
    padding: calc(5px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));
    &__title{
      width: fit-content;
      height: fit-content;
      font-size: calc(18px * var(--heightmultiplier));
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      // border: 1px solid green;
    }
    &__buttons{
      width: fit-content;
      height: fit-content;
      font-size: calc(18px * var(--heightmultiplier));
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: calc(0px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
      gap: calc(10px * var(--widthmultiplier));
      // border: 1px solid green;
    }
  }
  