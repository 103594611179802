.leftpanel-container{
    height: 100%;
    width: 100%;
    position: static;
    display: flex;
    flex-direction: column;
    
    // justify-content: center;
    // border-radius: calc(16px * var(--heightmultiplier));
    align-items: center;
    // border: 1px solid black;
    &__logodiv{
        width: 100%;
        height: 10%;
        position: relative;
        box-sizing: border-box;
        // border: 1px solid green;
        // padding: calc(10px * var(--heightmultiplier));
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        &__logo{
            width: 68%;
            height: calc(50px * var(--heightmultiplier));
            display: flex;
            align-items: center;
            justify-content: center;
            margin: calc(10px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));
            // border: 1px solid pink;
        }
    }
    &__logodiv-border {
        margin-top: calc(20px * var(--heightmultiplier));
        height: calc(2px * var(--heightmultiplier));
        width: 100%;
        background-color: var(--secondaryTextColor);
    // border: 1px solid black;

    }

    &__tabsContainer{
        width: 100%;
        height: 90%;
        margin-top: calc(20px * var(--heightmultiplier));
        display: flex;
        flex-direction: column;
        align-items: center;
        // border: 1px solid green;
        padding: calc(8px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));
    }
    &__backButton{
      width: 100%;
      height: 5%;
    //   border: 1px solid #FD6125;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      &__container{
        width: 25%;
        height: 70%;
        margin-right: calc(30px * var(--widthmultiplier));
        // border: 1px solid red;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        border-radius: calc(16px * var(--heightmultiplier));
        &:hover{
            color: #FD6125;
            stroke: #FD6125;
            cursor: pointer;
          }
        &__svgimag{
            transform: rotate(180deg);
            stroke:#878B8F ;
            // fill: #878B8F
            &:hover{
                stroke: #FD6125;
            }
        }
        &__textBack{
            font-size: calc(16px * var(--heightmultiplier));
            font-weight: 400;
        }
      }
    }
}
.leftPanel-ImageIcon{
    width: calc(22px * var(--heightmultiplier));
    height: calc(22px * var(--heightmultiplier));
    // border: 1px solid black;
}