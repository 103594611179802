@import "../variables";
@import "rsuite/dist/rsuite.css";

@import "../variables";


.OrderModel-container {
    background: var(--primaryColor);
    padding: calc(30px * var(--heightmultiplier));
    width: 100%;
    // border: 1px solid black;
    // border-radius: 2rem;
    display: flex;
    flex-direction: column;
    gap: calc(4px * var(--heightmultiplier));
    align-items: center;
    position: relative;
    height: 100%;
    justify-content: space-between;


    &__heading {
        font-weight: 500;
        // margin-bottom: 1rem;
        font-size: calc(40px * var(--heightmultiplier));
    }

    &__totalItems {
        box-sizing: border-box;
        font-family: 'General Sans';
        font-style: normal;
        // font-weight: 400;
        // font-size: calc(50px * var(--heightmultiplier));
        //line-height: calc(27px * var(--heightmultiplier));
        color: var(--primaryTextColor);
        margin-left: calc(3px * var(--heightmultiplier));
        display: flex;
        align-items: center;
        justify-content: space-between;
        // width: 50%;
        // height: fit-content;
        //border: 1px solid black;

        &__locationCont {
            //   display: flex;
            //   flex-direction: column;
            //   align-items: center;
            //   justify-content: space-evenly;
            //   margin: calc(10px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
            //border: 1px solid black;
            width: calc(700px * var(--heightmultiplier));
            display: flex;
            flex-direction: row;
            // border: 1px solid red;
            grid-template-columns: 1fr 1fr;
            // margin-top: 1.4rem;
        }

        &__locationCont2 {
            display: flex;
            width: calc(700px * var(--heightmultiplier));
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            position: relative;
            width: calc(700px * var(--heightmultiplier));
            // margin: calc(10px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
            //border: 1px solid black;
            &__delivery-type-group {
                color: var(--primaryTextColor);
                font-size: calc(18px * var(--heightmultiplier));
                display: flex;
                // flex-direction: column;
                align-items: center;
                gap: 1rem;
                width: 100%;
            }

            &__delivery-type-label {
                color: #FD6125;
                font-size: calc(20px * var(--heightmultiplier));
                font-family: "General Sans", sans-serif;
                width: 22%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &__delivery-type-radio-group {
                width: 42%;
                height: fit-content;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-evenly;
                gap: calc(3px * var(--heightmultiplier));
                // align-items: center;
                // flex-direction: row;
            }

            &__delivery-type-radio {
                color: var(--primaryTextColor);
                font-size: calc(18px * var(--heightmultiplier));
                
            }
            &__datePicker {
                width: 50%;
                height: fit-content;
                color: var(--primaryTextColor);
                font-size: calc(18px * var(--heightmultiplier));
                // background-color: var(--primaryColor);
                // width: 1px;
                // height: 1px;
                // visibility: hidden !important;
            }

            &__error {
                position: absolute;
                color: red;
                font-size: calc(16px * var(--heightmultiplier));
                // border: 1px solid red;
                top: 80%;
                width: 100%;
                text-align: start;
            }
        }

        &__locationCont1 {
            display: flex;
            flex-direction: column;
            // align-items: center;
            gap: calc(12px * var(--heightmultiplier));
            justify-content: space-evenly;
            width: calc(700px * var(--heightmultiplier));
            border-radius: calc(10px * var(--heightmultiplier));
            padding: calc(5px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));
            color: var(--primaryTextColor);
            border: 2px solid var(--primaryTextColor);
            padding-bottom: calc(10px * var(--heightmultiplier));


            &__label {
                width: fit-content;
                margin-left: calc(10px * var(--heightmultiplier));
                padding: calc(0px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
                // height: 100%;
                //border: 1px solid blue;
                font-size: calc(20px * var(--heightmultiplier));
                font-weight: 500;
                display: flex;
                text-align: flex-start;
                align-items: center;
                margin-top: calc(-18px * var(--heightmultiplier));
                background-color: var(--primaryColor);
                color: var(--primaryTextColor)
            }
        }

    }


    &__inputFiled {
        width: calc(700px * var(--heightmultiplier));
        height: fit-content;
        border: 2px solid var(--primaryTextColor);
        border-radius: calc(10px * var(--heightmultiplier));
        margin: calc(12px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
        // overflow-y: hidden;
        // position: relative;

        &__label {
            width: fit-content;
            margin-left: calc(10px * var(--heightmultiplier));
            padding: calc(0px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
            height: fit-content;
            //border: 1px solid blue;
            font-size: calc(20px * var(--heightmultiplier));
            font-weight: 500;
            display: flex;
            text-align: left;
            align-items: center;
            margin-top: calc(-12px * var(--heightmultiplier));
            background-color: var(--primaryColor);
            color: var(--primaryTextColor) // position: absolute;
                // left: 0px;
                // top: 0px;

        }

        &__error {
            position: absolute;
            color: red;
            font-size: calc(14px * var(--heightmultiplier));

            //   top: 100%;
            //   border: 1px solid red;
        }

        &__value {
            width: 100%;
            height: fit-content;
            //border: 1px solid blue;
            font-size: calc(18px * var(--heightmultiplier));
            display: flex;
            text-align: flex-start;
            align-items: center;
            background-color: transparent;
            border-radius: calc(10px * var(--heightmultiplier));
            padding: calc(5px * var(--heightmultiplier));


            &__input {
                width: 100%;
                height: 100%;
                outline: none;
                border: none;
                color: var(--primaryTextColor);
                background-color: transparent;
            }
            &__dropDown{
                width: 100%;
                height: 100%;
                outline: none;
                border: none;
                color: var(--primaryTextColor);
                background-color: var(--primaryColor);
            }
        }
    }

    &__inputFiled2 {
        width: 20%;
        height: fit-content;
        border: 2px solid var(--primaryTextColor);
        border-radius: calc(10px * var(--heightmultiplier));
        margin: calc(12px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
        // overflow-y: hidden;
        // position: relative;

        &__label {
            width: fit-content;
            margin-left: calc(10px * var(--heightmultiplier));
            padding: calc(0px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
            height: fit-content;
            //border: 1px solid blue;
            font-size: calc(16px * var(--heightmultiplier));
            //font-weight: 600;
            display: flex;
            text-align: flex-start;
            align-items: center;
            margin-top: calc(-12px * var(--heightmultiplier));
            background-color: var(--secondaryColor);
            color: var(--primaryTextColor) // position: absolute;
                // left: 0px;
                // top: 0px;

        }

        &__value {
            width: 100%;
            height: fit-content;
            //border: 1px solid blue;
            font-size: calc(16px * var(--heightmultiplier));
            display: flex;
            text-align: flex-start;
            align-items: center;
            background-color: var(--secondaryColor);
            border-radius: calc(10px * var(--heightmultiplier));
            padding: calc(5px * var(--heightmultiplier));


            &__input {
                width: 100%;
                height: 100%;
                outline: none;
                border: none;
                color: var(--primaryTextColor);
                background-color: var(--secondaryColor);
            }
        }
    }

    &__customerInput {
        width: 100%;
        // height: 100%;
        //border: 2px solid var(--primaryTextColor);
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: space-evenly;
        // padding: calc(0px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
        // overflow-y: hidden;
        position: relative;

        &__label {
            width: 20%;
            // margin-left: calc(10px * var(--heightmultiplier));
            padding: calc(0px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
            height: fit-content;
            // display: flex;
            // text-align: center;
            // align-items: center;
            // justify-content: space-evenly;
            //border: 1px solid blue;
            font-size: calc(18px * var(--heightmultiplier));
            font-weight: 500;

        }

        &__error {
            position: absolute;
            color: red;
            font-size: calc(14px * var(--heightmultiplier));
            ;
            top: 75%;
            width: 100%;
            //   border: 1px solid red;
        }

        &__value {
            width: 80%;
            height: fit-content;
            //border: 1px solid blue;
            font-size: calc(18px * var(--heightmultiplier));
            display: flex;
            text-align: flex-start;
            align-items: center;
            background-color: transparent;
            border-radius: calc(10px * var(--heightmultiplier));
            padding: calc(5px * var(--heightmultiplier));

            &__input {
                width: 100%;
                height: 100%;
                outline: none;
                border-radius: calc(5px * var(--heightmultiplier));
                padding: calc(0px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));
                //border: none;
                border: 1px solid var(--primaryTextColor);
                color: var(--primaryTextColor);
                background-color: transparent;
                ;
            }

            &__input1 {
                width: 100%;
                height: 100%;
                //outline: none;
                border-radius: calc(5px * var(--heightmultiplier));
                padding: calc(0px * var(--heightmultiplier)) calc(px * var(--widthmultiplier));
                //border: none;
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
                border-right: none;
                border: 1px solid var(--primaryTextColor);
                color: var(--primaryTextColor);
                background-color: transparent;
            }

            &__input2 {
                width: 100%;
                height: 100%;
                outline: none;
                border-radius: calc(5px * var(--heightmultiplier));
                padding: calc(0px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
                //border: none;
                border-left: none;
                border: 1px solid var(--primaryTextColor);
                color: var(--primaryTextColor);
                background-color: transparent;
            }
        }
    }
    &__item1{
        display: flex;
        width: calc(700px * var(--heightmultiplier));
        align-items: center;
        justify-content: space-between;
        gap: calc(3px * var(--heightmultiplier));

        &__inputFiled {
            flex: 1;
            height: fit-content;
            border: 2px solid var(--primaryTextColor);
            border-radius: calc(10px * var(--heightmultiplier));
            margin: calc(12px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
            // overflow-y: hidden;
            // position: relative;
    
            &__label {
                width: fit-content;
                margin-left: calc(10px * var(--heightmultiplier));
                padding: calc(0px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
                height: fit-content;
                //border: 1px solid blue;
                font-size: calc(20px * var(--heightmultiplier));
                font-weight: 500;
                display: flex;
                text-align: left;
                align-items: center;
                margin-top: calc(-12px * var(--heightmultiplier));
                background-color: var(--primaryColor);
                color: var(--primaryTextColor) // position: absolute;
                    // left: 0px;
                    // top: 0px;
    
            }
    
            &__error {
                position: absolute;
                color: red;
                font-size: calc(14px * var(--heightmultiplier));
    
                //   top: 100%;
                //   border: 1px solid red;
            }
    
            &__value {
                width: 100%;
                height: fit-content;
                //border: 1px solid blue;
                font-size: calc(18px * var(--heightmultiplier));
                display: flex;
                text-align: flex-start;
                align-items: center;
                background-color: transparent;
                border-radius: calc(10px * var(--heightmultiplier));
                padding: calc(5px * var(--heightmultiplier));
    
    
                &__input {
                    width: 100%;
                    height: 100%;
                    outline: none;
                    border: none;
                    color: var(--primaryTextColor);
                    background-color: transparent;
                }
            }
        }
    }

    &__Item4 {
        // display: flex;
        width: calc(700px * var(--heightmultiplier)) !important;
        display: grid;
        flex-direction: row;
        // border: 1px solid red;
        grid-template-columns: 2fr 2fr;
        // justify-content: space-between;

        &__inputFiled {
            // width: calc(700px * var(--heightmultiplier));
            height: fit-content;
            border: 2px solid var(--primaryTextColor);
            border-radius: calc(10px * var(--heightmultiplier));
            margin: calc(12px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
            // overflow-y: hidden;
            // position: relative;
    
            &__label {
                width: fit-content;
                margin-left: calc(10px * var(--heightmultiplier));
                padding: calc(0px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
                height: fit-content;
                //border: 1px solid blue;
                font-size: calc(20px * var(--heightmultiplier));
                font-weight: 500;
                display: flex;
                text-align: left;
                align-items: center;
                margin-top: calc(-12px * var(--heightmultiplier));
                background-color: var(--primaryColor);
                color: var(--primaryTextColor) // position: absolute;
                    // left: 0px;
                    // top: 0px;
    
            }
    
            &__error {
                position: absolute;
                color: red;
                font-size: calc(14px * var(--heightmultiplier));
    
                //   top: 100%;
                //   border: 1px solid red;
            }
    
            &__value {
                width: 100%;
                height: fit-content;
                //border: 1px solid blue;
                font-size: calc(18px * var(--heightmultiplier));
                display: flex;
                text-align: flex-start;
                align-items: center;
                background-color: transparent;
                border-radius: calc(10px * var(--heightmultiplier));
                padding: calc(5px * var(--heightmultiplier));
    
    
                &__input {
                    width: 100%;
                    height: 100%;
                    outline: none;
                    border: none;
                    color: var(--primaryTextColor);
                    background-color: transparent;
                }
            }
        }
    }


    &__ListDownItems-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        margin-top: 1rem;


        &__place-order {
            height: calc(50px * var(--heightmultiplier));
            width: calc(210px * var(--widthmultiplier));
            // background-color: var(--primaryColor);
            border-radius: calc(10px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba($color: #FD6125, $alpha: 0.2);
            border: none;

            font-family: 'General Sans';
            font-style: normal;
            font-weight: 500;
            font-size: calc(22px * var(--heightmultiplier));
            // margin-bottom: calc(28px * var(--heightmultiplier));
            color: #FD6125;

            &:hover {
                cursor: pointer;
                color: var(--primaryColor);
                background-color: rgba($color: #FD6125, $alpha: 1);
            }
        }

    }

    &__error {
        font-size: 15px;
        color: red;
        font-weight: 500;
        margin-top: 0.4rem;
    }

    &__close {
        height: calc(35px * var(--heightmultiplier));
        width: calc(35px * var(--widthmultiplier));
        // background-color: var(--primaryColor);
        border-radius: calc(10px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
        display: flex;
        align-items: center;
        justify-content: center;
        // text-align: center;
        background-color: rgba($color: red, $alpha: 0.2);
        border: none;
        position: absolute;
        top: calc(20px * var(--heightmultiplier));
        right: calc(20px * var(--heightmultiplier));
        ;
        font-family: 'General Sans';
        font-style: normal;
        font-weight: 500;
        font-size: calc(40px * var(--heightmultiplier));
        // margin-bottom: calc(28px * var(--heightmultiplier));
        color: red;

        &:hover {
            cursor: pointer;
            color: var(--primaryColor);
            background-color: rgba($color: red, $alpha: 1);
        }
    }

}

.rs-input {
    height: calc(10px var(--heightmultiplier)) !important;
    font-size: calc(16px * var(--widthmultiplier)) !important;
    padding: calc(5px * var(--heightmultiplier)) calc(5px * var(--heightmultiplier)) !important;
    background-color: transparent !important;
    overflow: hidden !important;
    color: var(--primaryTextColor) !important
  }
  
  .rs-input-group-addon {
    padding: calc(10px * var(--heightmultiplier)) calc(10px * var(--heightmultiplier)) !important;
    background-color: transparent !important;
    color: var(--primaryTextColor) !important
  }
  
  .rs-input-group {
    color: var(--primaryTextColor) !important;
    background-color: transparent !important;
    border: 2px solid var(--primaryTextColor) !important;
  }