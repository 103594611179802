.OrderStatusIndicator-container {
    position: relative;
    width: 100%;
    //height: 10px;
    height:  calc(120px * var(--heightmultiplier));
    padding: 0px;
    margin: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: calc(10px * var(--heightmultiplier));
     //border: 1px solid black;

    &__timeline {
        box-sizing: border-box;
        position: absolute;
        top: calc(5px * var(--heightmultiplier));
        left: calc(6px * var(--widthmultiplier));
        height: calc(8px * var(--heightmultiplier));
        width: 99%;
        // margin: calc(5px * var(--heightmultiplier)) calc(18px * var(--widthmultiplier));
        background-color: #FDD5C5;
        z-index: 0;

        &__bar{
            background-color: #FD6125;
            height: 100%;
            width: 0%;
            transition: all 2.1s linear;
        }
    }

    &__status-tags {
        position: absolute;
        width: 100%;
        // width: calc(px * var(--widthmultiplier));
        height: 100%;
        z-index: 2;
        padding: 0px;
        margin: 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__placed {
            height: 100%;
            width: calc(120px * var(--widthmultiplier));
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            // border: 1px solid black;
            font-family: 'General Sans';
            font-style: normal;
            font-weight: 400;
            font-size: calc(20px * var(--heightmultiplier));
            line-height: calc(22px * var(--heightmultiplier));

            &__circle {
                width: calc(18px * var(--widthmultiplier)) !important;
                height: calc(18px * var(--heightmultiplier)) !important;
                border-radius: 50%;
                // background-color: rgba($color: #FD6125, $alpha: 0.5);
                background-color: #FDD5C5;
                z-index: 10;
            }

            &__tag {
                box-sizing: border-box;
                width: calc(120px * var(--widthmultiplier));
                display: flex;
                align-items: center;
                justify-content: flex-start;
                text-align: center;
                // border: 1px solid black;
                margin-top: calc(15px * var(--heightmultiplier));
            }
        }

        &__statusMap {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            font-family: 'General Sans';
            font-style: normal;
            font-weight: 400;
            font-size: calc(20px * var(--heightmultiplier));
            line-height: calc(27px * var(--heightmultiplier));

            color: var(--primaryTextColor);
            width: 78.85%;
            height: 100%;
            // border: 1px solid black;

            &__name {
                box-sizing: border-box;
                width: calc(120px * var(--widthmultiplier));
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                text-align: center;
                // border: 1px solid black;
                height: 100%;

            }

            &__circle {
                min-width: calc(18px * var(--widthmultiplier));
                height: calc(18px * var(--heightmultiplier));
                border-radius: 50%;
                // background-color: rgba($color: #FD6125, $alpha: 0.5);
                background-color: #FDD5C5;
            }

            &__tag{
                margin-top: calc(15px * var(--heightmultiplier));
            }
        }


        &__delivered {
            height: 100%;
            width: calc(120px * var(--widthmultiplier));
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: flex-start;
            // border: 1px solid black;
            font-family: 'General Sans';
            font-style: normal;
            font-weight: 400;
            font-size: calc(20px * var(--heightmultiplier));
            line-height: calc(27px * var(--heightmultiplier));

            &__circle {
                width: calc(18px * var(--widthmultiplier));
                height: calc(18px * var(--heightmultiplier));
                border-radius: 50%;
                // background-color: rgba($color: #FD6125, $alpha: 0.5);
                background-color: #FDD5C5;
            }

            &__tag {
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                text-align: center;
                // border: 1px solid black;
                margin-top: calc(15px * var(--heightmultiplier));
            }
        }
    }

}