.updateUserProfile-modalBackground {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(6, 6, 6, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  /* border: 1px solid black; */
}



.updateUserProfile-modalContainer {
  width: 30%;
  height: 70%;

  border-radius: 0.75em;

  background-color: var(--primaryColor);
  box-shadow: #FD6025 0px 0px 15px;
  padding: calc(15px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));

  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: space-evenly;
  /* border: 5px solid #FC6025; */
  /* padding-top: calc(50px);
      padding-bottom: calc(50px); */
  &__titleContainer {
    position: relative;
    width: 100%;
    height: 8%;
    // border: 1px solid red;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-evenly;
    font-size: calc(26px* var(--heightmultiplier));
    font-weight: 550;

    &__cancelBtn {
      position: absolute;
      right: 0;
      top: 0;
      width: 6%;
      height: 10%;
      height: fit-content;
      font-family: 'Titillium Web';
      font-style: normal;
      font-weight: 400;
      font-size: calc(20px* var(--heightmultiplier));
      color: #FC6025;
      border-radius: 50%;
      margin-right: 0.5rem;
      z-index: 12;
      display: flex;
      //padding: 0px 10px;
      text-align: center;
      align-items: center;
      justify-content: center;

      &:hover {
        cursor: pointer;
        background-color: white;
        background-color: rgba($color: #FD6125, $alpha: 0.2);
      }
    }
  }

}

.updateUserProfile-custom-alert-box-msg {
  width: 96%;
  height: 80%;
  font-family: 'Titillium Web';
  font-style: normal;
  text-align: center;
  color: var(--primaryTextColor);
  opacity: 0.8;
  margin: calc(10px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: space-around;

  &__inputFiled {
    width: 98%;
    height: fit-content;
    border: 2px solid var(--primaryTextColor);
    border-radius: calc(10px * var(--heightmultiplier));
    margin: calc(12px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
    // overflow-y: hidden;
    // position: relative;

    &__label {
      width: fit-content;
      margin-left: calc(10px * var(--heightmultiplier));
      padding: calc(0px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
      height: fit-content;
      //border: 1px solid blue;
      font-size: calc(16px * var(--heightmultiplier));
      //font-weight: 600;
      display: flex;
      text-align: flex-start;
      align-items: center;
      margin-top: calc(-12px * var(--heightmultiplier));
      background-color: var(--primaryColor);
      color: var(--primaryTextColor) // position: absolute;
        // left: 0px;
        // top: 0px;

    }

    &__value {
      width: 90%;
      height: fit-content;
      //border: 1px solid blue;
      font-size: calc(16px * var(--heightmultiplier));
      display: flex;
      text-align: flex-start;
      align-items: center;
      background-color: var(--primaryColor);
      border-radius: calc(10px * var(--heightmultiplier));
      padding: calc(5px * var(--heightmultiplier));


      &__input {
        width: 100%;
        height: 100%;
        outline: none;
        border: none;
        color: var(--primaryTextColor);
        background-color: var(--primaryColor);
      }
      &__date {
        width: 10%;
        height: 100%;
        outline: none;
        border: none;
        color: var(--primaryTextColor);
        background-color: var(--primaryColor);
        display: flex;
        text-align: center;
        align-items: center;
      }
      &__month{
        width: 12%;
        height: 100%;
        outline: none;
        border: none;
        color: var(--primaryTextColor);
        background-color: var(--primaryColor);
        display: flex;
        text-align: center;
        align-items: center;
      }
      &__year {
        width: 15%;
        height: 100%;
        outline: none;
        border: none;
        color: var(--primaryTextColor);
        background-color: var(--primaryColor);
        display: flex;
        text-align: center;
        align-items: center;
      }
    }
  }

  &__inputRadio {
    width: 98%;
    height: 15%;
    // border: 2px solid var(--primaryTextColor);
    border-radius: calc(10px * var(--heightmultiplier));
    margin: calc(10px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));
    color: var(--primaryTextColor);
    display: flex;
    flex-direction: column;
    // text-align: flex-start;
    align-items: center;
    justify-content: space-evenly;

    &__label {
      width: 100%;
      //margin-left: calc(10px * var(--heightmultiplier));
      padding: calc(0px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
      height: 40%;
      // border: 1px solid blue;
      font-size: calc(16px * var(--heightmultiplier));
      //font-weight: 600;
      display: flex;
      text-align: flex-start;
      align-items: center;
      //margin-top: calc(-12px * var(--heightmultiplier));
      color: var(--primaryTextColor)
    }
    &__value {
      width: 100%;
      height: 40%;
      // border: 1px solid red;
      font-size: calc(16px * var(--heightmultiplier));
      font-weight: 500;
      // padding: calc(0px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
      display: flex;
      flex-direction: row;
      text-align: center;
      align-items: center;
      justify-content: space-evenly;
      //background-color: var(--primaryColor);

      &__input {
        width: fit-content;
        height: 100%;
        // outline: none;
        // border: none;
        // border: 1px solid blue;
        font-weight: 500;
        color: var(--primaryTextColor);
        display: flex;
        flex-direction: row;
        text-align: center;
        align-items: center;
        justify-content: center;
        //background-color: var(--primaryColor);
      }
    }
  }

}

.updateUserProfile-custom-alert-box-button-container {
  display: flex;
  height: fit-content;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;

  &__ChangePasswordBtn {
    box-sizing: border-box;
    width: fit-content;
    padding: calc(5px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 500;
    font-size: calc(24px * var(--heightmultiplier));
    background-color: rgba($color: #FD6125, $alpha: 0.2);
    border-radius: 5px;
    color: var(--primaryTextColor);

    &:hover {
      cursor: pointer;
      background-color: rgba($color: #FD6125, $alpha: 1);
      color: white;
      box-shadow: var(--primaryTextColor) 0px 0px 5px;
      ;
    }
  }
}

.custom-alert-box-name {
  height: fit-content;
  width: fit-content;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  font-size: 2.5em;

  color: #1F2633;

}

