.CustomDropDown-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  // border-bottom: 2px solid var(--secondaryTextColor);
  // gap: calc(6px* var(--heightmultiplier));
  justify-content: center;
  position: relative;
  overflow: hidden;

  // border: 1px solid red;
  &__categoryList {
    width: 90%;
    height: 100%;
    // border: 1px solid yellow;
    border-bottom: 2px solid var(--secondaryTextColor);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: calc(20px * var(--heightmultiplier));

    &__visibleList {
      width: fit-content;
      height: 100%;
      // border-bottom: 2px solid var(--secondaryTextColor);
      padding: calc(0px* var(--heightmultiplier)) calc(8px * var(--widthmultiplier));
      font-size: calc(16px * var(--heightmultiplier));
      border-top-left-radius: calc(10px * var(--heightmultiplier));
      border-top-right-radius: calc(10px * var(--heightmultiplier));
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      &:hover {
        cursor: pointer;
        background-color: var(--primaryColor);
      }
    }
    &__visibleListActive{
      width: fit-content;
      height: 100%;
      // border-bottom: 2px solid var(--secondaryTextColor);
      padding: calc(0px* var(--heightmultiplier)) calc(8px * var(--widthmultiplier));
      border-top-left-radius: calc(10px * var(--heightmultiplier));
      border-top-right-radius: calc(10px * var(--heightmultiplier));
      font-size: calc(16px * var(--heightmultiplier));
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      background-color: var(--primaryColor);
    }

    &__more {
      position: relative;
      // width: fit-content;
      height: 100%;
      // border: 1px solid green;
      border: none;
      outline: none;
      border-top-left-radius: calc(10px * var(--heightmultiplier));
      border-top-right-radius: calc(10px * var(--heightmultiplier));
      display: flex;
      background-color: var(--secondaryColor);
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      text-overflow: ellipsis;
      // padding: calc(0px* var(--heightmultiplier)) calc(15px * var(--widthmultiplier));
      font-size: calc(16px * var(--heightmultiplier));
      &:hover{
        cursor: pointer;
        background-color: var(--primaryColor);
      }

      &__dropdown-container{
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: fit-content;
        // border: 1px solid black;
        // background-color: var(--primaryColor);
        border-radius: calc(5px * var(--heightmultiplier));
        z-index: 1;
      }
    }
  }


  // .category-dropdown {
  //   border: 1px solid #ccc;
  //   border-radius: calc(10px * var(--heightmultiplier));
  //   padding:calc(10px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));
  //   font-size: calc(16px * var(--heightmultiplier));
  //   min-width: 15%;
  //   outline: none;
  // //   margin-bottom: calc(-56px * var(--heightmultiplier));
  //   z-index: 1;
  // //   margin-right: calc(-50px * var(--heightmultiplier));
  //   font-family: 'General Sans';
  //   font-style: normal;
  //   font-weight: 400;
  //   background-color: var(--primaryColor);

  //   &:focus {
  //     border-color: #89c2ff;
  //     box-shadow: 0 0 0px rgba(0, 123, 255, 0.5);
  //   }
  // }
}