.dashboardcard-container{
    width: calc(696.5px* var(--widthmultiplier));
    height:calc(320px* var(--heightmultiplier));
    position: relative;
    // border: 1px solid red;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    overflow: hidden;
    border-radius: calc(16px * var(--heightmultiplier));
    background-color:var(--cardColor);

    // &:nth-child(1){
    //     grid-column: 1;
    // }
    // &:nth-child(2){
    //     grid-column: 2;
    // }
    // &:nth-child(3){
    //     grid-column: 1/3;
    //     justify-self: center;
    // }
    &:hover{
        cursor: pointer;
        box-shadow: 0px 0px 10px #888888;
        scale:1.1;
    }
    &__title{
        width: 90%;
        height: 90%;
        // border: 1px solid purple;
        font-size: calc(28px * var(--heightmultiplier));
        font-weight: 500;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        // background-color: ;
    }
    &__arrow{
        width: fit-content;
        height: fit-content;
        position: absolute;
        right : calc(20px * var(--widthmultiplier));
        bottom: calc(20px * var(--heightmultiplier));
        // border: 1px solid purple;
       
    }
}