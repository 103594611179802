@import "../variables";



.OrderTable-container {
    // border: 1px solid black;
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    // background-color: var(--primaryColor);
    //border: 1px solid black;
    //display: flex;
    //margin: calc(10px * var(--heightmultiplier));
    align-items: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    //border: 2px solid blue;

    //background-color: var(--primaryColor);
    border-radius: calc(16px * var(--heightmultiplier)) calc(16px * var(--heightmultiplier)) calc(16px * var(--heightmultiplier)) calc(16px * var(--heightmultiplier));

    padding: calc(0px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));

    &__filter {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: calc(50px * var(--heightmultiplier));
        width: 100%;
        position: relative;
        background-color: var(--primaryColor);
        padding: calc(5px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));
        border-radius: calc(10px * var(--heightmultiplier)) calc(0px * var(--heightmultiplier)) calc(10px * var(--heightmultiplier)) calc(10px * var(--heightmultiplier));

        &__title {
            //width: 7%;
            // background-color: #FD6125;
            color: var(--primaryTextColor);
            font-size: calc(24px * var(--heightmultiplier));
        }

        &__orderButton {
            position: absolute;
            right: 0;
            width: fit-content;
            height: 70%;
            border: 1px solid #4285F4;
            background-color: rgba($color: #4285F4, $alpha: 0.2);
            border-radius: calc(5px* var(--heightmultiplier));
            display: flex;
            align-items: center;
            justify-content: center;
            padding: calc(0px* var(--heightmultiplier)) calc(8px* var(--heightmultiplier));
            font-weight: 500;

            &:hover {
                cursor: pointer;
                background-color: rgba($color: #4285F4, $alpha: 0.8);
                color: white;
            }
        }

        &__options {
            // width: 30%;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            // border: 1px solid #FD6125;
            height: 80%;
            // padding-left: calc(20px * var(--heightmultiplier));

            &__item {
                width: 25%;
                color: var(--primaryTextColor);
                font-size: calc(18px * var(--heightmultiplier));
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0px 10px;
                margin: 0px 10px;
                border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
                background-color: white;
                border: 1px solid #FD6125;
                color: black;

                &:hover {
                    background-color: rgba($color: #FD6125, $alpha: 1);
                    border: 1px solid #FD6125;
                    color: white;
                }

                &:focus {
                    background-color: rgba($color: #FD6125, $alpha: 1);
                    border: 1px solid #FD6125;
                    color: white;
                }

                &__open {
                    width: 25%;
                    color: var(--primaryTextColor);
                    font-size: calc(18px * var(--heightmultiplier));
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0px 10px;
                    margin: 0px 10px;
                    border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
                    background-color: rgba($color: #F2D058, $alpha: 0.2);
                    // background-color: rgba(red, green, blue, alpha);
                    border: 1px solid #F2D058;

                    &:hover {
                        background-color: rgba($color: #F2D058, $alpha: 1);
                        border: 1px solid #F2D058;
                        color: white;
                    }

                    &:focus {
                        background-color: rgba($color: #F2D058, $alpha: 1);
                        border: 1px solid #F2D058;
                        color: white;
                    }

                }

                &__delivered {
                    width: 25%;
                    color: var(--primaryTextColor);
                    font-size: calc(18px * var(--heightmultiplier));
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0px 10px;
                    margin: 0px 10px;
                    border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
                    background-color: rgba($color: #6CCC54, $alpha: 0.2);
                    border: 1px solid #6CCC54;

                    &:hover {
                        background-color: rgba($color: #6CCC54, $alpha: 1);
                        border: 1px solid #6CCC54;
                        color: white;
                    }

                    &:focus {
                        background-color: rgba($color: #6CCC54, $alpha: 1);
                        border: 1px solid #6CCC54;
                        color: white;
                    }

                }

                &__cancelled {
                    width: 25%;
                    color: var(--primaryTextColor);
                    font-size: calc(18px * var(--heightmultiplier));
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0px 10px;
                    margin: 0px 10px;
                    border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
                    background-color: rgba($color: #F25858, $alpha: 0.2);
                    border: 1px solid #F25858;

                    &:hover {
                        background-color: rgba($color: #F25858, $alpha: 1);
                        border: 1px solid#F25858;
                        color: white;
                    }

                    &:focus {
                        background-color: rgba($color: #F25858, $alpha: 1);
                        border: 1px solid #F25858;
                        color: white;
                    }

                }

                &__self {
                    width: 25%;
                    color: var(--primaryTextColor);
                    font-size: calc(18px * var(--heightmultiplier));
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0px 10px;
                    margin: 0px 10px;
                    border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
                    background-color: rgba($color: #4285Fa, $alpha: 0.2);
                    border: 1px solid #4285Fa;

                    &:hover {
                        background-color: rgba($color: #4285Fa, $alpha: 1);
                        border: 1px solid #4285Fa;
                        color: white;
                    }

                    &:focus {
                        background-color: rgba($color: #4285Fa, $alpha: 1);
                        border: 1px solid #4285Fa;
                        color: white;
                    }
                }

                &__customer {
                    width: 25%;
                    color: var(--primaryTextColor);
                    font-size: calc(18px * var(--heightmultiplier));
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0px 10px;
                    margin: 0px 10px;
                    border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
                    background-color: rgba($color: #9d85f6, $alpha: 0.2);
                    border: 1px solid #9d85f6;

                    &:hover {
                        background-color: rgba($color: #9d85f6, $alpha: 1);
                        border: 1px solid#9d85f6;
                        color: white;
                    }

                    &:focus {
                        background-color: rgba($color: #9d85f6, $alpha: 1);
                        border: 1px solid #9d85f6;
                        color: white;
                    }
                }
            }

            &__selected-item {
                //width: 50%;
                color: white;
                font-size: calc(24px * var(--heightmultiplier));
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0px 10px;
                margin: 0px 10px;
                border-radius: calc(10px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
                background-color: rgba($color: #FD6125, $alpha: 1);
                //border: 1px solid #6CCC54
                // &:hover {
                //     background-color: rgba($color: #FD6125, $alpha: 0.5);
                //     border-color: #FD6125;
                //     color: white;
                // }
            }
        }

        &__duration-title {
            // width: 10%;
            color: var(--primaryTextColor);
            font-size: calc(24px * var(--heightmultiplier));
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0px 10px;
            //border: 1px solid #6CCC54
        }

        &__duration-select {
            color: black;
            font-size: calc(20px * var(--heightmultiplier));
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0px 10px;
            margin: 0px 10px;
            border-color: #FD6125;
            //background-color: var(--primaryTextColor);
            //border: none;
            border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
            // &__option{

            // }
        }
    }

    &__heading {
        width: 100%;
        //  height: fit-content;
        height: 10%;
        padding: calc(10px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));
        font-size: calc(20px * var(--heightmultiplier));

        //border: 1px solid green;
        &__tabel {
            width: 100%;
            height: 100%;
            //border: 1px solid red;
            padding: calc(10px * var(--heightmultiplier));
            //margin-top: calc(10px * var(--heightmultiplier));
            background-color: rgb(124, 124, 124);
            color: white;
            // align-items: center;
            display: flex;
            align-items: center;
            //justify-content: space-between;
            border-radius: calc(8px * var(--heightmultiplier));

            &__item {
                width: 10%;
                // border: 1px solid #F2D058;
                // display:flex;
                // text-align: center;
                align-items: center;

                // justify-content: space-evenly;
                &__noItem {
                    width: 10%;
                    height: 100%;
                    display: flex;
                    text-align: center;
                    align-items: center;
                    justify-content: space-evenly;
                    // border: 1px solid red;
                   
                    overflow: hidden;
                }

                &__customer {
                    width: 12%;
                    height: 100%;
                    display: flex;
                    text-align: center;
                    align-items: center;
                    justify-content: space-evenly;
                    //border: 1px solid red;
                    overflow: hidden;
                }

                &__weight {
                    width: 7%;
                    height: 100%;
                    display: flex;
                    text-align: center;
                    align-items: center;
                    justify-content: space-evenly;
                    //border: 1px solid red;
                    overflow: hidden;
                }

                &__quantity {
                    width: 5%;
                    height: 100%;
                    display: flex;
                    text-align: center;
                    align-items: center;
                    justify-content: space-evenly;
                    //border: 1px solid red;
                    overflow: hidden;
                }

                &__startLoc {
                    width: 10%;
                    height: 100%;
                    display: flex;
                    text-align: center;
                    align-items: center;
                    justify-content: space-evenly;
                    //border: 1px solid red;
                    overflow: hidden;
                }

                &__endLoc {
                    width: 12%;
                    height: 100%;
                    display: flex;
                    text-align: center;
                    align-items: center;
                    justify-content: space-evenly;
                    //border: 1px solid red;
                    overflow: hidden;
                }

                &__status {
                    width: 12%;
                    height: 100%;
                    display: flex;
                    text-align: center;
                    align-items: center;
                    justify-content: space-evenly;
                    //border: 1px solid red;
                    overflow: hidden;
                }

                &__orderDate {
                    width: 10%;
                    height: 100%;
                    display: flex;
                    text-align: center;
                    align-items: center;
                    justify-content: space-evenly;
                    //border: 1px solid red;
                    overflow: hidden;
                }

                &__deliveredD {
                    width: 10%;
                    height: 100%;
                    display: flex;
                    text-align: center;
                    align-items: center;
                    justify-content: space-evenly;
                    //border: 1px solid red;
                    overflow: hidden;
                }

                &__orderNo {
                    width: 8%;
                    height: 100%;
                    display: flex;
                    text-align: center;
                    align-items: center;
                    justify-content: space-evenly;
                    //border: 1px solid red;
                    overflow: hidden;
                }
                &__getDetails {
                    width: 8%;
                    height: 100%;
                    display: flex;
                    text-align: center;
                    align-items: center;
                    justify-content: space-evenly;
                    //border: 1px solid red;
                    overflow: hidden;
                }
            }


            &__itemStart {
                width: 7%;
                height: 100%;
                // text-align: left;
                text-align: center;

                &__serialno {
                    width: 5%;
                    height: 100%;
                    display: flex;
                    text-align: center;
                    align-items: center;
                    justify-content: space-evenly;
                    //border: 1px solid red;
                    overflow: hidden;
                }
            }


            &__itemEnd {
                width: 7%;
                height: 100%;
                text-align: center;
            
                &__track{
                    width: 15%;
                    height: 100%;
                    display: flex;
                    text-align: center;
                    align-items: center;
                    justify-content: space-evenly;
                    // border: 1px solid red;
                    overflow: hidden;
                }

            }
        }

        &__rows {
            overflow-y: auto;
            // align-items: center;
            // display: flex;
            // align-items: center;
            width: 100%;
            height: 100%;
            padding: calc(2px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));
            font-size: calc(20px * var(--heightmultiplier));

            //border: 1px solid black;
            // align-items: center;
            // display: flex;
            // align-items: center;
            &__itemRow {
                width: 100%;
                //width:fit-content;
                height: 12%;
                //height: fit-content;
                //border: 1px solid black;
                padding: calc(10px * var(--heightmultiplier));
                //margin-top: calc(5px * var(--heightmultiplier));
                background-color: var(--secondaryColor);
                font-size: calc(18px*var(--heightmultiplier));
                align-items: center;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                border-radius: calc(8px * var(--heightmultiplier));

                &:nth-of-type(even) {
                    background-color: var(--primaryColor);
                }


                &__item {
                    // width: 10%;
                    // height: 100%;
                    text-align: center;

                    // &__image {
                    //     width: auto;
                    //     height: 100%;
                    //     // border-radius: 50%;
                    // }

                    // &__updateButton {
                    //     width: 50%;
                    //     height: fit-content;
                    //     border: none;
                    //     // padding: calc(5px * var(--widthmultiplier))
                    //     // calc(5px * var(--heightmultiplier));
                    //     background-color: rgba($color: #03bd1c, $alpha: 0.1);
                    //     //opacity: 0.5;
                    //     color: var(--primaryTextColor);
                    //     border-radius: calc(10px* var(--heightmultiplier));

                    //     &:hover {
                    //         color: white;
                    //         background-color: rgba($color: #07920e, $alpha: 0.5);
                    //     }
                    // }
                    &__noItem {
                        width: 10%;
                        height: 100%;
                        display: flex;
                        text-align: center;
                        align-items: center;
                        justify-content: center;
                        word-break: break-word;
                        // white-space: normal;                    
                        // overflow-wrap: break-word;   
                        padding-inline: calc(12px * var(--widthmultiplier));                 
                        overflow: hidden;
                        // margin-right: 0.3rem;
                    }

                    &__customer {
                        width: 12%;
                        height: 100%;
                        display: flex;
                        text-align: center;
                        align-items: center;
                        justify-content: space-evenly;
                        //border: 1px solid red;
                        overflow: hidden;
                    }

                    &__weight {
                        width: 7%;
                        height: 100%;
                        display: flex;
                        text-align: center;
                        align-items: center;
                        justify-content: space-evenly;
                        //border: 1px solid red;
                        overflow: hidden;
                    }

                    &__quantity {
                        width: 5%;
                        height: 100%;
                        display: flex;
                        text-align: center;
                        align-items: center;
                        justify-content: space-evenly;
                        //border: 1px solid red;
                        overflow: hidden;
                    }

                    &__startLoc {
                        width: 10%;
                        height: 100%;
                        display: flex;
                        text-align: center;
                        align-items: center;
                        justify-content: space-evenly;
                        //border: 1px solid red;
                        overflow: hidden;
                    }

                    &__endLoc {
                        width: 12%;
                        height: 100%;
                        display: flex;
                        text-align: center;
                        align-items: center;
                        justify-content: space-evenly;
                        //border: 1px solid red;
                        overflow: hidden;
                    }

                    &__status {
                        // width: 12%;
                        // height: 100%;
                        // text-align: center;
                        // // align-items: center;
                        // border: 1px solid black;

                        &__colorCode8 {
                            width: 12%;
                            height: 100%;
                            display: flex;
                            text-align: center;
                            align-items: center;
                            justify-content: space-evenly;
                            //border: 1px solid red;
                            font-weight: 500;
                            overflow: hidden;
                            color: #6CCC54;
                        }

                        &__colorCode9 {
                            width: 12%;
                            height: 100%;
                            display: flex;
                            text-align: center;
                            align-items: center;
                            justify-content: space-evenly;
                            //border: 1px solid red;
                            font-weight: 500;
                            //overflow: hidden;
                            color: #F25858
                        }

                        &__colorCode1 {
                            width: 12%;
                            height: 100%;
                            // display: flex;
                            text-align: center;
                            align-items: center;
                            // justify-content: space-evenly;
                            //border: 1px solid red;
                            font-weight: 500;
                            overflow: hidden;
                            color: #F2D058;

                            &__updateBtn {
                                width: 80%;
                                height: 50%;
                                font-size: calc(16px * var(--heightmultiplier));
                                background-color: rgba($color: #F2D058, $alpha: 0.2);
                                border-color: #F2D058;
                                border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
                                color: var(--primaryTextColor);

                                //margin: calc(10px * var(--heightmultiplier));
                                &:hover {
                                    background-color: rgba($color: #F2D058, $alpha: 1);
                                    border-color: #F2D058;
                                    color: black;
                                }

                            }
                        }

                        &__colorCode2 {
                            width: 12%;
                            height: 100%;
                            // display: flex;
                            text-align: center;
                            align-items: center;
                            //justify-content: space-evenly;
                            // border: 1px solid red;
                            font-weight: 500;
                            overflow: hidden;
                            color: #F2D058;

                            &__updateBtn {
                                width: 80%;
                                height: 50%;
                                font-size: calc(16px * var(--heightmultiplier));
                                background-color: rgba($color: #F2D058, $alpha: 0.2);
                                border-color: #F2D058;
                                border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
                                color: var(--primaryTextColor);

                                //margin: calc(10px * var(--heightmultiplier));
                                &:hover {
                                    background-color: rgba($color: #F2D058, $alpha: 1);
                                    border-color: #F2D058;
                                    color: black;
                                }
                            }
                        }

                        &__colorCode3 {
                            width: 12%;
                            height: 100%;
                            display: flex;
                            text-align: center;
                            align-items: center;
                            justify-content: space-evenly;
                            //border: 1px solid red;
                            font-weight: 500;
                            overflow: hidden;
                            color: #F2D058;

                        }

                        &__colorCode4 {
                            width: 12%;
                            height: 100%;
                            display: flex;
                            text-align: center;
                            align-items: center;
                            justify-content: space-evenly;
                            //border: 1px solid red;
                            font-weight: 500;
                            overflow: hidden;
                            color: #F2D058
                        }

                        &__colorCode5 {
                            width: 12%;
                            height: 100%;
                            display: flex;
                            text-align: center;
                            align-items: center;
                            justify-content: space-evenly;
                            //border: 1px solid red;
                            font-weight: 500;
                            overflow: hidden;
                            color: #F2D058
                        }

                        &__colorCode6 {
                            width: 12%;
                            height: 100%;
                            display: flex;
                            text-align: center;
                            align-items: center;
                            justify-content: space-evenly;
                            //border: 1px solid red;
                            font-weight: 500;
                            overflow: hidden;
                            color: #F2D058
                        }

                        &__colorCode7 {
                            width: 12%;
                            height: 100%;
                            display: flex;
                            text-align: center;
                            align-items: center;
                            justify-content: space-around;
                            //border: 1px solid red;
                            font-weight: 500;
                            overflow: hidden;
                            color: #F2D058
                        }
                    }

                    &__orderDate {
                        width: 10%;
                        height: 100%;
                        display: flex;
                        text-align: center;
                        align-items: center;
                        justify-content: space-evenly;
                        //border: 1px solid red;
                        overflow: hidden;
                    }

                    &__deliveredD {
                        width: 10%;
                        height: 100%;
                        display: flex;
                        text-align: center;
                        align-items: center;
                        justify-content: space-evenly;
                        //border: 1px solid red;
                        overflow: hidden;
                    }

                    &__orderNo {
                        width: 8%;
                        height: 100%;
                        display: flex;
                        text-align: center;
                        align-items: center;
                        justify-content: space-evenly;
                        //border: 1px solid red;
                        overflow: hidden;
                    }
                }

                &__itemStart {
                    width: 7%;
                    height: 100%;
                    // text-align: left;
                    text-align: center;

                    &__serialno {
                        width: 5%;
                        height: 100%;
                        display: flex;
                        text-align: center;
                        align-items: center;
                        justify-content: space-evenly;
                        // border: 1px solid red;
                        overflow: hidden;
                    }
                }

                &__itemEnd {
                    width: 7%;
                    height: 100%;
                    // height: calc(40px * var(--heightmultiplier));
                    // font-size: calc(40px * var(--heightmultiplier));
                    text-align: center;
                    box-sizing: border-box;

                    //border: 1px solid red;
                    &__getDetails {
                        width: 8%;
                        height: 100%;
                        box-sizing: border-box;
                        display: flex;
                        text-align: center;
                        align-items: center;
                        justify-content: space-evenly;
                        //border: 1px solid red;
                        overflow: hidden;

                        &__getBtn {
                            border: 2px solid #FD6125;
                            width: 75%;
                            height: 55%;
                            color: var(--primaryTextColor);
                            font-size: calc(16px * var(--heightmultiplier));
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            // padding: 0px 10px;
                            // margin: 0px 10px;
                            //font-size: calc(16px * var(--heightmultiplier));
                            //font-weight: 500;
                            border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
                            background-color: rgba($color: #FD6125, $alpha: 0.2);

                            &:hover {
                                background-color: rgba($color: #FD6125, $alpha: 1);
                                color: white;
                            }
                        }
                    }
                    &__track {
                        width: 7%;
                        height: 100%;
                        box-sizing: border-box;
                        display: flex;
                        text-align: center;
                        align-items: center;
                        justify-content: space-evenly;
                        //border: 1px solid red;
                        overflow: hidden;

                        &__trackBtn {
                            border: 2px solid #4285Fa;
                            width: 75%;
                            height: 55%;
                            color: var(--primaryTextColor);
                            font-size: calc(16px * var(--heightmultiplier));
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            // padding: 0px 10px;
                            // margin: 0px 10px;
                            //font-size: calc(16px * var(--heightmultiplier));
                            //font-weight: 500;
                            border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
                            background-color: rgba($color: #4285Fa, $alpha: 0.2);

                            &:hover {
                                background-color: rgba($color: #4285Fa, $alpha: 1);
                                color: white;
                            }
                        }
                    }

                    &__delete {
                        color: red;

                        // font-size: calc(24px * var(--heightmultiplier));
                        &:hover {
                            // font-size: calc(40px * var(--heightmultiplier));
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}


.OrderTable {
    //border: 1px solid black;
    width: 100%;
    height: 8%;
    box-sizing: border-box;
    align-items: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--secondaryColor);
    border-top-right-radius: calc(16px * var(--heightmultiplier));

    &__title {
        height: 100%;
        width: 80%;
        box-sizing: border-box;
        font-family: "General Sans";
        font-style: normal;
        font-weight: 500;
        font-size: calc(40px * var(--heightmultiplier));
        // line-height: calc(54px * var(--heightmultiplier));
        color: var(--primaryTextColor);
        background-color: var(--secondaryColor);
        padding: calc(0px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));
        //border: 1px solid black;
        // margin-bottom: calc(48px * var(--heightmultiplier));
    }

    //   &__block{
    //     height: 92%;
    //     width: 100%;
    //   }
    &__header {
        width: 20%;
        height: 100%;
        background-color: var(--secondaryColor);
        align-items: center;
        display: flex;
        align-items: flex-start;
        //margin: calc(0px * var(--heightmultiplier)) calc(4px * var(--widthmultiplier));
        //margin: 0px;
        background-color: var(--primaryColor);
        border-top-left-radius: calc(16px * var(--heightmultiplier));
        border-top-right-radius: calc(16px * var(--widthmultiplier));

        // border: 1px solid black;
        &__links {
            width: 50%;
            height: 100%;
            border: none;
            font-size: calc(20px * var(--heightmultiplier));
            color: #5f5e5e;
            font-weight: calc(50px * var(--heightmultiplier));
            //   border: 1px solid red;
            //border-radius: calc(10px * var(--heightmultiplier));
            border-top-left-radius: calc(16px * var(--heightmultiplier));
            background-color: transparent;
            border-right: 2px solid rgb(117, 115, 115);
            padding: calc(12px * var(--heightmultiplier)) calc(15px * var(--widthmultiplier));

            &:hover {
                //border-top-left-radius: calc(16px * var(--heightmultiplier));
                background-color: #cecdcd;
                color: black;

            }

            &:focus {
                color: white;
                background-color: #fd6125;
            }
        }

        &__linksFocus {
            width: 50%;
            height: 100%;
            border: none;
            font-size: calc(20px * var(--heightmultiplier));
            color: white;
            font-weight: calc(50px * var(--heightmultiplier));
            //   border: 1px solid red;
            //border-radius: calc(10px * var(--heightmultiplier));
            border-top-left-radius: calc(16px * var(--heightmultiplier));
            background-color: #fd6125;
            border-right: 2px solid rgb(117, 115, 115);
            padding: calc(12px * var(--heightmultiplier)) calc(15px * var(--widthmultiplier));
        }

        &__linkFocus {
            width: 50%;
            height: 100%;
            border: none;
            font-size: calc(20px * var(--heightmultiplier));
            color: white;
            font-weight: calc(50px * var(--heightmultiplier));
            // border: 1px solid black;
            background-color: #fd6125;
            border-top-right-radius: calc(16px * var(--heightmultiplier));
            //border-right: 2px solid rgb(117, 115, 115);
            padding: calc(12px * var(--heightmultiplier)) calc(15px * var(--widthmultiplier));
            margin-right: calc(-5px * (--widthmultiplier));
        }

        &__link {
            width: 50%;
            height: 100%;
            border: none;
            font-size: calc(20px * var(--heightmultiplier));
            color: #5f5e5e;
            font-weight: calc(50px * var(--heightmultiplier));
            // border: 1px solid black;
            background-color: transparent;
            border-top-right-radius: calc(16px * var(--heightmultiplier));
            //border-right: 2px solid rgb(117, 115, 115);
            padding: calc(12px * var(--heightmultiplier)) calc(15px * var(--widthmultiplier));
            margin-right: calc(-5px * (--widthmultiplier));

            &:hover {
                //border-top-left-radius: calc(16px * var(--heightmultiplier));
                background-color: #cecdcd;
                color: black;

            }

            &:focus {
                color: white;
                background-color: #fd6125;
            }
        }

        &__link1 {
            height: 100%;
            //width: 30%;
            border: none;
            font-size: calc(20px * var(--heightmultiplier));
            color: #5f5e5e;
            font-weight: calc(50px * var(--heightmultiplier));
            //border: 1px solid black;
            background-color: transparent;
            //
            //border-top-right-radius: calc(16px * var(--heightmultiplier));
            border-right: 2px solid rgb(117, 115, 115);
            padding: calc(12px * var(--heightmultiplier)) calc(15px * var(--widthmultiplier));

            &:hover {
                //border-top-left-radius: calc(16px * var(--heightmultiplier));
                background-color: #cecdcd;
                color: black;

            }

            &:focus {
                color: white;
                background-color: #fd6125;
            }
        }
    }
}