@import "../variables";



.InvListSubContent-container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  // flex-direction: column;
  // align-items: flex-start;
  // justify-content: flex-start;
  // border: 1px solid black;

  &__title {
    box-sizing: border-box;
    font-family: "General Sans";
    font-style: normal;
    font-weight: 500;
    font-size: calc(40px * var(--heightmultiplier));
    line-height: calc(54px * var(--heightmultiplier));
    color: var(--primaryTextColor);
    // margin-bottom: calc(48px * var(--heightmultiplier));
  }

  &__totalItems {
    box-sizing: border-box;
    font-family: "General Sans";
    font-style: normal;
    font-weight: 400;
    font-size: calc(20px * var(--heightmultiplier));
    line-height: calc(27px * var(--heightmultiplier));
    color: var(--primaryTextColor);
    margin-left: calc(3px * var(--heightmultiplier));
  }

  &__subContainer {
    box-sizing: border-box;
    width: 100%;
    height: calc(730px * var(--heightmultiplier));
    background-color: var(--primaryColor);
    // display: flex;
    // flex-direction: column;
    align-items: center;
    // justify-content: flex-start;
    border-radius: calc(16px * var(--heightmultiplier));
    // border: 1px solid black;
    &__bottomFlex {
      display: flex;
      justify-content: space-between;
      //border: 1px solid black;
      &__box2 {
        // border: 1px solid #080808;
        height: calc(150px * var(--heightmultiplier));
        border-radius: calc(10px * var(--heightmultiplier));
        // border-radius: calc(10px * var(--heightmultiplier));
        width: 80%;
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &__label2 {
          // height:  calc(24px * var(--heightmultiplier));
          height: calc(50px * var(--heightmultiplier));
          font-size: calc(24px * var(--heightmultiplier));
          font-weight: bolder;
          background-color: var(--primaryColor);
          padding-left: calc(20px * var(--widthmultiplier));
          // border: 1px solid rgb(18, 214, 11);
          width: 11.5%;
        }
        &__file_input_textbox3 {
          // border: 5px solid #FD6125;
          height: 100%; // calc(50px * var(--heightmultiplier));
          font-size: calc(24px * var(--heightmultiplier));
          border: none;
          outline: none;
          padding-left: calc(10px * var(--widthmultiplier));
          // padding: 0px;
          border-radius: calc(10px * var(--heightmultiplier));
          border-top-left-radius: calc(10px * var(--heightmultiplier));
          border-bottom-left-radius: calc(10px * var(--heightmultiplier));
          width: 90%;
          margin-left: calc(16px * var(--widthmultiplier));
          //background-color: var(--primaryTextColor);
          color: var(--primaryTextColor);
          // border-color: rgba($color: #FD6125, $alpha: 1);
          background-color: var(--secondaryColor);
          resize: none;
          // margin: calc(5px * var(--heightmultiplier))
          //   calc(0px * var(--widthmultiplier));
        }
      }
      &__submitBtn {
        float: right;
        margin: calc(20px * var(--widthmultiplier));
        padding: calc(10px * var(--widthmultiplier))
          calc(10px * var(--heightmultiplier));
        // width: calc(30% * var(--widthmultiplier))
        &__inputBtn {
          height: calc(60px * var(--heightmultiplier));
          width: calc(180px * var(--widthmultiplier));
          background-color: rgba($color: #fd6125, $alpha: 0.1);
          color: #fd6125;
          font-size: calc(24px * var(--heightmultiplier));
          font-weight: 500;
          border: none;
          border-radius: calc(10px * var(--heightmultiplier));
          &:hover {
            cursor: pointer;
            color: white;
            background-color: rgba($color: #fd6125, $alpha: 1);
          }
        }
      }
    }
    &__itemsimage {
      height: auto;
      width: 38%;
      padding-right: calc(5px * var(--widthmultiplier));
      // border: 1px solid black;
      display: flex;
      align-items: center;
      justify-content: center;
      // margin: calc(5px * var(--heightmultiplier))  calc(100px * var(--widthmultiplier));
      &__image {
        height: auto;
        width: calc(500px * var(--heightmultiplier));
      }
    }
    &__listDown {
      box-sizing: border-box;
      height: fit-content;
      width: 100%;
      // display: flex;
      // flex-direction: column;
      align-items: center;
      // justify-content: flex-start;
      overflow-y: scroll;
      // border: 1px solid black;
    }

    &__top {
      width: 100%;
      height: calc(511px * var(--heightmultiplier));
      height: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      // border: 1px solid black;
    }
    &__itemscontainer {
      height: fit-content; //calc(80px * var(--heightmultiplier));
      width: 60%;
      // background-color: var(--primaryTextColor);
      border-radius: calc(
        10px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2
      );
      padding: calc(5px * var(--heightmultiplier))
        calc(20px * var(--widthmultiplier));
      padding-bottom: calc(0px * var(--heightmultiplier));
      // border: 1px solid black;
      &__bigbox{
         // border: 1px solid #080808;
         height: calc(60px * var(--heightmultiplier));
         border-radius: calc(10px * var(--heightmultiplier));
         // border-radius: calc(10px * var(--heightmultiplier));
         width: 100%;
         //background-color: var(--primaryTextColor);
         // background-color: var(--secondaryColor);
         padding: 0px;
         margin: calc(30px * var(--heightmultiplier))
           calc(0px * var(--widthmultiplier));
        //  border: 1px solid black;
         display: flex;
         align-items: center;
         justify-content: space-between;
         &__labelbig {
          // height:  calc(24px * var(--heightmultiplier));
          font-size: calc(24px * var(--heightmultiplier));
          font-weight: bolder;
          background-color: var(--primaryColor);
          padding: calc(0px * var(--widthmultiplier))
            calc(15px * var(--heightmultiplier));
          // border: 1px solid rgb(209, 19, 19);
          width: 10%;
        }
        &__file_input_textboxbig {
          // border: 1px solid #FD6125;
          height: calc(60px * var(--heightmultiplier));
          font-size: calc(24px * var(--heightmultiplier));
          border: none;
          outline: none;
          padding-left: calc(10px * var(--widthmultiplier));
          border-radius: calc(10px * var(--heightmultiplier));
          width: 87%;
          //background-color: var(--primaryTextColor);
          color: var(--primaryTextColor);
          // border-color: rgba($color: #FD6125, $alpha: 1);
          background-color: var(--secondaryColor);
          margin: calc(5px * var(--heightmultiplier))
            calc(0px * var(--widthmultiplier));
        }
      }
      &__box {
        // border: 1px solid #080808;
        height: calc(60px * var(--heightmultiplier));
        border-radius: calc(10px * var(--heightmultiplier));
        // border-radius: calc(10px * var(--heightmultiplier));
        width: 100%;
        //background-color: var(--primaryTextColor);
        // background-color: var(--secondaryColor);
        padding: 0px;
        margin: calc(30px * var(--heightmultiplier))
          calc(0px * var(--widthmultiplier));
        // border: 1px solid black;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &__label {
          // height:  calc(24px * var(--heightmultiplier));
          font-size: calc(24px * var(--heightmultiplier));
          font-weight: bolder;
          background-color: var(--primaryColor);
          padding: calc(0px * var(--widthmultiplier))
            calc(15px * var(--heightmultiplier));
          // border: 1px solid black;
          width: 14%;
        }
        &__file_input_textbox {
          // border: 1px solid #FD6125;
          height: calc(60px * var(--heightmultiplier));
          font-size: calc(24px * var(--heightmultiplier));
          border: none;
          outline: none;
          padding-left: calc(10px * var(--widthmultiplier));
          border-radius: calc(10px * var(--heightmultiplier));
          width: 78%;
          //background-color: var(--primaryTextColor);
          color: var(--primaryTextColor);
          // border-color: rgba($color: #FD6125, $alpha: 1);
          background-color: var(--secondaryColor);
          margin: calc(5px * var(--heightmultiplier))
            calc(0px * var(--widthmultiplier));
        }
        &__file_input_textbox2 {
          // border: 1px solid #0e0d0d;
          outline: none;
          border: none;
          height: 100%;
          font-size: calc(24px * var(--heightmultiplier));
          border-radius: calc(10px * var(--heightmultiplier));
          border-top-right-radius: calc(0px * var(--heightmultiplier));
          border-bottom-right-radius: calc(0px * var(--heightmultiplier));
          // border-radius: calc(10px * var(--heightmultiplier));
          width: 78%;
          //background-color: var(--primaryTextColor);
          color: var(--primaryTextColor);
          // border-color: rgba($color: #FD6125, $alpha: 1);
          background-color: var(--secondaryColor);
          margin: 0px;
          padding-left: calc(10px * var(--widthmultiplier));
        }
        &__file_input_button {
          border: none;
          width: 20%;
          font-size: calc(24px * var(--heightmultiplier));
          font-weight: 500;
          height: calc(60px * var(--heightmultiplier));
          border-top-right-radius: calc(10px * var(--heightmultiplier));
          border-bottom-right-radius: calc(10px * var(--heightmultiplier));
          // border-radius: calc(10px * var(--heightmultiplier));
          background-color: rgba($color: #fd6125, $alpha: 0.1);
          color: #fd6125;
          margin: 0px;
          &:hover {
            cursor: pointer;
            color: white;
            background-color: rgba($color: #fd6125, $alpha: 1);
          }
        }
        &__file_input_hidden {
          display: none;
          // visibility: hidden;
        }
      }
      &__place-order {
        height: calc(45px * var(--heightmultiplier));
        width: calc(200px * var(--widthmultiplier));
        // background-color: var(--primaryColor);
        border-radius: calc(
          10px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2
        );
        // display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba($color: #fd6125, $alpha: 0.1);

        font-family: "General Sans";
        font-style: normal;
        font-weight: 500;
        font-size: calc(20px * var(--heightmultiplier));
        // margin-bottom: calc(28px * var(--heightmultiplier));
        color: #fd6125;

        &:hover {
          cursor: pointer;
          color: white;
          background-color: rgba($color: #fd6125, $alpha: 1);
        }
      }
      &__update-cart {
        height: calc(45px * var(--heightmultiplier));
        width: calc(200px * var(--widthmultiplier));
        // background-color: var(--primaryColor);
        border-radius: calc(
          10px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2
        );
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba($color: #fd6125, $alpha: 0.1);

        font-family: "General Sans";
        font-style: normal;
        font-weight: 500;
        font-size: calc(20px * var(--heightmultiplier));
        // margin-bottom: calc(28px * var(--heightmultiplier));
        color: #fd6125;

        &:hover {
          cursor: pointer;
          color: white;
          background-color: rgba($color: #fd6125, $alpha: 1);
        }
      }

      &__total {
        // height: calc(80px * var(--heightmultiplier));
        width: calc(250px * var(--widthmultiplier));
        font-family: "General Sans";
        font-style: normal;
        font-weight: 500;
        font-size: calc(20px * var(--heightmultiplier));
        line-height: calc(27px * var(--heightmultiplier));
        text-align: center;
        color: var(--primaryTextColor);
        display: flex;
        align-items: center;
        justify-content: center;

        &__tag {
          margin-right: calc(20px * var(--widthmultiplier));
        }

        // opacity: 0.4;
      }
    }
  }
}
// @media screen and (max-width: 1020px){
//   .InvListSubContent-container{
//     border: 1px solid red;
//     width: 100%;
//   height: 100%;
//   box-sizing: border-box;
//   // flex-direction: column;
//   // align-items: flex-start;
//   // justify-content: flex-start;
//   // border: 1px solid black;

//   &__title {
//     box-sizing: border-box;
//     font-family: "General Sans";
//     font-style: normal;
//     font-weight: 500;
//     font-size: calc(40px * var(--heightmultiplier));
//     line-height: calc(54px * var(--heightmultiplier));
//     color: var(--primaryTextColor);
//     // margin-bottom: calc(48px * var(--heightmultiplier));
//   }

//   &__totalItems {
//     box-sizing: border-box;
//     font-family: "General Sans";
//     font-style: normal;
//     font-weight: 400;
//     font-size: calc(20px * var(--heightmultiplier));
//     line-height: calc(27px * var(--heightmultiplier));
//     color: var(--primaryTextColor);
//     margin-left: calc(3px * var(--heightmultiplier));
//   }

//   &__subContainer {
//     box-sizing: border-box;
//     width: 100%;
//     height: calc(730px * var(--heightmultiplier));
//     background-color: var(--primaryColor);
//     // display: flex;
//     // flex-direction: column;
//     align-items: center;
//     // justify-content: flex-start;
//     border-radius: calc(16px * var(--heightmultiplier));
//     // border: 1px solid black;
//     &__bottomFlex {
//       display: flex;
//       justify-content: space-between;
//       //border: 1px solid black;
//       &__box2 {
//         // border: 1px solid #080808;
//         height: calc(150px * var(--heightmultiplier));
//         border-radius: calc(10px * var(--heightmultiplier));
//         // border-radius: calc(10px * var(--heightmultiplier));
//         width: 80%;
//         padding: 0px;
//         display: block;
//         align-items: center;
//         // justify-content: space-between;
//         &__label2 {
//           // height:  calc(24px * var(--heightmultiplier));
//           height: calc(50px * var(--heightmultiplier));
//           font-size: calc(24px * var(--heightmultiplier));
//           font-weight: bolder;
//           background-color: var(--primaryColor);
//           padding-left: calc(20px * var(--widthmultiplier));
//           // border: 1px solid rgb(18, 214, 11);
//           width: 11.5%;
//         }
//         &__file_input_textbox3 {
//           // border: 5px solid #FD6125;
//           height: 100%; // calc(50px * var(--heightmultiplier));
//           font-size: calc(24px * var(--heightmultiplier));
//           border: none;
//           outline: none;
//           padding-left: calc(10px * var(--widthmultiplier));
//           // padding: 0px;
//           border-radius: calc(10px * var(--heightmultiplier));
//           border-top-left-radius: calc(10px * var(--heightmultiplier));
//           border-bottom-left-radius: calc(10px * var(--heightmultiplier));
//           width: 90%;
//           margin-left: calc(16px * var(--widthmultiplier));
//           //background-color: var(--primaryTextColor);
//           color: var(--primaryTextColor);
//           // border-color: rgba($color: #FD6125, $alpha: 1);
//           background-color: var(--secondaryColor);
//           resize: none;
//           // margin: calc(5px * var(--heightmultiplier))
//           //   calc(0px * var(--widthmultiplier));
//         }
//       }
//       &__submitBtn {
//         float: right;
//         margin: calc(20px * var(--widthmultiplier));
//         padding: calc(10px * var(--widthmultiplier))
//           calc(10px * var(--heightmultiplier));
//         // width: calc(30% * var(--widthmultiplier))
//         &__inputBtn {
//           height: calc(60px * var(--heightmultiplier));
//           width: calc(180px * var(--widthmultiplier));
//           background-color: rgba($color: #fd6125, $alpha: 0.1);
//           color: #fd6125;
//           font-size: calc(24px * var(--heightmultiplier));
//           font-weight: 500;
//           border: none;
//           border-radius: calc(10px * var(--heightmultiplier));
//           &:hover {
//             cursor: pointer;
//             color: white;
//             background-color: rgba($color: #fd6125, $alpha: 1);
//           }
//         }
//       }
//     }
//     &__itemsimage {
//       height: auto;
//       width: 38%;
//       padding-right: calc(5px * var(--widthmultiplier));
//       // border: 1px solid black;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       // margin: calc(5px * var(--heightmultiplier))  calc(100px * var(--widthmultiplier));
//       &__image {
//         height: auto;
//         width: calc(500px * var(--heightmultiplier));
//       }
//     }
//     &__listDown {
//       box-sizing: border-box;
//       height: fit-content;
//       width: 100%;
//       // display: flex;
//       // flex-direction: column;
//       align-items: center;
//       // justify-content: flex-start;
//       overflow-y: scroll;
//       // border: 1px solid black;
//     }

//     &__top {
//       width: 100%;
//       height: calc(511px * var(--heightmultiplier));
//       height: auto;
//       display: flex;
//       align-items: center;
//       justify-content: space-between;
//       // border: 1px solid black;
//     }
//     &__itemscontainer {
//       height: fit-content; //calc(80px * var(--heightmultiplier));
//       width: 60%;
//       // background-color: var(--primaryTextColor);
//       border-radius: calc(
//         10px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2
//       );
//       padding: calc(5px * var(--heightmultiplier))
//         calc(20px * var(--widthmultiplier));
//       padding-bottom: calc(0px * var(--heightmultiplier));
//       // border: 1px solid black;
//       &__bigbox{
//          // border: 1px solid #080808;
//          height: calc(60px * var(--heightmultiplier));
//          border-radius: calc(10px * var(--heightmultiplier));
//          // border-radius: calc(10px * var(--heightmultiplier));
//          width: 100%;
//          //background-color: var(--primaryTextColor);
//          // background-color: var(--secondaryColor);
//          padding: 0px;
//          margin: calc(30px * var(--heightmultiplier))
//            calc(0px * var(--widthmultiplier));
//         //  border: 1px solid black;
//          display: block;
//          align-items: center;
//         //  justify-content: space-between;
//          &__labelbig {
//           // height:  calc(24px * var(--heightmultiplier));
//           font-size: calc(24px * var(--heightmultiplier));
//           font-weight: bolder;
//           background-color: var(--primaryColor);
//           padding: calc(0px * var(--widthmultiplier))
//             calc(15px * var(--heightmultiplier));
//           // border: 1px solid rgb(209, 19, 19);
//           width: 10%;
//         }
//         &__file_input_textboxbig {
//           // border: 1px solid #FD6125;
//           height: calc(60px * var(--heightmultiplier));
//           font-size: calc(24px * var(--heightmultiplier));
//           border: none;
//           outline: none;
//           padding-left: calc(10px * var(--widthmultiplier));
//           border-radius: calc(10px * var(--heightmultiplier));
//           width: 87%;
//           //background-color: var(--primaryTextColor);
//           color: var(--primaryTextColor);
//           // border-color: rgba($color: #FD6125, $alpha: 1);
//           background-color: var(--secondaryColor);
//           margin: calc(5px * var(--heightmultiplier))
//             calc(0px * var(--widthmultiplier));
//         }
//       }
//       &__box {
//         // border: 1px solid #080808;
//         height: calc(60px * var(--heightmultiplier));
//         border-radius: calc(10px * var(--heightmultiplier));
//         // border-radius: calc(10px * var(--heightmultiplier));
//         width: 100%;
//         //background-color: var(--primaryTextColor);
//         // background-color: var(--secondaryColor);
//         padding: 0px;
//         margin: calc(30px * var(--heightmultiplier))
//           calc(0px * var(--widthmultiplier));
//         // border: 1px solid black;
//         display: block;
//         align-items: center;
//         // justify-content: space-between;
//         &__label {
//           // height:  calc(24px * var(--heightmultiplier));
//           font-size: calc(24px * var(--heightmultiplier));
//           font-weight: bolder;
//           background-color: var(--primaryColor);
//           padding: calc(0px * var(--widthmultiplier))
//             calc(15px * var(--heightmultiplier));
//           // border: 1px solid black;
//           width: 14%;
//         }
//         &__file_input_textbox {
//           // border: 1px solid #FD6125;
//           height: calc(60px * var(--heightmultiplier));
//           font-size: calc(24px * var(--heightmultiplier));
//           border: none;
//           outline: none;
//           padding-left: calc(10px * var(--widthmultiplier));
//           border-radius: calc(10px * var(--heightmultiplier));
//           width: 78%;
//           //background-color: var(--primaryTextColor);
//           color: var(--primaryTextColor);
//           // border-color: rgba($color: #FD6125, $alpha: 1);
//           background-color: var(--secondaryColor);
//           margin: calc(5px * var(--heightmultiplier))
//             calc(0px * var(--widthmultiplier));
//         }
//         &__file_input_textbox2 {
//           // border: 1px solid #0e0d0d;
//           outline: none;
//           border: none;
//           height: 100%;
//           font-size: calc(24px * var(--heightmultiplier));
//           border-radius: calc(10px * var(--heightmultiplier));
//           border-top-right-radius: calc(0px * var(--heightmultiplier));
//           border-bottom-right-radius: calc(0px * var(--heightmultiplier));
//           // border-radius: calc(10px * var(--heightmultiplier));
//           width: 78%;
//           //background-color: var(--primaryTextColor);
//           color: var(--primaryTextColor);
//           // border-color: rgba($color: #FD6125, $alpha: 1);
//           background-color: var(--secondaryColor);
//           margin: 0px;
//           padding-left: calc(10px * var(--widthmultiplier));
//         }
//         &__file_input_button {
//           border: none;
//           width: 20%;
//           font-size: calc(24px * var(--heightmultiplier));
//           font-weight: 500;
//           height: calc(60px * var(--heightmultiplier));
//           border-top-right-radius: calc(10px * var(--heightmultiplier));
//           border-bottom-right-radius: calc(10px * var(--heightmultiplier));
//           // border-radius: calc(10px * var(--heightmultiplier));
//           background-color: rgba($color: #fd6125, $alpha: 0.1);
//           color: #fd6125;
//           margin: 0px;
//           &:hover {
//             cursor: pointer;
//             color: white;
//             background-color: rgba($color: #fd6125, $alpha: 1);
//           }
//         }
//         &__file_input_hidden {
//           display: none;
//           // visibility: hidden;
//         }
//       }
//       &__place-order {
//         height: calc(45px * var(--heightmultiplier));
//         width: calc(200px * var(--widthmultiplier));
//         // background-color: var(--primaryColor);
//         border-radius: calc(
//           10px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2
//         );
//         // display: flex;
//         align-items: center;
//         justify-content: center;
//         background-color: rgba($color: #fd6125, $alpha: 0.1);

//         font-family: "General Sans";
//         font-style: normal;
//         font-weight: 500;
//         font-size: calc(20px * var(--heightmultiplier));
//         // margin-bottom: calc(28px * var(--heightmultiplier));
//         color: #fd6125;

//         &:hover {
//           cursor: pointer;
//           color: white;
//           background-color: rgba($color: #fd6125, $alpha: 1);
//         }
//       }
//       &__update-cart {
//         height: calc(45px * var(--heightmultiplier));
//         width: calc(200px * var(--widthmultiplier));
//         // background-color: var(--primaryColor);
//         border-radius: calc(
//           10px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2
//         );
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         background-color: rgba($color: #fd6125, $alpha: 0.1);

//         font-family: "General Sans";
//         font-style: normal;
//         font-weight: 500;
//         font-size: calc(20px * var(--heightmultiplier));
//         // margin-bottom: calc(28px * var(--heightmultiplier));
//         color: #fd6125;

//         &:hover {
//           cursor: pointer;
//           color: white;
//           background-color: rgba($color: #fd6125, $alpha: 1);
//         }
//       }

//       &__total {
//         // height: calc(80px * var(--heightmultiplier));
//         width: calc(250px * var(--widthmultiplier));
//         font-family: "General Sans";
//         font-style: normal;
//         font-weight: 500;
//         font-size: calc(20px * var(--heightmultiplier));
//         line-height: calc(27px * var(--heightmultiplier));
//         text-align: center;
//         color: var(--primaryTextColor);
//         display: flex;
//         align-items: center;
//         justify-content: center;

//         &__tag {
//           margin-right: calc(20px * var(--widthmultiplier));
//         }

//         // opacity: 0.4;
//       }
//     }
//   }
// }
// }