

.dashboard-container {
    width: 100%;
    height: 100%;
    background-color: var(--secondaryColor);
    overflow: hidden;
    padding: 0px;
    margin: 0px;
    // background-color: var(--primaryColor);
    padding: calc(15px* var(--heightmultiplier)) calc(15px* var(--widthmultiplier));
    // border-radius: calc(16px * var(--heightmultiplier));
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: calc(100px * var(--heightmultiplier));
    // border: 1px solid green;

    &__Header{
        width: 100%;
        height: 10%;
        // border: 1px solid green;
        display: flex;
        flex-direction: row;
        text-align: left;
        align-items: center;
        justify-content: flex-start;

    }
    &__cardComponent{
        width: 100%;
        // height: 90%;
        // padding: calc(40px * var(--heightmultiplier)) calc(100px * var(--widthmultiplier));
        // border: 1px solid blue;
        // display: grid;
        // grid-template-columns: 1fr 1fr; 
        // grid-template-rows: auto auto;
        display: flex;
        flex-wrap: wrap;
        column-gap: calc(100px * var(--heightmultiplier));
        justify-content: center;
        align-items: center
    }

}