.CustomerDetailsRow-container {
    width: 100%;
    height: fit-content;
    // border: 1px solid green;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: calc(8px * var(--heightmultiplier)) calc(8px * var(--widthmultiplier));

    &__info {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        width: fit-content;
        height: fit-content;
        border-radius: calc(10px * var(--heightmultiplier));
        padding: calc(5px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));
        color: var(--primaryTextColor);
        border: 2px solid var(--primaryTextColor);
        position: relative;
        // border: 1px solid blue;

        &__label {
            width: fit-content;
            position: absolute;
            left: calc(12px * var(--widthmultiplier));
            top: -26%;
            height: fit-content;
            // margin-top: calc(-20px * var(--heightmultiplier));
            // margin-left: calc(10px * var(--heightmultiplier));
            padding: calc(0px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
            // border: 1px solid blue;
            font-size: calc(15px * var(--heightmultiplier));
            font-weight: 500;
            display: flex;
            text-align: flex-start;
            align-items: center;
            background-color: var(--primaryColor);
            color: var(--primaryTextColor)
        }


        &__customerInput {
            width: fit-content;
            height: fit-content;
            // position: absolute;
            // top: 20px;
            // left: 2px;
            // border: 2px solid var(--primaryTextColor);
            display: flex;
            text-align: center;
            align-items: center;
            justify-content: flex-start;
            // margin-left: calc(0px* var(--widthmultiplier));
            // padding: calc(0px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
            // overflow-y: hidden;
            // position: relative;
    
            &__label {
                width: fit-content;
                // margin-left: calc(10px * var(--heightmultiplier));
                padding: calc(0px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
                height: fit-content;
                display: flex;
                text-align: center;
                align-items: center;
                justify-content: space-evenly;
                
                font-size: calc(16px * var(--heightmultiplier));
                font-weight: 500;
                // padding: calc(5px * var(--heightmultiplier))

                &__cont{
                    min-width: calc(150px * var(--widthmultiplier));
                    // border: 1px solid blue;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-wrap: nowrap;
                }
    
            }
    
            &__value {
                width: 60%;
                height: fit-content;
                // border: 1px solid blue;
                font-size: calc(16px * var(--heightmultiplier));
                border: none;
                outline: none;
                display: flex;
                text-align: flex-start;
                align-items: center;
                background-color: var(--primaryColor);
                border-radius: calc(10px * var(--heightmultiplier));
                padding: calc(5px * var(--heightmultiplier));
    
                &__input {
                    width: fit-content;
                    height: fit-content;
                    outline: none;
                    border-radius: calc(5px * var(--heightmultiplier));
                    padding: calc(0px * var(--heightmultiplier)) calc(2px * var(--widthmultiplier));
                    //border: none;
                    border: 1px solid var(--primaryTextColor);
                    color: var(--primaryTextColor);
                    background-color: var(--primaryColor);
                }
                &__static {
                    width: fit-content;
                    height: fit-content;
                    margin-right: calc(3px * var(--heightmultiplier));
                    border-radius: calc(5px * var(--heightmultiplier));
                    padding: calc(0px * var(--heightmultiplier)) calc(2px * var(--widthmultiplier));
                    //border: none;
                    // border: 1px solid var(--primaryTextColor);
                    color: var(--primaryTextColor);
                    background-color: var(--primaryColor);
                }
    
                &__input1 {
                    width: fit-content;
                    height: fit-content;
                    //outline: none;
                    border-radius: calc(5px * var(--heightmultiplier));
                    padding: calc(0px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));
                    //border: none;
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px;
                    border-right: none;
                    border: 1px solid var(--primaryTextColor);
                    color: var(--primaryTextColor);
                    background-color: var(--primaryColor);
                }
    
                &__input2 {
                    width: fit-content;
                    height: fit-content;
                    //outline: none;
                    border-radius: calc(5px * var(--heightmultiplier));
                    padding: calc(0px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                    //border: none;
                    border-left: none;
                    border: 1px solid var(--primaryTextColor);
                    color: var(--primaryTextColor);
                    background-color: var(--primaryColor);
                }
            }
        }
    }


}