.productCardContainer {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: var(--primaryColor);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: calc(30px * var(--heightmultiplier)) calc(30px * var(--widthmultiplier));
  border-radius: calc(10px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
  // z-index: 2;

  //border: 1px solid red;
  &:hover {
    border-radius: calc(10px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
    // top: calc(-20px * var(--heightmultiplier));
    box-shadow: 0px 0px 15px #FD6125;
    //background-color: #FD6125;
  }

  &:focus {
    border-radius: calc(10px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
    // top: calc(-20px * var(--heightmultiplier));
    box-shadow: 0px 0px 10px #FD6125;
    //background-color: #FD6125;
  }

  &__itemCounter {
    display: flex;
    justify-content: space-around;
    //margin: 20px;
    width: calc(200px * var(--widthmultiplier));
    height: calc(40px * var(--heightmultiplier));
    //background-color: rgba(253, 97, 37, 0.1);
    background-color: rgba(253, 238, 232, 0.1);
    box-sizing: border-box;
    padding: 0px calc(38px * var(--widthmultiplier));
    border-radius: calc(8px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
    border: 2px solid orangered;
    border-color: #FD6125;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: calc(23px * var(--heightmultiplier));
    color: #fd6125;

    &:hover {
      cursor: pointer;
      //background-color:rgba(253, 97, 37, 0.1);
      color: white;
    }


    &__button {
      min-width: calc(22px * var(--widthmultiplier));
      min-height: calc(22px * var(--heightmultiplier));
      height: calc(22px * var(--widthmultiplier));
      width: calc(22px * var(--heightmultiplier));
      display: flex;
      align-items: center;
      justify-content: space-around;
      border-radius: calc(8px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
      border: none;
      background-color: rgba($color: #FD6125, $alpha: 0.1);
      stroke: #FD6125;

      &:hover {
        background-color: rgba($color: #FD6125, $alpha: 1);
        stroke: #FD6125;
        cursor: pointer;
      }
    }

    &__input {
      min-width: calc(22px * var(--widthmultiplier));
      min-height: calc(22px * var(--heightmultiplier));
      height: calc(22px * var(--widthmultiplier));
      width: calc(25px * var(--heightmultiplier));
      //  width: 40px;
      text-align: center;
      border: none;
      outline: none;
      color: var(--primaryTextColor);
      background-color: inherit;
    
    
    }
    
    

  }

}





.svgbag {
  width: fit-content;
  height: fit-content;
  border: 1px solid red;
}
.cartIcon{
  width:calc(18px * var(--widthmultiplier));
  height: calc(20px * var(--widthmultiplier));
  // border: 1px solid red;
  // margin-right: calc(10px * var(--heightmultiplier));
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  
}