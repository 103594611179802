@import '../styles/variables';

.register-page-body{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $chatBox-bgcolor;

}

.register-page-main-container{
    width: 500px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: flex-start;
    background-color: $sidePanel-bgcolor;
    box-shadow: 1px 1px 20px black;
}
.register-page-usericon-container{
    position: relative;
    top: -50px;
    width: 100px;
    height: 100px;
    background-color: yellow;
    border-radius: 50%;
    box-shadow: 1px 1px 10px gray;
}
.register-page-member-register-name{
    font-size: 30px;
    color: white;
    margin-top: -30px;
}
.register-page-input-username{
    background-color: #f2f2f2;
    height: 50px;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    border: none;
    margin-top: 20px;
    text-align: center;
    border-radius: 6px;
}
.register-page-input-password{
    background-color: #f2f2f2;
    height: 50px;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    border: none;
    margin-top: 20px;
    text-align: center;
    border-radius: 6px;
}
.register-page-input-password:focus{
    background-color: white;
}
.register-page-input-confirm-password{
    background-color: #f2f2f2;
    height: 50px;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    border: none;
    margin-top: 20px;
    text-align: center;
    border-radius: 6px;
}
.register-page-input-confirm-password:focus{
    background-color: white;
}
.register-page-btn-register{
    width: 80%;
    background-color: skyblue;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    margin-top: 30px;
    border-radius: 6px;
    margin-bottom: 20px;
}

.register-page-btn-register:hover{
    cursor: pointer;
}
.register-page-error-message{
    opacity: 0;
    color: red;
    margin-bottom: 40px;
}
.register-page-login-link{
    color: rgb(38, 0, 70);
    font-size: 20px;
   
}
.register-page-login-link:hover{
    cursor: pointer;
    text-decoration: underline;
}