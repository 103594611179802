.AddCatagoryForm{
    //position: relative;
    width: 100%;
    height: 100%;
    //height: 100%;
    //border: 2px solid rgb(184, 11, 11);
    display: block;
    &__title{
    width: 100%;
    height: 30%;
    //width: 70%;
    box-sizing: border-box;
    font-family: "General Sans";
    font-style: normal;
    font-weight: 500;
    align-self: center;
   // border: 2px solid orangered;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: space-around;
    //justify-content: space-between;
    font-size: calc(38px * var(--heightmultiplier));
   // line-height: calc(54px * var(--heightmultiplier));
    color: var(--primaryTextColor);
   // margin: calc(2px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
    }
    &__formContainer{
        width: 100%;
        height: 40%;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: space-around;
        //border: 2px solid rgb(255, 208, 0);
        &__lable{
            width:40%;
            height: calc(40px * var(--heightmultiplier));
            //height: fit-content;
            //width: 70%;
            box-sizing: border-box;
            font-family: "General Sans";
            font-style: normal;
            font-weight: 500;
            font-size: calc(24px * var(--heightmultiplier));
            //line-height: calc(32px * var(--heightmultiplier));
           // border: 1px solid blue;
            //justify-items: flex-start;
            color: var(--primaryTextColor);
            // margin: calc(2px * var(--heightmultiplier))
            //   calc(5px * var(--widthmultiplier));
        }
        &__colon{
            width: 10%;
            height: calc(40px * var(--heightmultiplier));
            //height: fit-content;
            //width: 70%;
            box-sizing: border-box;
            font-family: "General Sans";
            font-style: normal;
            font-weight: 500;
            font-size: calc(24px * var(--heightmultiplier));
            line-height: calc(32px * var(--heightmultiplier));
            //border: 1px solid blue;
            //justify-items: flex-start;
            color: var(--primaryTextColor);
            // margin: calc(2px * var(--heightmultiplier))
            //     calc(5px * var(--widthmultiplier));
        }
        &__inputBox{
            width:50%;
            height: calc(40px * var(--heightmultiplier));
            //height:fit-content;
            //width: 70%;
            background-color: var(--primaryColor);
            //border: none;
            box-sizing: border-box;
            font-family: "General Sans";
            font-style: normal;
            font-weight: 500;
            font-size: calc(20px * var(--heightmultiplier));
            line-height: calc(32px * var(--heightmultiplier));
            color: var(--primaryTextColor);
            border-radius: calc(10px * var(--heightmultiplier));
            padding: calc(0px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
            //border: 1px solid green;
            //justify-items: flex-start;
            // margin: calc(2px * var(--heightmultiplier))
            //     calc(5px * var(--widthmultiplier));
            &:hover{
                outline: none;
            }
        }
    }
    &__Options{
        width: 100%;
        height: 30%;
       // border: 1px solid pink;
        display: flex;
        align-items: center;
        justify-content: space-between;
       // margin: calc(10px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));
      }
}
#Cancel {
    width: 25.43%;
    height: 100%;
  
    font-style: normal;
    font-weight: 400;
    color: #FC6025;
    background-color: rgba(253,97,37, 0.1);
    border-radius: 5px;
    z-index: 12;
    display: flex;
    padding: 0px 10px;
    align-items: center;
    justify-content: center;
    /* border: 2px solid #FC6025; */
  }
  
  #Cancel:hover {
    cursor: pointer;
    /* color: white; */
    font-weight: 600;
    background-color: #FC6025;
    color: white;
  }
  
  #Okay {
    
    box-sizing: border-box;
    width: 25.43%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 600;
    background-color: rgba(253,97,37, 1);
    border-radius: 5px;
    color: white;
  
  }
  
  #Okay:hover {
    cursor: pointer;
    background-color: #FC6025;
  }