// #sourceLocationInput {
//   border: 2px solid lightgrey;
//   box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
//   width: 100%;
//   height: 100%;
//   justify-content: center;
//   text-align: center;
//   font-weight: bold;
//   font-size: 15px;
//   // margin-left: 0.3rem;

// }

.SideDrawer-container {
  width: 30%;
  height: 100%;
  position: absolute;
  right: 0px;
  top: 0px;
  background-color: var(--primaryColor);


  .__header {
    height: 100px;
    width: 100%;
    background-color: black;

    .__cancel {
      height: 100px;
      width: 100%;
      background-color: black;

    }


  }
}