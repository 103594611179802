.leftPanelTabLabel-container {
    width: 100%;
    font-size: calc(26px * var(--heightmultiplier)* var(--widthmultiplier))/2;
    margin-top: calc(18px * var(--heightmultiplier));
    height: calc(50px * var(--heightmultiplier));
    padding-left: calc(30px * var(--widthmultiplier));
    display: flex;
    // border: 1px solid red;
    align-items: center;
    justify-content: flex-start;
    border: 4px solid transparent;

    &:hover {
        cursor: pointer;
    }

}

.leftPanelTabLabel-containerActive {
    width: 100%;
    font-size: calc(26px * var(--heightmultiplier)* var(--widthmultiplier))/2;
    margin-top: calc(18px * var(--heightmultiplier));
    height: calc(50px * var(--heightmultiplier));
    padding-left: calc(30px * var(--widthmultiplier));
    display: flex;
    // border: 1px solid red;
    align-items: center;
    justify-content: flex-start;
    border: 2px solid #FD6125;
    border-radius: calc(12px * var(--widthmultiplier));

    &:hover {
        cursor: pointer;
    }

}

.leftPanelTabLabel-textActive {
    font-size: calc(26px * var(--heightmultiplier)* var(--widthmultiplier))/2;
    // font-size: calc(20px * var(--heightmultiplier));
    margin-left: calc(30px * var(--widthmultiplier));
    color: rgba($color: #FD6125, $alpha: 1.0);
    // border: 1px solid red;
    font-weight: 500;
}

.leftPanelTabLabel-text {
    font-size: calc(26px * var(--heightmultiplier)* var(--widthmultiplier))/2;
    // font-size: calc(20px * var(--heightmultiplier));
    margin-left: calc(30px * var(--widthmultiplier));
    color: var(--primaryTextColor);
    // border: 1px solid red;
    font-weight: 400;
}