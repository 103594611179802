.export-button {
  // display: inline-block;
  padding: calc(4px * var(--heightmultiplier)) calc(9px * var(--heightmultiplier));
  font-size: calc(18px * var(--heightmultiplier));
  font-weight: 600;
  color: #ffffff;
  background-color: #fb5411;
  border: none;
  border-radius: calc(4px * var(--heightmultiplier)); 

  transition: background-color 0.3s ease, box-shadow 0.2s ease, transform 0.2s ease;
  text-align: center;
  // border: 1px solid black;

  &:hover {
    cursor: pointer;
    background-color: #fd6b35; 
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1); 
    transform: translateY(-1px);

  &:active {
    background-color: #e55720; 
    transform: translateY(0);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15); 
  }

  &:disabled {
    background-color: #d3d3d3; 
    cursor: not-allowed;
    opacity: 0.7;
  }
}
}
.Export-btn-container{
  width: 100%;
  height: 100%;
  // border: 1px solid black;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}