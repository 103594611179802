.Home-container {
  width: 100%;
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  // display: block;
  // border: 1px solid blue;
  &__inventory-container{
    // width: 50%;
    height: 100%;
    // border: 1px solid red;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &__title {
      width: 100%;
      height: 5%;
      // border: 2px solid purple;
      box-sizing: border-box;
      font-family: 'General Sans';
      font-style: normal;
      font-weight: 500;
      font-size: calc(40px * var(--heightmultiplier));
      line-height: calc(54px * var(--heightmultiplier));
      color: var(--primaryTextColor);
      margin-bottom: calc(2px * var(--heightmultiplier));
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      &__text{
        width: 80%;
        height: 100%;
        // border: 1px solid red;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
      }
      &__dropdown{
        width: 100%;
        height: 100%;
        // border: 1px solid rgb(0, 255, 234);
        // display: flex;
        // flex-direction: row;
        // align-items: center;
        // justify-content: center;
      }
  
    }
    &__product-container {
      width: 100%;
      height: 94%;
      // border: 2px solid yellow;
      direction: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
  
      &__noProductFound-text {
        width: 100%;
        height: 100%;
        // border: 2px solid green;
        direction: flex;
        flex-direction: row;
        text-align: center;
        align-content: center;
        justify-items: center;
        font-size: calc(28px * var(--heightmultiplier));
        font-weight: 500;
      }
    }
  }
  &__QuickCheckoutContainer{
    // width: 50%;
    height: 100%;
    // border: 1px solid green;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    // padding: calc(10px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));
    &__product-description{
      position: relative;
      box-sizing: border-box;
      height: 100%;
      background-color: var(--primaryColor);
      width: 100%;
      // border: 1px solid black;
      padding: calc(21px * var(--heightmultiplier)) calc(21px * var(--widthmultiplier));
      border-radius: calc(10px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }
}