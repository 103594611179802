.UserDropDown_modalBackground {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(6, 6, 6, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 105;
  /* border: 1px solid black; */
}



.UserDropDown_modalContainer {
  width: 36%;
  height: 33.33%;

  border-radius: 0.75em;

  background-color: var(--primaryColor);;
  box-shadow: #FD6125 0px 5px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  z-index: 20;
  /* border: 5px solid #FC6025; */
  /* padding-top: calc(50px);
  padding-bottom: calc(50px); */
}

.userDropDown {
  box-sizing: border-box;
  position: absolute;
  // height: calc(5px * var(--heightmultiplier));
  width: calc(200px * var(--widthmultiplier));
  top: calc(100px * var(--heightmultiplier));
  right: calc(60px * var(--widthmultiplier));
  background-color: var(--primaryColor);
  border-radius: calc(10px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
  padding: calc(17px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));
  z-index: 1000;
  box-shadow: 0px 0px 15px #FD6125;
  transition: all 1s linear;

  &__options {
      height: calc(38px * var(--heightmultiplier));
      width: calc(182px * var(--widthmultiplier));
      margin: calc(8px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: calc(10px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
      color: var(--primaryTextColor);


      &:hover {
          background-color: rgba(253, 97, 37, 0.1);
          cursor: pointer;
      }
  }
}