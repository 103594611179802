@import "../variables";



.OrdersListSubContent-container {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    
    // border: 1px solid black;
    &__header{
        width: 100%;
        height: 6%;
        // border: 1px solid yellow;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        &__titleContainer{
            width: fit-content;
            height: fit-content;
            // border: 1px solid blue;
        }
    }

    &__title {
        width: fit-content;
        height: fit-content;
        box-sizing: border-box;
        font-family: 'General Sans';
        font-style: normal;
        font-weight: 500;
        font-size: calc(24px * var(--heightmultiplier));
        line-height: calc(54px * var(--heightmultiplier));
        padding-left: calc(10px * var(--heightmultiplier));
        color: var(--primaryTextColor);
        // border: 1px solid black;
        // margin-bottom: calc(48px * var(--heightmultiplier));

    }
    &__filter {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: calc(50px * var(--heightmultiplier));
        width: fit-content;
        position: relative;
        // border: 1px solid red;

        &__title {
            //width: 7%;
            // background-color: #FD6125;
            color: var(--primaryTextColor);
            font-size: calc(24px * var(--heightmultiplier));
        }
        &__orderButton{
            position: absolute;
            right: 0;
            width: 20%;
            height: 70%;
            border: 1px solid #4285F4;
            background-color: rgba($color: #4285F4, $alpha: 0.2);
            border-radius: calc(5px* var(--heightmultiplier));
            display: flex;
            align-items: center;
            justify-content: center;
            padding: calc(0px* var(--heightmultiplier)) calc(8px* var(--heightmultiplier));
            font-weight: 500;
            &:hover{
                cursor: pointer;
                background-color: rgba($color: #4285F4, $alpha: 0.8);
                color: white;
            }
        }

        &__options {
            width: 30%;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            // border: 1px solid #FD6125;
            height: 80%;
            // padding-left: calc(20px * var(--heightmultiplier));

            &__item {
                width: 25%;
                color: var(--primaryTextColor);
                font-size: calc(18px * var(--heightmultiplier));
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0px 10px;
                margin: 0px 10px;
                border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
                background-color: white;
                border: 1px solid #FD6125;
                color: black;

                &:hover {
                    background-color: rgba($color: #FD6125, $alpha: 1);
                    border: 1px solid #FD6125;
                    color: white;
                }
                &:focus {
                    background-color: rgba($color: #FD6125, $alpha: 1);
                    border:1px solid #FD6125;
                    color: white;
                }

                &__open {
                    width: fit-content;
                    color: var(--primaryTextColor);
                    font-size: calc(18px * var(--heightmultiplier));
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0px 10px;
                    margin: 0px 10px;
                    border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
                    background-color: rgba($color: #F2D058, $alpha: 0.2);
                    // background-color: rgba(red, green, blue, alpha);
                    border: 1px solid #F2D058;

                    &:hover {
                        background-color: rgba($color: #F2D058, $alpha: 1);
                        border: 1px solid #F2D058;
                        color: white;
                    }
                    &:focus {
                        background-color: rgba($color: #F2D058, $alpha: 1);
                        border: 1px solid #F2D058;
                        color: white;
                    }

                }

                &__delivered {
                    width: fit-content;
                    color: var(--primaryTextColor);
                    font-size: calc(18px * var(--heightmultiplier));
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0px 10px;
                    margin: 0px 10px;
                    border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
                    background-color: rgba($color: #6CCC54, $alpha: 0.2);
                    border: 1px solid #6CCC54;
                    &:hover {
                        background-color: rgba($color: #6CCC54, $alpha: 1);
                        border: 1px solid #6CCC54;
                        color: white;
                    }
                    &:focus {
                        background-color: rgba($color: #6CCC54, $alpha: 1);
                        border: 1px solid #6CCC54;
                        color: white;
                    }

                }

                &__cancelled {
                    width: fit-content;
                    color: var(--primaryTextColor);
                    font-size: calc(18px * var(--heightmultiplier));
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0px 10px;
                    margin: 0px 10px;
                    border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
                    background-color: rgba($color: #F25858, $alpha: 0.2);
                    border: 1px solid #F25858;

                    &:hover {
                        background-color: rgba($color: #F25858, $alpha: 1);
                        border: 1px solid#F25858;
                        color: white;
                    }
                    &:focus {
                        background-color: rgba($color: #F25858, $alpha: 1);
                        border: 1px solid #F25858;
                        color: white;
                    }

                }
            }

            &__selected-item {
                //width: 50%;
                color: white;
                font-size: calc(24px * var(--heightmultiplier));
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0px 10px;
                margin: 0px 10px;
                border-radius: calc(10px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
                background-color: rgba($color: #FD6125, $alpha: 1);
                //border: 1px solid #6CCC54
                // &:hover {
                //     background-color: rgba($color: #FD6125, $alpha: 0.5);
                //     border-color: #FD6125;
                //     color: white;
                // }
            }
        }

        &__duration-title {
            // width: 10%;
            color: var(--primaryTextColor);
            font-size: calc(24px * var(--heightmultiplier));
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0px 10px;
            //border: 1px solid #6CCC54
        }

        &__duration-select {
            color: black;
            font-size: calc(20px * var(--heightmultiplier));
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0px 10px;
            margin: 0px 10px;
            border-color: #FD6125;
            //background-color: var(--primaryTextColor);
            //border: none;
            border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
            // &__option{

            // }
        }
    }

    &__totalItems {
        width:calc(200px * var(--widthmultiplier));
        height: calc(50px * var(--heightmultiplier));
        box-sizing: border-box;
        font-family: 'General Sans';
        font-style: normal;
        font-weight: 400;
        font-size: calc(20px * var(--heightmultiplier));
        line-height: calc(27px * var(--heightmultiplier));
        color: var(--primaryTextColor);
        margin-left: calc(3px * var(--heightmultiplier));
        // border: 1px solid green;

    }

    &__subContainer {
        box-sizing: border-box;
        width: 100%;
        height: 94%;
        // height: calc(800px * var(--heightmultiplier));
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        // border: 1px solid black;

        &__listDown {
            box-sizing: border-box;
            height: 95%;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            overflow-y: auto;
            // border: 1px solid purple;
            //padding: calc(10px * var(--heightmultiplier));
            padding: calc(15px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));
        }


    }
}

.OrdersListItems-container {
    height: 5%;
    width: 96%;
    // background-color: var(--primaryColor);
    border-radius: calc(10px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
    padding: calc(0px * var(--heightmultiplier)) calc(50px * var(--widthmultiplier));
    display: flex;
    align-items: center;
    justify-content: space-between;
    // margin-bottom: calc(28px * var(--heightmultiplier));
    // border: 1px solid black;

    &__Sr-No {
        height: 100%;
        width: calc(60px * var(--widthmultiplier));
        font-family: 'General Sans';
        font-style: normal;
        font-weight: 500;
        font-size: calc(20px * var(--heightmultiplier));
        line-height: calc(27px * var(--heightmultiplier));
        padding: 0px;
        color: var(--primaryTextColor);
        display: flex;
        align-items: center;
        justify-content: center;
        // border: 1px solid black;
        // opacity: 0.4;
    }

    &__order-no {
        // height: calc(80px * var(--heightmultiplier));
        width: calc(300px * var(--widthmultiplier));
        font-family: 'General Sans';
        font-style: normal;
        font-weight: 500;
        font-size: calc(20px * var(--heightmultiplier));
        line-height: calc(27px * var(--heightmultiplier));
        // border: 1px solid black;

        color: var(--primaryTextColor); // opacity: 0.4;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__quantity {
        // height: calc(80px * var(--heightmultiplier));
        width: calc(200px * var(--widthmultiplier));
        font-family: 'General Sans';
        font-style: normal;
        font-weight: 500;
        font-size: calc(20px * var(--heightmultiplier));
        line-height: calc(27px * var(--heightmultiplier));
        display: flex;
        align-items: center;
        justify-content: center;

        color: var(--primaryTextColor) // opacity: 0.4;
    }

    &__totalWeight {
        // height: calc(80px * var(--heightmultiplier));
        width: calc(150px * var(--widthmultiplier));
        font-family: 'General Sans';
        font-style: normal;
        font-weight: 500;
        font-size: calc(20px * var(--heightmultiplier));
        line-height: calc(27px * var(--heightmultiplier));
        text-align: center;

        color: var(--primaryTextColor); // opacity: 0.4;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__total-quantity {
        width: calc(150px * var(--widthmultiplier));
        font-family: 'General Sans';
        font-style: normal;
        font-weight: 500;
        font-size: calc(20px * var(--heightmultiplier));
        line-height: calc(27px * var(--heightmultiplier));
        text-align: center;

        color: var(--primaryTextColor);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__total-price {
        width: calc(150px * var(--widthmultiplier));
        font-family: 'General Sans';
        font-style: normal;
        font-weight: 500;
        font-size: calc(20px * var(--heightmultiplier));
        line-height: calc(27px * var(--heightmultiplier));
        text-align: center;

        color: var(--primaryTextColor);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__status {
        width: calc(210px * var(--widthmultiplier));
        font-family: 'General Sans';
        font-style: normal;
        font-weight: 500;
        font-size: calc(20px * var(--heightmultiplier));
        line-height: calc(27px * var(--heightmultiplier));
        text-align: center;
        color: var(--primaryTextColor);
        display: flex;
        align-items: center;
        justify-content: center;
        // border: 1px solid black;
    }
    &__actions{
        width: calc(100px * var(--widthmultiplier));
        font-family: 'General Sans';
        font-style: normal;
        font-weight: 500;
        font-size: calc(20px * var(--heightmultiplier));
        line-height: calc(27px * var(--heightmultiplier));
        text-align: center;
        color: var(--primaryTextColor);
        display: flex;
        align-items: center;
        justify-content: center;
        // border: 1px solid black;
    }
}