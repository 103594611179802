@import "../variables";
@import "rsuite/dist/rsuite.css";


.ShopifySubComponent-container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  //border: 1px solid black;
  &__topBar {
    width: 100%;
    height: fit-content;
    //border: 1px solid red;
  }

  &__title {
    box-sizing: border-box;
    font-family: 'General Sans';
    font-style: normal;
    font-weight: 500;
    font-size: calc(40px * var(--heightmultiplier));
    line-height: calc(54px * var(--heightmultiplier));
    color: var(--primaryTextColor);
    // margin-bottom: calc(48px * var(--heightmultiplier));
    //border: 1px solid black;

  }

  &__totalItems {
    box-sizing: border-box;
    font-family: 'General Sans';
    font-style: normal;
    font-weight: 400;
    font-size: calc(20px * var(--heightmultiplier));
    //line-height: calc(27px * var(--heightmultiplier));
    color: var(--primaryTextColor);
    margin-left: calc(3px * var(--heightmultiplier));
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 95%;
    // height: fit-content;
    //border: 1px solid black;

    &__locationCont {
        width: 80%;
      display: flex;
       flex-direction: row;
       gap: 3rem;
      align-items: center;
      justify-content: space-evenly;
      // margin: calc(10px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
      //border: 1px solid black;
    }
    &__locationCont2 {
        display: flex;
        width: 80%;
         flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        // margin: calc(10px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
        //border: 1px solid black;
      }
    &__locationCont1 {
      // display: flex;
      // flex-direction: column;
      // align-items: center;
      // justify-content: space-evenly;
      width: 80%;
      height: 100%;
      border-radius: calc(10px * var(--heightmultiplier));
      padding: calc(5px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));
      color: var(--primaryTextColor);
      border: 2px solid var(--primaryTextColor);

      &__label {
        width: fit-content;
        margin-left: calc(10px * var(--heightmultiplier));
        padding: calc(0px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
        height: 100%;
        //border: 1px solid blue;
        font-size: calc(20px * var(--heightmultiplier));
        font-weight: 500;
        display: flex;
        text-align: flex-start;
        align-items: center;
        margin-top: calc(-18px * var(--heightmultiplier));
        background-color: var(--secondaryColor);
        color: var(--primaryTextColor)
      }
    }

  }

  &__inputRadio {
    width: 98%;
    height: 15%;
    //border: 2px solid var(--primaryTextColor);
    border-radius: calc(10px * var(--heightmultiplier));
    margin: calc(10px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));
    color: var(--primaryTextColor);
    display: flex;
    flex-direction: column;
    text-align: flex-start;
    align-items: flex-start;
    justify-content: flex-start;

    &__label {
      width: 98%;
      //margin-left: calc(10px * var(--heightmultiplier));
      padding: calc(0px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
      height: 100%;
      //border: 1px solid blue;
      font-size: calc(16px * var(--heightmultiplier));
      //font-weight: 600;
      display: flex;
      text-align: flex-start;
      align-items: center;
      //margin-top: calc(-12px * var(--heightmultiplier));
      color: var(--primaryTextColor)
    }

    &__value {
      width: 100%;
      height: 40%;
      //border: 1px solid blue;
      font-size: calc(14px * var(--heightmultiplier));
      padding: calc(0px * var(--heightmultiplier)) calc(15px * var(--widthmultiplier));
      display: flex;
      flex-direction: row;
      text-align: center;
      align-items: center;
      justify-content: space-between;
      //background-color: var(--primaryColor);

      &__input {
        width: 100%;
        height: 100%;
        outline: none;
        border: none;
        //border: 1px solid blue;
        color: var(--primaryTextColor);
        //background-color: var(--primaryColor);
      }
    }
  }

  &__inputFiled {
    // width: 98%;
    // height: fit-content;
    border: 2px solid var(--primaryTextColor);
    border-radius: calc(10px * var(--heightmultiplier));
    margin: calc(12px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
    // overflow-y: hidden;
    // position: relative;

    &__label {
      width: fit-content;
      margin-left: calc(10px * var(--heightmultiplier));
      padding: calc(0px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
      height: fit-content;
      //border: 1px solid blue;
      font-size: calc(20px * var(--heightmultiplier));
      font-weight: 500;
      display: flex;
      text-align: left;
      align-items: center;
      margin-top: calc(-12px * var(--heightmultiplier));
      background-color: var(--secondaryColor);
      color: var(--primaryTextColor) // position: absolute;
        // left: 0px;
        // top: 0px;

    }

    &__value {
      width: 100%;
      height: fit-content;
      //border: 1px solid blue;
      font-size: calc(18px * var(--heightmultiplier));
      display: flex;
      text-align: flex-start;
      align-items: center;
      background-color: var(--secondaryColor);
      border-radius: calc(10px * var(--heightmultiplier));
      padding: calc(5px * var(--heightmultiplier));


      &__input {
        width: 100%;
        height: 100%;
        outline: none;
        border: none;
        color: var(--primaryTextColor);
        background-color: var(--secondaryColor);
      }
    }
  }

  &__inputFiled2 {
    width: 20%;
    height: fit-content;
    border: 2px solid var(--primaryTextColor);
    border-radius: calc(10px * var(--heightmultiplier));
    margin: calc(12px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
    // overflow-y: hidden;
    // position: relative;

    &__label {
      width: fit-content;
      margin-left: calc(10px * var(--heightmultiplier));
      padding: calc(0px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
      height: fit-content;
      //border: 1px solid blue;
      font-size: calc(16px * var(--heightmultiplier));
      //font-weight: 600;
      display: flex;
      text-align: flex-start;
      align-items: center;
      margin-top: calc(-12px * var(--heightmultiplier));
      background-color: var(--secondaryColor);
      color: var(--primaryTextColor) // position: absolute;
        // left: 0px;
        // top: 0px;

    }

    &__value {
      width: 100%;
      height: fit-content;
      //border: 1px solid blue;
      font-size: calc(16px * var(--heightmultiplier));
      display: flex;
      text-align: flex-start;
      align-items: center;
      background-color: var(--secondaryColor);
      border-radius: calc(10px * var(--heightmultiplier));
      padding: calc(5px * var(--heightmultiplier));


      &__input {
        width: 100%;
        height: 100%;
        outline: none;
        border: none;
        color: var(--primaryTextColor);
        background-color: var(--secondaryColor);
      }
    }
  }

  &__customerInput {
    width: 100%;
    height: 100%;
    //border: 2px solid var(--primaryTextColor);
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: space-evenly;
   // padding: calc(0px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
    // overflow-y: hidden;
    // position: relative;

    &__label {
      width: 20%;
      // margin-left: calc(10px * var(--heightmultiplier));
       padding: calc(25px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
      height: fit-content;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: space-evenly;
      //border: 1px solid blue;
      font-size: calc(20px * var(--heightmultiplier));
      font-weight: 500;

    }

    &__value {
      width: 80%;
      height: 40px;
      //border: 1px solid blue;
      font-size: calc(18px * var(--heightmultiplier));
      display: flex;
      text-align: flex-start;
      align-items: center;
      background-color: var(--secondaryColor);
      border-radius: calc(10px * var(--heightmultiplier));
      padding: calc(5px * var(--heightmultiplier));
      &__input {
        width: 100%;
        height: 100%;
        outline: none;
        border-radius: calc(5px * var(--heightmultiplier));
        padding: calc(0px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));
        //border: none;
        border: 1px solid var(--primaryTextColor);
        color: var(--primaryTextColor);
        background-color: var(--secondaryColor);
      }
      &__input1 {
        width: 100%;
        height: 100%;
        //outline: none;
        border-radius: calc(5px * var(--heightmultiplier));
        padding: calc(0px * var(--heightmultiplier)) calc(px * var(--widthmultiplier));
        //border: none;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-right: none;
        border: 1px solid var(--primaryTextColor);
        color: var(--primaryTextColor);
        background-color: var(--secondaryColor);
      }
      &__input2 {
        width: 100%;
        height: 100%;
        //outline: none;
        border-radius: calc(5px * var(--heightmultiplier));
        padding: calc(0px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        //border: none;
        border-left: none;
        border: 1px solid var(--primaryTextColor);
        color: var(--primaryTextColor);
        background-color: var(--secondaryColor);
      }
    }
  }

  // &__datePicker{
  //     border: 1px solid red;
  //     color: var(--primaryTextColor);
  //     position: absolute;
  // }    
  &__subContainer {
    box-sizing: border-box;
    width: 100%;
    height: calc(730px * var(--heightmultiplier));
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    // border: 1px solid black;

    &__listDown {
      box-sizing: border-box;
      height: calc(500px * var(--heightmultiplier));
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      overflow-y: scroll;
      // border: 1px solid black;
    }


  }


  &__ListDownItems-container {
    height: calc(80px * var(--heightmultiplier));
    width: calc(1400px * var(--widthmultiplier));
    // background-color: var(--primaryColor);
    border-radius: calc(10px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
    // padding: calc(0px * var(--heightmultiplier)) calc(50px * var(--widthmultiplier));
    display: flex;
    align-items: center;
    justify-content: space-between;
    // margin-bottom: calc(28px * var(--heightmultiplier));
    // border: 1px solid black;


    &__place-order {
      height: calc(45px * var(--heightmultiplier));
      width: calc(200px * var(--widthmultiplier));
      // background-color: var(--primaryColor);
      border-radius: calc(10px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba($color: #FD6125, $alpha: 0.1);
      border: none;

      font-family: 'General Sans';
      font-style: normal;
      font-weight: 500;
      font-size: calc(20px * var(--heightmultiplier));
      // margin-bottom: calc(28px * var(--heightmultiplier));
      color: #FD6125;

      &:hover {
        cursor: pointer;
        color: white;
        background-color: rgba($color: #FD6125, $alpha: 1);
      }
    }

    &__update-cart {
      height: calc(45px * var(--heightmultiplier));
      width: calc(200px * var(--widthmultiplier));
      // background-color: var(--primaryColor);
      border-radius: calc(10px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba($color: #FD6125, $alpha: 0.1);
      border: none;
      font-family: 'General Sans';
      font-style: normal;
      font-weight: 500;
      font-size: calc(20px * var(--heightmultiplier));
      // margin-bottom: calc(28px * var(--heightmultiplier));
      color: #FD6125;

      &:hover {
        cursor: pointer;
        color: white;
        background-color: rgba($color: #FD6125, $alpha: 1);
      }
    }

    &__total {
      height: calc(80px * var(--heightmultiplier));
      width: calc(450px * var(--widthmultiplier));
      font-family: 'General Sans';
      font-style: normal;
      font-weight: 500;
      font-size: calc(20px * var(--heightmultiplier));
      line-height: calc(27px * var(--heightmultiplier));
      text-align: center;
      color: var(--primaryTextColor);
      display: flex;
      align-items: center;
      justify-content: space-between;

      //border: 1px solid maroon;
      &__tag {
        margin-right: calc(20px * var(--widthmultiplier));
      }

      &__value {
        width: 100%;
        height: 100%;

        // border: 1px solid red;
        &__data {
          width: 100%;
          height: 100%;
          //border: 1px solid pink;
          display: flex;
          text-align: center;
          align-items: center;
          justify-content: space-between;
        }
      }

      // opacity: 0.4;
    }

  }
}

// .MuiButtonBase-root {
//     font-size: calc(20px * var(--heightmultiplier));
// }


// .MuiOutlinedInput-notchedOutline {
//     &:hover {

//         border-color: #FD6125;
//         border-width: 5px;
//     }
// }