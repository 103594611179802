@import "rsuite/dist/rsuite.css";

.quickCart-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  //border: 1px solid black;
  border-radius: 4px;
  overflow: hidden;
  // gap: 0.2rem;
  // padding: calc(10px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));
  background-color: var(--primaryColor);



  &__heading {
    // border: 1px solid black;
    height: fit-content;
    width: 100%;
    // height: calc(300px * var(--heightmultiplier));
    display: flex;
    // flex-direction: column;
    padding: calc(10px * var(--heightmultiplier)) calc(20px * var(--widthmultiplier));
    gap: calc(5px * var(--heightmultiplier));
    align-items: center;
    justify-content: space-evenly;
    border-bottom: 3px solid var(--secondaryColor);

    &__inputFiled {
      width: 50%;
      flex-grow: 1;
      height: fit-content;
      border: 1px solid var(--primaryTextColor);
      border-radius: calc(10px * var(--heightmultiplier));
      margin: calc(12px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
      // border: 1px solid green;
      // overflow-y: hidden;
      // position: relative;

      &__label {
        width: fit-content;
        margin-left: calc(10px * var(--heightmultiplier));
        padding: calc(0px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
        height: fit-content;
        // border: 1px solid blue;
        font-size: calc(16px * var(--heightmultiplier));
        //font-weight: 600;
        display: flex;
        text-align: left;
        align-items: center;
        margin-top: calc(-12px * var(--heightmultiplier));
        background-color: var(--primaryColor);
        color: var(--primaryTextColor) // position: absolute;
          // left: 0px;
          // top: 0px;

      }

      &__value {
        width: 100%;
        height: fit-content;
        // border: 1px solid blue;
        font-size: calc(16px * var(--heightmultiplier));
        display: flex;
        text-align: flex-start;
        align-items: center;
        background-color: var(--primaryColor);
        border-radius: calc(10px * var(--heightmultiplier));
        padding: calc(5px * var(--heightmultiplier));


        &__input {
          width: 100%;
          height: 100%;
          outline: none;
          border: none;
          color: var(--primaryTextColor);
          background-color: var(--primaryColor);
        }
      }
    }


  }

  &__delivery-type {

    width: 100%;
    height: fit-content;
    border-bottom: 3px solid var(--secondaryColor);
    padding: calc(10px * var(--heightmultiplier)) calc(20px * var(--widthmultiplier));
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: center;

    &__container {
      width: 100%;
      height: fit-content;
      // border: 1px solid blue;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      &__title {
        width: 30%;
        height: 100%;
        // border: 1px solid yellow;
        font-size: calc(16px * var(--heightmultiplier)) !important;
        font-weight: 500;
        color: var(--primaryTextColor)
      }

      &__radiobtn {
        width: 100%;
        height: fit-content;
        // border: 1px solid red;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: calc(0px * var(--heightmultiplier)) calc(16px * var(--heightmultiplier));
        font-size: calc(16px * var(--heightmultiplier)) !important;
        font-weight: 600 !important;
        // margin-left: 10rem;
        // margin-top: -2rem;
      }
    }

    &__urgent-reason {
      width: 60%;
      height: fit-content;
      border: 1px solid var(--primaryTextColor);
      border-radius: calc(10px * var(--heightmultiplier));
      margin: calc(12px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
      // border: 1px solid blue;
      &__label {
        width: fit-content;
        margin-left: calc(10px * var(--heightmultiplier));
        padding: calc(0px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
        height: fit-content;
        //border: 1px solid blue;
        font-size: calc(16px * var(--heightmultiplier));
        //font-weight: 600;
        display: flex;
        text-align: flex-start;
        align-items: center;
        margin-top: calc(-12px * var(--heightmultiplier));
        background-color: var(--primaryColor);
        color: var(--primaryTextColor) // position: absolute;
          // left: 0px;
          // top: 0px;
      }

      &__colon {
        width: 4%;
        height: 100%;
        // border: 1px solid blue;
      }

      &__options {
        width: 100%;
        height: fit-content;
        border: none;
        outline: none;
        border-radius: calc(6px * var(--widthmultiplier));
        padding: calc(0px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
        background-color: var(--primaryColor);
        // border-color: var(--FD6125);
         // border: 1px solid #FD6125;
        color: var(--primaryTextColor);
      }
    }

    &__calander {
      width: fit-content;
      height: fit-content;
      // margin-top: 4rem;
      // margin-left: 8rem;
      // border: 1px solid red;

      &__datepicker {
        width: fit-content;
        height: fit-content;
        // border: 1px solid blue;
        background-color: var(--primaryColor) !important;
        // margin-right: 2rem;

      }
    }
  }




  &__tableHeader {
    display: flex;
    // flex-direction: column;
    justify-content: space-between;
    align-items: center;
    // background-color: #f5f5f5;
    border-bottom: 3px solid var(--secondaryColor);
    // padding: calc(0px * var(--heightmultiplier)) calc(10px * var(--heightmultiplier));
    width: 100%;
    height: 5%;
    // position: sticky;
    color: #ccc;
    top: 0px;
    background-color: var(--primaryColor) !important;
    // z-index: 10;

    &__drawer-header-title {
      width: 55%;
      height: 100%;
      text-align: center;
      font-size: calc(16px * var(--heightmultiplier)) !important;
      font-weight: 500;
      color: var(--primaryTextColor);
      // margin-left: 1.2rem;
      // border: 1px solid green;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    &__drawer-header-quantity {
      width: 24%;
      height: 100%;
      text-align: center;
      font-size: calc(16px * var(--heightmultiplier)) !important;
      font-weight: 500;
      color: var(--primaryTextColor);
      // margin-left: 1.2rem;
      // border: 1px solid green;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    &__drawer-header-delete {
      width: 15%;
      height: 100%;
      text-align: center;
      font-size: calc(16px * var(--heightmultiplier)) !important;
      font-weight: 500;
      color: var(--primaryTextColor);
      // margin-left: 1.2rem;
      margin-right: calc(10px * var(--heightmultiplier));
      // border: 1px solid green;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }


  }



  &__drawer-body {
    height: 50%;
    width: 100%;
    // border: 1px solid green;
    // border-top: 2px solid var(--secondaryTextColor);
    flex: 1;
    // padding: 16px  !important;
    padding: 0px !important;
    overflow-y: auto;


    &__item-list {
      width: 100%;
      height: fit-content;
      // border: 1px solid red;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;



      &__drawer-item {
        width: 100%;
        height: 100%;
        display: flex;
        // flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: calc(5px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier)) !important;
        // font-size: calc(26px * var(--heightmultiplier) + var(--widthmultiplier)/2);
        // font-size: calc(16px * var(--heightmultiplier));
        // border: 2px solid black;
        border-bottom: 2px solid var(--secondaryColor);
        /* Light grey border */
        // box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        /* Subtle shadow effect */
        // border-radius: 8px;


        &__item-name {
          // font-size:calc(66px * var(--heightmultiplier) + var(--widthmultiplier)/2);
          width: 55%;
          height: 100%;
          // text-align: center;
          font-size: calc(18px * var(--heightmultiplier)) !important;
          font-weight: 400;
          color: var(--primaryTextColor);
          // margin-left: 1.2rem;
          // border: 1px solid green;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
        }

        &__item-quantity {
          width: 24%;
          height: 100%;
          text-align: center;
          font-size: calc(16px * var(--heightmultiplier)) !important;
          font-weight: 400;
          color: var(--primaryTextColor);
          // margin-left: 1.2rem;
          // border: 1px solid green;
          // border: 1px solid #FD6125;
          border-radius: calc(5px * var(--heightmultiplier));
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          &__availbleQtyErr {
            font-weight: 500;
            // position: absolute;
            // top: calc(3px * var(--heightmultiplier));
            // height: 20%;
            font-size: calc(13px * var(--heightmultiplier));
            line-height: calc(13px * var(--heightmultiplier));
            // border: 1px solid black;
            color: red;
          }

          &__backorderQtyErr {
            font-weight: 500;
            // position: absolute;
            // bottom: calc(3px * var(--heightmultiplier));
            // height: 20%;
            font-size: calc(13px * var(--heightmultiplier));
            line-height: calc(13px * var(--heightmultiplier));
            // border: 1px solid black;
            color: red;
          }

          &__quantity-controls {
            display: flex;
            // display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            // flex-direction: column;
            // justify-content: space-between;




            &__minus-plus {
              width: calc(22px * var(--heightmultiplier));
              height: calc(22px * var(--heightmultiplier));
              background-color: rgba($color: #FD6125, $alpha: 0.1);
              border-radius: calc(5px * var(--heightmultiplier));
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              font-size: calc(16px * var(--heightmultiplier));

              &__plus-minus-svg {
                width: calc(14px * var(--heightmultiplier));
                height: calc(14px * var(--heightmultiplier));
              }

            }

            &__quantity-input {
              width: 30px;
              height: 30px;
              text-align: center;
              font-size: calc(18px * var(--heightmultiplier));
              background-color: var(--primaryColor);
              // border: 1px solid #ddd;
              border: none;
              border-radius: 4px;
              outline: none;
              // transition: border-color 0.3s, box-shadow 0.3s;

            }

            &__item-delete {
              width: 15%;
              height: 100%;
              background-color: var(--primaryColor) !important;
              text-align: center;
              font-size: calc(16px * var(--heightmultiplier)) !important;
              font-weight: 500;
              color: var(--primaryTextColor);
              // margin-left: 1.2rem;
              // border: 1px solid green;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              // font-size: 12px;

              &__binImg {
                cursor: pointer;
                width: calc(20px * var(--widthmultiplier));
                height: calc(20px * var(--heightmultiplier));
              }

            }

          }

        }


      }


    }


  }

  &__drawer-footer {
    height: 10%;
    border-top: 3px solid var(--secondaryColor) !important;
    width: 100% !important;
    // padding: 0.5rem;
    margin: 0 !important;
    // text-align: start !important;
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
    // border: 1px solid yellow;
    align-items: center;

    &__total-weight {
      height: 100%;
      width: fit-content;
      font-weight: bold;
      font-size: calc(16px * var(--heightmultiplier));
      // border: 1px solid red;
      color: var(--primaryTextColor);
      display: flex !important;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      // margin-bottom: -3rem;
      // margin-left: -15rem;
      // margin-top: 0.6rem;

    }

    &__btn-container {
      width: 50%;
      height: 100%;
      // border: 1px solid green;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
    }

    &__checkout-button {
      // padding: 4px 10px;
      width: fit-content;
      height: fit-content;
      padding: calc(8px * var(--heightmultiplier)) calc(6px * var(--widthmultiplier));
      background-color: rgba($color: #FD6125, $alpha: 0.5) !important;
      // color: orangered;
      color: var(--primaryTextColor) !important;
      border-radius: calc(8px * var(--heightmultiplier));
      // border: none;
      // border: 1px solid red !important;
      font-size: calc(16px * var(--heightmultiplier));
      // margin-left: 10rem;
      // margin-top: -2rem !important;
      // margin-right: -2rem;

      &:hover {
        cursor: pointer;
        background-color: rgba($color: #FD6125, $alpha: 1.0) !important;
        color: white !important;
        font-weight: 500 !important;
        /* Slightly darker orange on hover */
        transform: scale(1.05);
        /* Slightly enlarges the button */
        box-shadow: 0 4px 8px var(--secondaryTextColor);
        /* Adds a shadow effect */
      }
    }

  }

  &__quick-checkout-and-cart {
    width: 100%;
    height: 10%;
    border-bottom: 3px solid var(--secondaryColor);
    display: flex;
    justify-content: space-between;
    padding: calc(10px * var(--heightmultiplier)) calc(20px * var(--widthmultiplier));

    &__quick-checkout {

      white-space: nowrap;
      /* Prevents text from wrapping */
      overflow: hidden;
      /* Hides overflow content if necessary */
      text-overflow: ellipsis;
      /* Adds ellipsis (...) if the text overflows */
      font-size: calc(25px * var(--heightmultiplier));
      font-weight: 500;
      // margin-left: calc(0px * var(--widthmultiplier));
      /* Correct 'rem' usage */
      width: 50%;
      /* Adjust as per layout needs */
      height: auto;
      /* Ensures height is suitable for content */
      display: flex;
      align-items: center;
      justify-content: center;
      // margin-top: -0.3rem;
      // border: 1px solid red;

    }

    &__cart {
      // margin-top: -0.5rem;
      width: 50%;
      height: 100%;
      // border: 1px solid green;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      &__viewBtn-container {
        width: fit-content;
        height: fit-content;
        // border: 1px solid green
      }

      &__view-button {
        // padding: 4px 10px;
        width: fit-content;
        height: fit-content;
        padding: calc(8px * var(--heightmultiplier)) calc(6px * var(--widthmultiplier));
        background-color: rgba($color: #FD6125, $alpha: 0.5) !important;
        // color: orangered;
        color: var(--primaryTextColor) !important;
        border-radius: calc(8px * var(--heightmultiplier));
        // border: none;
        // border: 1px solid red !important;
        font-size: calc(16px * var(--heightmultiplier));
        // margin-left: 10rem;
        // margin-top: -2rem !important;
        // margin-right: -2rem;

        &:hover {
          cursor: pointer;
          background-color: rgba($color: #FD6125, $alpha: 1.0) !important;
          color: white !important;
          font-weight: 500 !important;
          /* Slightly darker orange on hover */
          transform: scale(1.05);
          /* Slightly enlarges the button */
          box-shadow: 0 4px 8px var(--secondaryTextColor);
          /* Adds a shadow effect */
        }

      }

    }
  }

  //    flex-direction: column;

}

.rs-input {
  height: calc(10px var(--heightmultiplier)) !important;
  font-size: calc(16px * var(--widthmultiplier)) !important;
  padding: calc(5px * var(--heightmultiplier)) calc(5px * var(--heightmultiplier)) !important;
  background-color: transparent !important;
  overflow: hidden !important;
  color: var(--primaryTextColor) !important
}

.rs-input-group-addon {
  padding: calc(10px * var(--heightmultiplier)) calc(10px * var(--heightmultiplier)) !important;
  background-color: transparent !important;
  color: var(--primaryTextColor) !important
}

.rs-input-group {
  color: var(--primaryTextColor) !important;
  background-color: transparent !important;
  border: 2px solid var(--primaryTextColor) !important;
}