@import './components/variables';

@font-face {
  font-family: 'General Sans';
  src: url('./fonts/fonts/GeneralSans-Variable.woff2') format('woff2'),
    url('./fonts/fonts/GeneralSans-Variable.woff') format('woff'),
    url('./fonts/fonts/GeneralSans-Variable.ttf') format('truetype');
  font-weight: 200 700;
  font-display: swap;
  font-style: normal;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


::-webkit-scrollbar {
    width: calc(8px * var(--widthmultiplier));
    border-radius: calc(4px * var(--widthmultiplier));
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba($color: #FD6125, $alpha: 0.2);
    ;
    border-radius: calc(4px * var(--widthmultiplier));
}

/* Handle */
::-webkit-scrollbar-thumb {
    // background: #cececf;
    background: #FD6125;
    border-radius: calc(4px * var(--widthmultiplier));
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    // background: #b30000; 
    cursor: pointer;
}
::-webkit-scrollbar {
  width: calc(4px * var(--widthmultiplier));
  border-radius: calc(2px * var(--widthmultiplier));
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba($color: #fd6125, $alpha: 0.2);
  border-radius: calc(4px * var(--widthmultiplier));
}

/* Handle */
::-webkit-scrollbar-thumb {
  // background: #cececf;
  background: #fd6125;
  border-radius: calc(4px * var(--widthmultiplier));
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  // background: #b30000;
  cursor: pointer;
}

#root {
  font-family: "General Sans";
  font-size: calc(16px * var(--heightmultiplier));
}
html {
  font-size: calc(16px * var(--heightmultiplier));
  --bs-blue: #FD6125;
}

body {
  font-family: "General Sans";
  font-size: calc(16px * var(--heightmultiplier));

}

.App {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $appBgColor;

}

.app-page-container {

  --widthmultiplier: 1;
  --heightmultiplier: 1;
  --primaryColor: white;
  --secondaryColor: #F2F2F2;
  --primaryTextColor: #08030A;
  --secondaryTextColor: #e2e2e2;
  height: calc(1080px * var(--heightmultiplier));
  width: calc(1920px * var(--widthmultiplier));
  // overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // background-color: aquamarine;
  box-sizing: border-box;
  background-color: var(--secondaryColor);
  color: var(--primaryTextColor);
  font-family: "General Sans";

  &__themeSwitch {
    position: absolute;
    right: 1%;
    bottom: 1%;
    box-sizing: border-box;
    padding: 0px;
    height: calc(20px * var(--heightmultiplier));
    width: calc(40px * var(--widthmultiplier));
    border: 1px solid #FD6125;
    border-radius: calc(10px * var(--heightmultiplier));
    ;
    display: flex;
    align-items: center;
    justify-content: center;
    // box-shadow: 0px 0px 10px #FD6125;
    overflow: hidden;

    &:hover {
      cursor: pointer;
    }

    &__circle {
      height: 100%;
      width: 50%;
      border-radius: 50%;
      transition: all 0.2s linear;
      transform: translateX(calc(-10px * var(--widthmultiplier)));
      background-color: #FD6125;
    }
  }

}