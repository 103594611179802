@import "../variables";



.InvListSubContent-container {
  // border: 1px solid black;
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: var(--primaryColor);
  //border: 1px solid black;
  //display: flex;
  //margin: calc(10px * var(--heightmultiplier));
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  //border: 2px solid blue;

  //background-color: var(--primaryColor);
  border-radius: calc(16px * var(--heightmultiplier)) calc(0px * var(--heightmultiplier)) calc(16px * var(--heightmultiplier)) calc(16px * var(--heightmultiplier));
  //padding:  calc(10px * var(--heightmultiplier));


  // &__totalItems {
  //   box-sizing: border-box;
  //   font-family: "General Sans";
  //   font-style: normal;
  //   font-weight: 400;
  //   font-size: calc(20px * var(--heightmultiplier));
  //   //line-height: calc(27px * var(--heightmultiplier));
  //   color: var(--primaryTextColor);
  //   margin-left: calc(3px * var(--heightmultiplier));
  // }

  &__subContainer {
    //box-sizing: border-box;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: var(--primaryColor);
    // align-items: center;
    // border-radius: calc(16px * var(--heightmultiplier)) calc(0px * var(--heightmultiplier)) calc(16px * var(--heightmultiplier)) calc(16px * var(--heightmultiplier));

    // display: flex;
    // text-align: center;
    // justify-content: center;
    // align-items: center;
    border: 1px solid var(--primaryColor);
    //  &__top {
    //   width: 50%;
    //   //height: calc(511px * var(--heightmultiplier));
    //   height: auto;
    // display: flex;
    // align-items: flex-start;
    // justify-content: space-between;
    //    border: 1px solid rgb(185, 6, 6);
    // }
    &__left {
      width: 60%;
    }

    &__inside {
      align-items: center;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    &__itemscontainer {
      // border: 1px solid rgb(40, 7, 226);
      height: fit-content;
      width: 100%;
      border-radius: calc(10px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
      padding: calc(30px * var(--heightmultiplier)) calc(20px * var(--widthmultiplier));
      display: block;
      // padding-bottom: calc(0px * var(--heightmultiplier));
    }

    &__leftBox {
      width: 100%;
      //height: calc(511px * var(--heightmultiplier));
      height: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      overflow-y: auto;
      overflow-x: hidden;
      //border: 1px solid rgb(185, 6, 6);
    }

    &__uploadImage {
      height: auto;
      width: 38%;
      padding-right: calc(5px * var(--widthmultiplier));
      // border: 1px solid black;
      margin-top: calc(30px * var(--heightmultiplier));
      display: flex;
      align-items: center;
      justify-content: center;

      &__display {
        height: auto;
        width: calc(500px * var(--heightmultiplier));
      }
    }

    &__inputFiled {
      width: 96%;
      height: fit-content;
      border: 1px solid var(--primaryTextColor);
      border-radius: calc(10px * var(--heightmultiplier));
      margin: calc(10px * var(--heightmultiplier)) calc(20px * var(--widthmultiplier));
      background-color: var(--primaryColor);
      // border: 1px solid red;
      // padding: calc(10px * var(--heightmultiplier))
      // calc(20px * var(--widthmultiplier));
      //overflow-y: auto;
      //overflow-x: hidden;
      // position: relative;
      max-height: 40%;

      &__label {
        width: fit-content;
        margin-left: calc(10px * var(--heightmultiplier));
        padding: calc(0px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
        height: 2%;
        //border: 1px solid blue;
        font-size: calc(16px * var(--heightmultiplier));
        font-weight: 600;
        display: flex;
        text-align: flex-start;
        align-items: center;
        margin-top: calc(-12px * var(--heightmultiplier));
        background-color: var(--primaryColor);
        color: var(--primaryTextColor) // position: absolute;
          // left: 0px;
          // top: 0px;
      }

      &__value {
        width: 100%;
        height: fit-content;
        max-height: 40%;
        //border: 1px solid blue;
        font-size: calc(16px * var(--heightmultiplier));
        display: flex;
        text-align: flex-start;
        align-items: center;
        background-color: transparent;
        border-radius: calc(10px * var(--heightmultiplier));
        padding: calc(5px * var(--heightmultiplier));


        &__input {
          width: 100%;
          height: 100%;
          outline: none;
          border: none;
          color: var(--primaryTextColor);
          background-color: transparent;
          overflow-y: auto;
          overflow-x: hidden;
          max-height: 40%;
        }
      }
    }
    &__inputFiled2 {
      width: 100%;
      height: fit-content;
       //border: 1px solid var(--primaryTextColor);
      //border-radius: calc(10px * var(--heightmultiplier));
      // margin: calc(10px * var(--heightmultiplier)) calc(20px * var(--widthmultiplier));
      background-color: var(--primaryColor);
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      max-height: 40%;
      &__imageInput{
        width: 90%;
        height: fit-content;
        border: 1px solid var(--primaryTextColor);
        border-radius: calc(10px * var(--heightmultiplier));
        margin: calc(10px * var(--heightmultiplier)) calc(18px * var(--widthmultiplier));
        margin-right: 0px;
        background-color: var(--primaryColor);
        border-radius: calc(10px * var(--heightmultiplier));
        border-top-right-radius: calc(0px * var(--heightmultiplier));
        border-bottom-right-radius: calc(0px * var(--heightmultiplier));
        border-right: none;
        &__label {
          width: fit-content;
          margin-left: calc(10px * var(--heightmultiplier));
          padding: calc(0px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
          height: 2%;
         //border: 1px solid blue;
          font-size: calc(16px * var(--heightmultiplier));
          font-weight: 600;
          display: flex;
          text-align: flex-start;
          align-items: center;
          margin-top: calc(-12px * var(--heightmultiplier));
          background-color: var(--primaryColor);
          color: var(--primaryTextColor) // position: absolute;
            // left: 0px;
            // top: 0px;
        }
        &__file_input_textbox2 {
         // border: 1px solid #0e0d0d;
          outline: none;
          border: none;
          height: calc(60px * var(--heightmultiplier));
          font-size: calc(18px * var(--heightmultiplier));
          border-radius: calc(10px * var(--heightmultiplier));
          border-top-right-radius: calc(0px * var(--heightmultiplier));
          border-bottom-right-radius: calc(0px * var(--heightmultiplier));
          // border-radius: calc(10px * var(--heightmultiplier));
          width: 100%;
          background-color: transparent;
          color: var(--primaryTextColor);
          // border-color: rgba($color: #FD6125, $alpha: 1);
          //background-color: var(--secondaryColor);
          margin: 0px;
          padding-left: calc(10px * var(--widthmultiplier));
        }
       
        &__file_input_hidden {
          display: none;
          // visibility: hidden;
        }
      }
      &__imageButton{
        width: 10%;
       // border: 1px solid red;
        height: fit-content;
        margin-right: calc(17px * var(--heightmultiplier));
        border-top-right-radius: calc(10px * var(--heightmultiplier));
        border-bottom-right-radius: calc(10px * var(--heightmultiplier));
        // border-radius: calc(10px * var(--heightmultiplier));
        &__file_input_button {
          border: none;
          width: 100%;
          font-size: calc(22px * var(--heightmultiplier));
          font-weight: 500;
         // padding: calc(10px * var(--heightmultiplier));
          height: calc(75px * var(--heightmultiplier));
          margin-right: calc(2px * var(--heightmultiplier));
          border-top-right-radius: calc(10px * var(--heightmultiplier));
          border-bottom-right-radius: calc(10px * var(--heightmultiplier));
           //border-radius: calc(10px * var(--heightmultiplier));
          background-color: rgba($color: #fd6125, $alpha: 0.1);
          color: #fd6125;
          //margin: 0px;
          border: 1px solid red;
          &:hover {
            cursor: pointer;
            color: white;
            background-color: rgba($color: #fd6125, $alpha: 1);
          }
        }
      }
     
    }
   


    &__buttonContainer {
      width: 100%;
      height: 10%;
      position: absolute;
      bottom: 0;
      //border: 1px solid red;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;

      &__submit2 {
        // margin: calc(10px * var(--widthmultiplier)) calc(15px * var(--heightmultiplier));
        width: calc(120px * var(--widthmultiplier));
        border: none;
        border: 1px solid #fd6125;
        height: 60%;
        background-color: rgba($color: #fd6125, $alpha: 0.2);
        color: #fd6125;
        float: right;
        border-radius: calc(10px * var(--heightmultiplier));
        font-size: calc(24px * var(--heightmultiplier));

        &:hover {
          cursor: pointer;
          color: white;
          background-color: rgba($color: #fd6125, $alpha: 1);
        }
      }
    }

    &__InputBox2 {
      // border: 1px solid rgb(13, 109, 13);
      width: 100%;
      border-radius: calc(10px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
      display: block;
      padding: calc(10px * var(--heightmultiplier)) calc(20px * var(--widthmultiplier));


      &__label {
        // height:  calc(24px * var(--heightmultiplier));
        font-size: calc(24px * var(--heightmultiplier));
        font-weight: bolder;
        background-color: var(--primaryColor);
        padding: calc(0px * var(--widthmultiplier)) calc(15px * var(--heightmultiplier));
        // border: 1px solid black;
        width: 14%;
      }
    }


    &__InputBox {
      //border: 1px solid rgb(13, 109, 13);
      width: 100%;
      border-radius: calc(10px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
      display: block;
      padding: calc(10px * var(--heightmultiplier)) calc(20px * var(--widthmultiplier));

      &__label {
        color: red;
        font-size: calc(20px * var(--heightmultiplier));
      }

      &__submit1 {
        margin: calc(20px * var(--widthmultiplier)) calc(20px * var(--heightmultiplier));
        width: calc(120px * var(--widthmultiplier));
        height: calc(80px * var(--heightmultiplier));
        border: none;
        //position: static;
        background-color: rgba($color: #fd6125, $alpha: 0.2);
        color: #fd6125;
        // float: right;
        border-radius: calc(10px * var(--heightmultiplier));
        font-size: calc(24px * var(--heightmultiplier));

        &:hover {
          cursor: pointer;
          color: white;
          background-color: rgba($color: #fd6125, $alpha: 1);
        }
      }
    }
  }
}

// .MenuItem {
//   color: rgb(243, 6, 6);
// }

// /* Increase the specificity */
// .MenuItem.Mui-selected {
//   color: rgb(10, 97, 10);
// }