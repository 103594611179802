@import '../styles/variables';

.register-page-body {

    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    background-color: var(--secondaryColor);
    overflow: hidden;
    padding: 0px;
    margin: 0px;

    &__leftHalf {

        left: 0;
        top: 0;
        height: 100%;
        width: 45%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: var(--primaryColor);

        // border: 1px solid black;


        &__welcomeBox {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            width: 59.84%;
            margin-bottom: calc(70px * var(--heightmultiplier));


            &__logo {
                height: calc(80px * var(--heightmultiplier));
                width: calc(80px * var(--widthmultiplier));
                margin-bottom: calc(80px * var(--heightmultiplier));
            }

            &__welcomeMsg {
                // font-family: 'General Sans';
                font-style: normal;
                font-weight: 500;
                font-size: calc(52px * var(--heightmultiplier));
                line-height: calc(70px * var(--heightmultiplier));

                /* Primary Black */

                color: var(--primaryTextColor);

            }

            &__register-message {
                // font-family: 'General Sans';
                font-style: normal;
                font-weight: 400;
                font-size: calc(22px * var(--heightmultiplier));
                line-height: calc(30px * var(--heightmultiplier));
                /* identical to box height */
                color: #878B8F;

            }
        }

        &__register-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            width: 59.84%;

            &__input-email {
                box-sizing: border-box;
                outline: none;
                border: none;
                background: var(--secondaryTextColor);
                border-radius: calc(10px * (var(--widthmultiplier) + var(--widthmultiplier)) / 2);
                padding-left: calc(32px * var(--widthmultiplier));
                width: 85.5%;
                height: calc(50px * var(--heightmultiplier));
                font-size: calc(16px * var(--heightmultiplier));
                margin-bottom: calc(12px * var(--heightmultiplier));
                &__valueReadOnly{
                    height: 100%;
                    margin-left: calc(-18px * var(--widthmultiplier));
                    border: none;
                    background-color: transparent;
                }
                &__value{
                    width: 92%;
                    height: 98%;
                    border: none;
                    background-color: transparent;
                    outline: none;
                }
            }

            &__input-password {
                box-sizing: border-box;
                outline: none;
                outline: none;
                border: 1px solid #F2F2F2;
                border-radius: calc(10px * (var(--widthmultiplier) + var(--widthmultiplier)) / 2);
                padding-left: calc(32px * var(--widthmultiplier));
                width: 85.5%;
                height: calc(50px * var(--heightmultiplier));
                font-size: calc(16px * var(--heightmultiplier));
                margin-bottom: calc(12px * var(--heightmultiplier));
            }

            &__forgotPassword {
                text-align: end;
                box-sizing: border-box;
                outline: none;
                outline: none;
                // border: 1px solid #F2F2F2;
                // border-radius: 10px;
                // padding-left: 32px;
                width: 85.5%;
                height: calc(20px * var(--heightmultiplier));
                font-weight: 400;
                font-size: calc(16px * var(--heightmultiplier));
                line-height: calc(20px * var(--heightmultiplier));
                text-decoration-line: underline;

                color: #878B8F;

                mix-blend-mode: normal;

                margin-bottom: calc(26px * var(--heightmultiplier));
            }

            &__btn-SignIn {
                width: 85.5%;
                height: calc(45px * var(--heightmultiplier));
                // font-family: 'General Sans';
                font-style: normal;
                font-weight: 500;
                font-size: calc(16px * var(--heightmultiplier));
                line-height: calc(22px * var(--heightmultiplier));
                color: #F2F2F2;

                background: #FD6125;
                border-radius: calc(10px * (var(--widthmultiplier) + var(--widthmultiplier)) / 2);
                color: white;
                display: flex;
                align-items: center;
                justify-content: center;

                &:hover {
                    cursor: pointer;
                }
            }

            &__register-msg1 {
                margin-top: calc(20px * var(--heightmultiplier));
                width: 85.5%;
                // font-family: 'General Sans';
                font-style: normal;
                font-weight: 400;
                font-size: calc(16px * var(--heightmultiplier));
                line-height: calc(20px * var(--heightmultiplier));
                /* identical to box height, or 125% */


                color: #878B8F;

                mix-blend-mode: normal;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &__register-msg2 {
                // font-family: 'General Sans';
                font-style: normal;
                font-weight: 400;
                font-size: calc(16px * var(--heightmultiplier));
                line-height: calc(20px * var(--heightmultiplier));
                /* identical to box height, or 125% */

                mix-blend-mode: normal;
                color: rgba(66, 133, 244, 1);

                &:hover {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }

            &__error-msg {
                color: red;
                font-size: calc(16px * var(--heightmultiplier));
                opacity: 0;
            }

            &__field1 {
                font-size: calc(18px * var(--heightmultiplier));
                width: 85.5%;
                height: calc(50px * var(--heightmultiplier));
                display: flex;
                align-items: center;
                justify-content: space-between;
                background: var(--secondaryTextColor);
                padding: calc(4px * var(--heightmultiplier)) 0px;
                border: 1px solid #F2F2F2;
                margin-bottom: calc(10px * var(--heightmultiplier));
                border-radius: calc(10px * (var(--widthmultiplier) + var(--widthmultiplier)) / 2);
                color: #878B8F;

                &__title {
                    width: 29%;
                    //font-weight: 500;
                    padding: 0px calc(10px * var(--widthmultiplier));
                    display: flex;
                    //align-items: center;
                   // text-align: center;
                    justify-content: left;
                    // border:1px solid green;
                }

                &__colon {
                    width: 2%;
                    //   font-weight: 500;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                }

                &__value {
                    width: 69%;
                    //font-weight: 500;
                    //background-color: grey;
                    border: none;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    // color: var(--primaryTextColor);

                    &__option {
                        width: 100%;
                        //font-weight: 500;
                        outline: none;
                        border: none;
                        // color: var(--primaryTextColor) ;
                        background-color: transparent;
                        //color: var(--primaryTextColor);
                        // margin-left: calc(2px * var(--heightmultiplier));
                    }
                }
            }

            &__field {
                font-size: calc(18px * var(--heightmultiplier));
                width: 85.5%;
                height: calc(50px * var(--heightmultiplier));
                display: flex;
                align-items: center;
                //background-color: var(--primaryTextColor);
                background-color: white;
                justify-content: space-between;
                padding: calc(4px * var(--heightmultiplier)) 0px;
                border: 1px solid #F2F2F2;
                margin-bottom: calc(10px * var(--heightmultiplier));
                border-radius: calc(10px * (var(--widthmultiplier) + var(--widthmultiplier)) / 2);
             

                &__title {
                    width: 29%;
                    //font-weight: 500;
                    padding: 0px calc(10px * var(--widthmultiplier));
                    display: flex;
                    align-items: center;
                    text-align: center;
                    justify-content: left;
                    color: #878B8F;
                    // border:1px solid green;
                }

                &__colon {
                    width: 2%;
                    //   font-weight: 500;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                }

                &__value {
                    width: 69%;
                    //font-weight: 500;
                    //background-color: grey;
                    border: none;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                   // color: var(--primaryTextColor);

                    &__option {
                        width: 100%;
                        //font-weight: 500;
                        outline: none;
                        border: none;
                        background-color: transparent;
                        //color: var(--primaryTextColor);
                        // margin-left: calc(2px * var(--heightmultiplier));
                    }
                }
            }
        }
    }

    &__rightHalf {
        box-sizing: border-box;
        position: relative;
        height: 100%;
        width: 55%;
        // border: 1px solid black;
        overflow: hidden;

        &__img {
            // width: 1920px;
            position: absolute;
            height: 100%;
            // width: 181.8%;
            left: -41.85%;
            top: 0px;

            z-index: 0;
        }
    }

}